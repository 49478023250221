<script setup>
import { ref } from 'vue';
import apiManager from '@/api';
import LoginPage from '@/components/global/LoginPage.vue';
import CheckIn from '@/components/eventCheckIn/CheckIn.vue';
import { useMeta } from 'vue-meta';
import { useToast } from 'vue-toastification';

const showCheckIn = ref(false);
const token = ref('');
const toast = useToast();
const loadingLogin = ref(false);
useMeta({
  robots: 'noindex',
});

const enableCheckIn = async (e) => {
  loadingLogin.value = true;
  try {
    const { data } = await apiManager.checkIn.postLoginCheckIn(e);
    if (data) {
      showCheckIn.value = true;
      token.value = data;
    } else {
      toast.error('Please provide a valid key');
    }
  } catch (err) {
    toast.error('Please provide a valid key');
  } finally {
    loadingLogin.value = false;
  }
};

useMeta({
  robots: 'noindex',
});
</script>
<template>
  <login-page
    title="Check-In Page Login"
    legend="Login to event check-in page"
    :loading="loadingLogin"
    @enableCheckIn="enableCheckIn($event)"
    v-if="!showCheckIn" />
  <check-in v-if="showCheckIn" :token="token" />
</template>
