<script setup>
import {
  reactive,
  computed,
  defineProps,
} from 'vue';
import useValidate from '@vuelidate/core';
import {
  helpers,
  required,
} from '@vuelidate/validators';
import { useToast } from 'vue-toastification';
import TheFieldset from './TheFieldset.vue';
import TheTextfield from '../registration/TheTextfield.vue';
import TheButton from './TheButton.vue';

const toast = useToast();

const state = reactive({
  key: '',
});

const requiredValidator = helpers.withMessage('Field is required', required);

const rules = computed(() => (
  {
    key: {
      requiredValidator,
    },
  }
));

defineProps({
  loading: Boolean,
  title: { type: String, required: true },
  legend: { type: String, required: true },
});

const v$ = useValidate(rules, state, { $autoDirty: true });

const emit = defineEmits(['enableCheckIn']);

function submitForm() {
  v$.value.$validate();
  if (!v$.value.$error) {
    const login = {
      key: state.key,
    };

    emit('enableCheckIn', login);
  } else {
    toast.error('Please provide a valid key.');
  }
}
</script>
<template>
  <div class="p-5 lg:p-14 grid lg:grid-cols-4">
    <div class="col-span-2 xl:col-span-3">
      <h1 class="
        mb-10
        text-2xl
        sm:text-3xl
        lg:text-4xl
        font-MyriadProLight
        uppercase
      ">
        {{ title }}
        <span class="border-b-[6px] border-dynamic block w-[4rem]"></span>
      </h1>
      <form
        @submit.prevent="submitForm"
        class="">
        <TheFieldset
          :legend="legend"
          class="lg:grid-cols-4 gap-4"
        >
          <TheTextfield
            type="text"
            name="key"
            placeholder="KEY*"
            maxLength="25"
            :dirty="v$.key.$dirty"
            :errorMessage="v$.key.$errors[0]?.$message"
            v-model:value="state.key"
            class="lg:col-span-3"
          />
          <TheButton
            type="submit"
            class="border-2"
            label="Login"
            :loading="loading"
          />
        </TheFieldset>
      </form>
    </div>
  </div>
</template>
