import { baseCRUDAPI } from './base';

export default class Reservations extends baseCRUDAPI {
  constructor(axiosObject) {
    super(axiosObject, 'Reservations');
  }

  async getLocations(eventType) {
    return this.axiosObject.get(
      `${this.apiBaseName}/Locations`,
      {
        params: {
          eventType,
        },
      },
    );
  }

  async getAllLocations(eventType) {
    return this.axiosObject.get(`${this.apiBaseName}/Locations`, {
      params: {
        eventType,
      },
    });
  }

  async getWaiverLocations() {
    return this.axiosObject.get(`${this.apiBaseName}/Locations/All`);
  }

  async getSessions({ eventType, locationId }) {
    return this.axiosObject.get(
      `${this.apiBaseName}/Sessions`,
      {
        params: {
          locationId,
          eventType,
        },
      },
    );
  }

  async postReserveRegistration({ sessionId }) {
    return this.axiosObject.post(
      `${this.apiBaseName}/ReserveRegistration`,
      null,
      {
        params: {
          sessionId,
        },
      },
    );
  }

  async postReservations(data, sessionUIC) {
    return this.axiosObject.post(`${this.apiBaseName}`, data, {
      headers: {
        'Content-type': 'application/json',
      },
      params: {
        uic: sessionUIC,
      },
    });
  }

  async updateReservation({ authToken, id, body }) {
    return this.axiosObject.put(
      `${this.apiBaseName}`,
      body,
      {
        headers: {
          authToken,
        },
        params: {
          id,
        },
      },
    );
  }

  async postAddGuest({
    authToken,
    eventType,
    guestFirstName,
    guestLastName,
  }) {
    return this.axiosObject.post(
      `${this.apiBaseName}/AddGuest`,
      null,
      {
        headers: {
          authToken,
        },
        params: {
          eventType,
          guestFirstName,
          guestLastName,
        },
      },
    );
  }

  async putAddTime({ uic, sessionId }) {
    return this.axiosObject.put(
      `${this.apiBaseName}/ReserveRegistration/AddTime`,
      null,
      {
        params: {
          uic,
          sessionId,
        },
      },
    );
  }

  async cancel({ id }) {
    return this.axiosObject.delete(
      `${this.apiBaseName}/${id}/cancel`,
    );
  }
}
