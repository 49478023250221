const EVENT_TYPES = [
  {
    name: 'Consumer Event',
    value: '0',
    route: '',
  },
  {
    name: 'Acura Training',
    value: '1',
    route: 'acuratraining',
  },
  {
    name: 'Honda Training',
    value: '2',
    route: 'hondatraining',
  },
  {
    name: 'ZDX Drive',
    value: '3',
    route: 'zdxdrive',
  },
  {
    name: 'Prologue Drive',
    value: '4',
    route: 'prologuedrive',
  },
];

export default EVENT_TYPES;
