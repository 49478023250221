<script setup>
import store from '@/store';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import filterActiveEventLocations from '@/utils/filterEventLocations';

const route = useRoute();

const locations = computed(() => filterActiveEventLocations(store.state.locations.locations));

</script>

<template>
<header class="container mx-auto">
    <a
      class="
        bg-dynamic
        text-white
        font-AvenirHeavy
        left-1/2
        text-xl
        font-bold
        p-1
        absolute
        -translate-y-full
        focus:translate-y-0
        transition-transform
        duration-300
      "
      href="#main"
      >Skip to main content</a
    >
    <primary-navbar :locations="locations" />
  </header>
  <main id="main" class="container mx-auto flex-1 overflow-hidden">
    <router-view :key="route.fullPath" />
  </main>
  <the-footer :locations="locations" />
</template>
