<script setup>
import { defineEmits, defineProps } from 'vue';

defineProps({
  disableButton: { type: Boolean, required: true },
});

const emit = defineEmits(['cancel-registration']);

const cancelRegistration = () => emit('cancel-registration');
</script>
<template>
<div
  class="
  flex flex-col
  justify-center
    text-black
    text-[1.85rem] xl:text-[1.75rem]
    font-AvenirLight
    pl-[1rem] xl:pl-[8.8125rem]
    pr-[1rem]
  "
>
<p
  class="
    w-[40rem]
    text-dynamic
    font-MyriadProBold
    text-[3rem]
    xl:text-[4.687rem]
    text-left
    uppercase
    leading-[3rem]
    xl:leading-[4rem]
    pb-8">
    <span class="text-[2.75rem] xl:text-[3.5rem]">
      Cancel
    </span> <br />
   Registration</p>
  <p class="text-[1.5rem] text-left xl:text-[2.25rem] pb-[3rem]">Can't make it?
    <span class="whitespace-nowrap">Change of plans?</span>
  </p>
  <p class="
    text-left
    text-[1.5rem]
    xl:text-[2.25rem]
    pb-[3rem]">
    No problem.
  </p>
  <p class="
    text-left
    text-[1.5rem]
    xl:text-[1.75rem]
    pb-[2rem]
    md:pb-[3.5rem]">
    Click below to confirm your cancellation.
  </p>
  <button
    class="
      uppercase
      w-full
      md:max-w-[21.25rem]
      h-[4rem]
      text-[1.25rem]
      rounded-[0.675rem]
      font-MyriadProLight
      font-bold
    "
    :class="disableButton
      ? 'bg-acura-gray-dark border-acura-gray-dark border cursor-not-allowed'
      : 'bg-dynamic text-white'"
    :disabled="disableButton"
    @click="cancelRegistration"
  >Cancel Registration</button>
</div>
</template>
