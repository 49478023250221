<script setup>
import apiManager from '@/api';
import FaqsDisplay from '@/components/faqsDisplay/FaqsDisplay.vue';
import store from '@/store';
import { computed, onBeforeMount, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useMeta } from 'vue-meta';

const eventType = computed(() => store.getters['user/eventType']);

const faqs = ref([]);

const route = useRoute();

useMeta({
  meta: [
    { name: 'description', content: route.meta.description },
    { 'http-equiv': 'Content-Type', content: 'text/html;charset=utf-8' },
  ],
});

const loadFAQ = async () => {
  const { data } = await apiManager.home.faqs(eventType.value);
  faqs.value = data;
};

onBeforeMount(async () => {
  await loadFAQ();
});
</script>
<template>
  <faqs-display :faqs="faqs" />
</template>
