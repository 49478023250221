<script setup>
import { ref, computed } from 'vue';
import {
  helpers,
  required,
} from '@vuelidate/validators';
import useValidate from '@vuelidate/core';
import { useToast } from 'vue-toastification';

import apiManager from '@/api';
import TheButton from '../../global/TheButton.vue';
import TheFieldset from '../../global/TheFieldset.vue';
import TheTextfield from '../../registration/TheTextfield.vue';

const form = ref({
  firstName: '',
  lastName: '',
  dptsid: null,
  dealerName: null,
});

const requiredValidator = helpers.withMessage('Field is required', required);

const rules = computed(() => (
  {
    firstName: { requiredValidator },
    lastName: { requiredValidator },
    dptsid: {},
    dealerName: {},
  }
));

const isLoading = ref(false);
const toast = useToast();
const v$ = useValidate(rules, form.value, { $autoDirty: true });

defineProps({
  token: String,
});

const clearForm = () => {
  form.value.firstName = '';
  form.value.lastName = '';
  form.value.dptsid = '';
  form.value.dealerName = '';

  v$.value.$reset();
};

const printBadge = async () => {
  isLoading.value = true;
  try {
    await apiManager.print.postPrintLabel({
      ...form.value,
      dptsid: form.value.dptsid || null,
      dealerName: form.value.dealerName || null,
    });
    toast.success('The request has been successfully processed and the badge will begin printing shortly.');
    clearForm();
  } catch (error) {
    const errorMessage = error.response.data.message || 'An error ocurred while printing your badge. Please try again later.';
    toast.error(errorMessage);
  } finally {
    isLoading.value = false;
  }
};

const submitForm = async () => {
  await v$.value.$validate();
  if (!v$.value.$invalid) {
    printBadge();
  } else {
    toast.error('Please provide values for all required fields.');
  }
};
</script>

<template>
  <TheFieldset
            legend="Fill out the fields below to print the label"
            class=""
          >
          <p class="mb-4">Fields with * are required</p>
          <form
            @submit.prevent="submitForm"
            class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8"
          >
              <TheTextfield
                name="firstname"
                placeholder="First Name*"
                :dirty="v$.firstName.$dirty"
                :errorMessage="v$.firstName.$errors[0]?.$message"
                v-model:value="form.firstName"
              />
              <TheTextfield
                name="lastname"
                placeholder="Last Name*"
                :dirty="v$.lastName.$dirty"
                :errorMessage="v$.lastName.$errors[0]?.$message"
                v-model:value="form.lastName"
              />
              <TheTextfield
                name="dptsid"
                placeholder="DPTS ID"
                :dirty="v$.dptsid.$dirty"
                :errorMessage="v$.dptsid.$errors[0]?.$message"
                v-model:value="form.dptsid"
              />
              <TheTextfield
                name="dealerName"
                placeholder="Dealership Name"
                :dirty="v$.dealerName.$dirty"
                :errorMessage="v$.dealerName.$errors[0]?.$message"
                v-model:value="form.dealerName"
              />
                <TheButton
                type="submit"
                class="border-2 md:col-span-2 lg:col-span-1"
                custom-bg="bg-[#2D7AB9] border-[#2D7AB9]"
                label="Print Badge"
                :loading="isLoading"
              />
          </form>
        </TheFieldset>
</template>
