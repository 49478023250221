<template>
  <div class="flex flex-col gap-2 text-justify">
    <p>Please upload the list of badges that you want printed and click the print badges button.</p>
    <p>
      The file should be a .CSV or .XLSX with four columns. First Name (required), Last Name
      (required), DPTS ID (optional), and Dealership Name (optional).
    </p>
    <p>Each unique badge that you want to print should be on its own row.</p>
  </div>
</template>
