<script setup>
import { useStore } from 'vuex';
import apiManager from '@/api';
import router from '@/router';
import { reactive, computed, ref } from 'vue';
import useValidate from '@vuelidate/core';
import { formatDate, formatTime, unmaskPhoneNumber } from '@/utils/format';
import actions from '@/types/registrationActions';
import { handleRouteWithEvent } from '@/utils/eventType';
import { faChevronRight, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import eventTypes from '@/types/eventTypes.enum';
import { trackCustomEvent } from '@/utils/analytics';
import vueRecaptcha from 'vue3-recaptcha2';
import {
  helpers,
  required,
  email,
  sameAs,
  requiredIf,
} from '@vuelidate/validators';
import { useToast } from 'vue-toastification';
import Cookies from 'js-cookie';
import RegistrationTimer from './RegistrationTimer.vue';
import TheTextfield from './TheTextfield.vue';
import TheCheckbox from './TheCheckbox.vue';
import TheRadio from './TheRadio.vue';

const props = defineProps({
  action: String,
  city: String,
  locationId: String,
  session: Object,
  sessionTime: Number,
  inviteeType: String,
});

const store = useStore();

const captchaSiteKey = computed(() => process.env.VUE_APP_RECAPTCHA_SITE_KEY);
const eventType = computed(() => store.getters['user/eventType']);
const sessionUIC = computed(() => store.getters['user/sessionUIC']);

const source = computed(() => Cookies.get('experiencedrive_source'));

const toast = useToast();

const YesNo = { YES: 'yes', NO: 'no' };

const state = reactive({
  dptsid: '',
  dealernumber: '',
  firstName: '',
  lastName: '',
  email: '',
  confirmEmail: '',
  phoneNumber: '',
  zipCode: '',
  signMe: false,
  captchaVerified: true,
  yesOrNo: '',
  modelYear: '',
  acuraBrand: '',
  guestFirstName: '',
  guestLastName: '',
});

const requiredValidator = helpers.withMessage('Field is required', required);
const emailValidator = helpers.withMessage('Please provide a valid email', email);
const regexPhone = helpers.regex(/^(1[- .]?)?(\([2-9]\d{2}\)[. -]?|[2-9]\d{2}[. -]?)[2-9]\d{2}[- .]?\d{4}$/);
const phoneValidator = helpers.withMessage('Please provide a valid phone number', regexPhone);
const regexZipCode = helpers.regex(/^\d{5}(?:[-]\d{4})?$/);
const zipCodeValidator = helpers.withMessage('Please provide a valid zip code', regexZipCode);
const regexModelYear = helpers.regex(/^\d{4}\s\w+$/);

const rules = computed(() => (
  {
    firstName: { requiredValidator },
    lastName: { requiredValidator },
    email: {
      requiredValidator,
      emailValidator,
    },
    confirmEmail: {
      requiredValidator,
      emailValidator,
      sameAs: helpers.withMessage('Provided emails do not match', sameAs(state.email)),
    },
    phoneNumber: {
      requiredValidator,
      phoneValidator,
    },
    zipCode: {
      requiredValidator,
      zipCodeValidator,
    },
    captchaVerified: {
      sameAs: helpers.withMessage('Captcha is required', sameAs(true)),
    },
    dptsid: {
      requiredMedia: helpers.withMessage('Field is required', requiredIf(eventType.value === 'acuratraining' || eventType.value === 'hondatraining')),
    },
    dealernumber: {
      requiredMedia: helpers.withMessage('Field is required', requiredIf(eventType.value === 'zdxdrive' || eventType.value === 'prologuedrive')),
    },
    guestFirstName: {
      requiredGln: helpers.withMessage('Field is required', requiredIf(state.guestLastName)),
    },
    guestLastName: {
      requiredGfn: helpers.withMessage('Field is required', requiredIf(state.guestFirstName)),
    },
    modelYear: {
      modelYear: helpers.withMessage('YYYY MODEL', regexModelYear),
    },
  }
));

const v$ = useValidate(rules, state, { $autoDirty: true });

const loading = ref(false);
const recaptchaRef = ref();

const navigateTo = (path) => {
  const routeWithEvent = handleRouteWithEvent(eventType.value, path);
  router.push(routeWithEvent);
};

const validateRecaptcha = () => {
  state.captchaVerified = true;
};

function submitForm() {
  v$.value.$validate();
  if (!v$.value.$error) {
    loading.value = true;
    const registration = {
      sessionId: props.session.sessionId,
      firstName: state.firstName,
      lastName: state.lastName,
      emailAddress: state.email,
      phoneNumber: unmaskPhoneNumber(state.phoneNumber),
      zipCode: state.zipCode,
      communicationOptIn: state.signMe,
      acuraOwnership: state.yesOrNo === YesNo.YES,
      acuraModel: state.modelYear.substring(5),
      modelYear: state.modelYear.substring(0, 4),
      brandOpinion: parseInt(state.acuraBrand, 10),
      guestFirstName: state.guestFirstName,
      guestLastName: state.guestLastName,
      eventType: eventTypes[eventType.value],
      uic: sessionUIC.value,
    };
    if (eventType.value === 'acuratraining' || eventType.value === 'hondatraining') {
      registration.dptsid = state.dptsid;
    }
    if (eventType.value === 'zdxdrive' || eventType.value === 'prologuedrive') {
      registration.dealernumber = state.dealernumber;
    }
    if (source.value) {
      registration.source = source.value;
    }
    const reservations = async () => {
      try {
        const {
          data,
        } = await apiManager.reservations.postReservations(registration, sessionUIC.value);
        let message = '';
        message = data.reservation.status === 1
          ? 'Thank you for registering. We look forward to seeing you at Evolution Experience!'
          : 'Thank you for joining the waitlist. We will notify you if a seat opens up.';
        toast.success(message);
        trackCustomEvent('RegistrationForm:Submitted', eventType.value, 'registration', { location: props.locationId, action: 'Registration', actionValue: 'complete' });
        navigateTo('/');
      } catch (err) {
        loading.value = false;
        const message = err.response.data.message ?? 'Your registration failed for an unknown reason. Please try again or contact our support team at info@acuraperformancedrive.com.';
        toast.error(message);
      }
    };
    reservations(registration);
  } else {
    const errorType = v$.value.$errors[0].$property === 'captchaVerified'
      ? 'Captcha verification is required'
      : 'Please fill out all required fields';
    toast.error(errorType);
  }
}
</script>
<template>
  <div class="
    bg-[#F7F7F7] p-5 lg:px-[2.6875rem] lg:py-12 shadow-lg
  ">
  <div
    class="
    font-AvenirHeavy uppercase
    pb-10 border-acura-gray-dark
  ">
    <div
      v-if="props.action !== actions.WAITLIST"
      class="">
    <registration-timer v-if="action !== actions.WAITLIST" />
    </div>
      <div>
        <h2
          class="
          font-MyriadProLight
          font-bold
          text-[#888888]
          text-xl
          mb-2
          ">Location and Time</h2>
        <div class="md:flex justify-between">
        <div class="grid grid-cols-1">
          <p class="sm:text-3xl lg:text-5xl font-MyriadProBold font-bold text-dynamic">
            {{ props.city }}
          </p>
          <p class="sm:text-lg text-black lg:text-xl font-MyriadProRegular">
            {{ formatDate(props.session.startTime) }} | {{ formatTime(props.session.startTime) }}
          </p>
        </div>
        <button
          @click="navigateTo(`/location/${props.locationId}`)"
          class="text-[1.175rem] uppercase text-dynamic font-MyriadProBold font-bold">
          Edit location or time
        <font-awesome-icon
          :icon="faChevronRight"
          size="md"
          color="dynamic"
          class="ml-2"
        />
        </button>
        </div>
      </div>
    </div>
    <form
      class="bg-white rounded-[30px] px-6 py-8 md:px-[2.25rem] md:py-[3.875rem]"
        @submit.prevent="submitForm"
      >
      <h2 class="font-MyriadProLight text-xl lg:text-4xl mb-7">
          Personal Information
      </h2>
      <div class="md:grid grid-cols-1 md:grid-cols-2 gap-x-5">
      <div class="col-span-2">
        <the-textfield
          v-if="eventType === 'acuratraining' || eventType === 'hondatraining'"
          class="mb-[3rem] md:mb-[6.625rem] md:w-[48.9%]"
          name="dptsid"
          label="DPTS ID*"
          placeholder="ID#"
          :dirty="v$.dptsid.$dirty"
          :errorMessage="v$.dptsid.$errors[0]?.$message"
          v-model:value="state.dptsid"
        />
      </div>
      <div class="col-span-2">
        <the-textfield
          v-if="eventType === 'zdxdrive' || eventType === 'prologuedrive'"
          class="mb-[3rem] md:mb-[6.625rem] md:w-[48.9%]"
          name="dealerNumber"
          label="DEALER NUMBER*"
          placeholder="00000000"
          :dirty="v$.dealernumber.$dirty"
          :errorMessage="v$.dealernumber.$errors[0]?.$message"
          v-model:value="state.dealernumber"
        />
      </div>

        <the-textfield
          class="mb-6 md:mb-12"
          name="first-name"
          label="FIRST NAME*"
          placeholder="First Name"
          :dirty="v$.firstName.$dirty"
          :errorMessage="v$.firstName.$errors[0]?.$message"
          v-model:value="state.firstName"
        />
        <the-textfield
          class="mb-6 md:mb-0"
          name="last-name"
          label="LAST NAME*"
          placeholder="Last Name"
          :dirty="v$.lastName.$dirty"
          :errorMessage="v$.lastName.$errors[0]?.$message"
          v-model:value="state.lastName"
        />
        <the-textfield
          class="mb-6 md:mb-12"
          type="email"
          name="email"
          label="EMAIL*"
          placeholder="email@webaddress.com"
          :dirty="v$.email.$dirty"
          :errorMessage="v$.email.$errors[0]?.$message"
          v-model:value="state.email"
        />
        <the-textfield
          class="mb-6 md:mb-0"
          type="email"
          name="confirm-email"
          label="CONFIRM EMAIL*"
          placeholder="email@webaddress.com"
          tooltip="Please confirm your email address"
          :dirty="v$.confirmEmail.$dirty"
          :errorMessage="v$.confirmEmail.$errors[0]?.$message"
          v-model:value="state.confirmEmail"
        />
        <the-textfield
          class="mb-6 md:mb-12"
          name="phone-number"
          label="MOBILE*"
          placeholder="(000) 000-0000"
          tooltip="Pleased provide your full phone number including area code"
          mask="(000) 000-0000"
          maxLength="14"
          :dirty="v$.phoneNumber.$dirty"
          :errorMessage="v$.phoneNumber.$errors[0]?.$message"
          v-model:value="state.phoneNumber"
        />
        <the-textfield
          name="zip-code"
          label="ZIP CODE*"
          placeholder="00000"
          tooltip="Please provide a five or nine digit zip code"
          mask="00000[-0000]"
          maxLength="10"
          :dirty="v$.zipCode.$dirty"
          :errorMessage="v$.zipCode.$errors[0]?.$message"
          v-model:value="state.zipCode"
        />
      </div>
      <fieldset class="mt-12 text-lg">
        <legend class="font-MyriadProRegular mb-2">
          I would like to receive confirmation and reminders
        </legend>
        <div class="text-acura-gray-darker">
        <the-checkbox
          name="sign"
          :label="`Sign me up for communications with general information about new models,
          special offers, and ${eventType === 'consumer' ? 'Acura or Honda'
          : eventType === 'acuratraining' || eventType === 'zdxdrive'
          ? 'Acura' : 'Honda'} stories.`"
          @input="state.signMe = $event"
        />
          <span v-if="state.signMe">
            <p class="pl-[35px] text-acura-gray-darker">
              By checking this box, I agree to: (1) receive recurring automated marketing
              and non-marketing calls, texts, and emails from American Honda Motor Co., Inc.
              and participating Honda and Acura dealers at any phone numbers and email
              addresses provided above (consent not required to make a purchase, msg &
              data rates apply, reply STOP to opt-out of texts); (2) the
              <a href="https://www.honda.com/privacy/sms-terms.pdf" target="_blank"
              class="underline font-AvenirBlack">SMS Terms</a> (including arbitration provision);
              and (3) the <a href="https://www.honda.com/privacy/privacy-policy.pdf"
              target="_blank" class="underline font-AvenirBlack">Privacy Notice</a>
              (which describes how Honda collects and uses personal
              information and any privacy rights I may have).”
            </p>
          </span>
        </div>
      </fieldset>
      <fieldset class="mt-4 md:mt-16">
        <legend class="font-MyriadProRegular text-lg mb-2">
          Do you currently own
          {{eventType === 'consumer'
            ? 'an Acura or Honda'
            : eventType === 'acuratraining' || eventType === 'zdxdrive'
            ? 'an Acura'
            : 'a Honda'}}
          vehicle?
        </legend>
        <the-radio
          name="yesOrNo"
          @input="state.yesOrNo = $event"
          :options="[
            { id: 'yes', label: 'Yes', value: 'yes' },
            { id: 'no', label: 'No', value: 'no' },
          ]"
        />
        <div v-if="state.yesOrNo === YesNo.YES">
          <the-textfield
            :name="model-and-model-year"
            label="MODEL YEAR AND MODEL:"
            placeholder="YYYY Model"
            :dirty="v$.modelYear.$dirty"
            :errorMessage="v$.modelYear.$errors[0]?.$message"
            v-model:value="state.modelYear"
            class="mt-5"
            mask="0000 C"
          />
        </div>
      </fieldset>
      <fieldset class="md:mt-16">
        <legend class="font-MyriadProRegular text-lg mb-2">
          What is your opinion of the
          {{ eventType === 'consumer'
            ? 'Acura and Honda brands?'
            : eventType === 'acuratraining' || eventType === 'zdxdrive'
            ? 'Acura brand?'
            : 'Honda brand?'}}
        </legend>
        <the-radio
          name="acuraBrand"
          @input="state.acuraBrand = $event"
          :options="[
            { id: 'veryFavorable', label: 'Very Favorable', value: 0 },
            { id: 'favorable', label: 'Favorable', value: 1 },
            { id: 'neutral', label: 'Neutral', value: 2 },
            { id: 'unfavorable',label: 'Unfavorable', value: 3 },
            { id: 'veryUnfavorable', label: 'Very Unfavorable', value: 4 },
          ]"
        />
      </fieldset>
      <p
        class="md:mt-16 leading-10 font-MyriadProItalic text-lg"
      >
        <i>
          <b>Please Note:</b> Must be 18 years of age or older with  a valid driver's
          license to participate in the driving activities.<br />
          Please leave weapons, alcohol, and controlled substances at
          home to maintain a safe driving environment.
          <template v-if="eventType === 'zdxdrive' || eventType === 'prologuedrive'">
            <br />
            Participants will be asked to pass a breathalyzer test in order to
            participate.
          </template>
        </i>
      </p>
      <div>
      <template v-if="eventType === 'consumer'">
        <h2 class="font-MyriadProLight text-xl lg:text-4xl mt-6 lg:mt-[5.875rem] mb-7">
          Guest Information
        </h2>
        <div
          class="grid grid-cols-1 lg:grid-cols-2 gap-y-7 lg:gap-y-12 gap-x-5">
          <the-textfield
            class="mb-6 md:mb-12"
            name="guest-first-name"
            label="GUEST FIRST NAME:"
            placeholder="Guest First Name"
            :dirty="v$.guestFirstName.$dirty"
            :errorMessage="v$.guestFirstName.$errors[0]?.$message"
            v-model:value="state.guestFirstName"
          />
          <the-textfield
            name="guest-last-name"
            label="GUEST LAST NAME:"
            placeholder="Guest Last Name"
            :dirty="v$.guestLastName.$dirty"
            :errorMessage="v$.guestLastName.$errors[0]?.$message"
            v-model:value="state.guestLastName"
          />
        </div>
      </template>
      <div class="
        flex
        flex-col
        md:flex-row
        justify-end
        items-center
        gap-4
        md:gap-0
        mt-[1rem]
        md:mt-[3rem]">
      <vue-recaptcha
        :sitekey="captchaSiteKey"
        @verify="validateRecaptcha"
        @expire="recaptchaRef.reset()"
        ref="recaptchaRef"/>
      <button
        @click.prevent="submitForm()"
        class="
          uppercase text-white py-[0.925rem]
          lg:px-[3.75rem] w-full lg:w-auto lg:ml-6 my-auto
          text-[1.175rem]
          font-MyriadProLight
          tracking-[2px]
          font-bold
          bg-dynamic rounded-[.5rem]
          disabled:bg-acura-gray-dark
          disabled:cursor-not-allowed
        "
        :disabled="loading"
        >
        Submit
        <font-awesome-icon
          v-if="loading"
          :icon="faSpinner"
          class="ml-2 animate-spin"
        />
      </button>
      </div>
      <p class="lg:mt-[4.5rem] font-MyriadProRegular text-lg">
        To learn more about how we collect and use
        personal information, please read our
        <a href="https://www.honda.com/privacy/privacy-policy.pdf" target="_blank" class="underline font-MyriadProBold">Privacy Notice</a>.
      </p>
    </div>
    </form>
  </div>
</template>
