<template>
  <div
    class="w-full overflow-y-scroll border-2 p-4 border-[#CDCDCD] mb-4"
    :class="!showFullWaiver ? 'h-[30rem]' : 'h-auto'"
    name="text-area-honda-waiver"
    id="text-area-honda-waiver"
    ref="containerRef"
  >
    <slot />
  </div>
</template>

<style scoped lang="scss">
p {
  @apply my-4;
}
</style>

<script setup>
import { ref } from 'vue';

defineProps({
  showFullWaiver: { type: Boolean, required: false },
});

const containerRef = ref();
const resetToTop = () => {
  containerRef.value.scrollTo({ top: 0, behavior: 'smooth' });
};

defineExpose({ resetToTop });
</script>
