<script setup>
import { computed } from 'vue';
import router from '@/router';
import store from '@/store';
import { handleRouteWithEvent } from '@/utils/eventType';

const props = defineProps({
  multipleDays: Boolean,
  startTime: String,
  endTime: String,
  available: Number,
  sessionId: Number,
  locationId: Number,
});

const formatTime = (dateTime) => {
  const date = new Date(dateTime);
  const hours = date.getHours();
  const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  const meridiem = hours >= 12 ? 'PM' : 'AM';
  return `${hours > 12 ? hours - 12 : hours}:${minutes} ${meridiem}`;
};

const formatDate = (dateTime) => {
  const date = new Date(dateTime);
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${month}/${day}`;
};

const formattedTime = computed(() => formatTime(props.startTime));
const isAvailable = computed(() => props.available > 0);
const eventType = computed(() => store.getters['user/eventType']);

const navigateTo = (path) => {
  const routeWithEvent = handleRouteWithEvent(eventType.value, path);
  router.push(routeWithEvent);
};

</script>
<template>
  <div class="
    bg-[#FFF]
    px-[.5rem] md:px-0
    pt-[1.1rem] md:pt-0
    flex flex-col md:flex-row
    rounded-[0.625rem]
    min-h-[5.625rem]
    lg:flex
    items-center
  ">
    <div class="h-full w-full flex flex-col justify-center md:ml-[4rem]">
      <div
        class="w-full lg:flex justify-between mb-[1rem] md:mb-0"
        :class="{ 'text-[#8f8f8f]': !isAvailable}"
      >
        <div class="h-full flex justify-center">
          <p
            v-if="props.multipleDays"
            class="
              p-0
              pr-2
              font-MyriadProSemiBold
              text-[1.25rem] md:text-[1.5rem] lg:text-[2rem]
              w-[8rem]
            "
            :class="!isAvailable ? 'text-[#747474]' : 'text-dynamic'"
          >
            {{ formatDate(props.startTime) }}
          </p>
          <p
            class="
              p-0
              pr-2
              font-MyriadProSemiBold
              text-[1.25rem] md:text-[1.5rem] lg:text-[2rem]
            "
            :class="!isAvailable ? 'text-[#747474]' : 'text-dynamic'"
          >
            {{ formatTime(props.startTime) }}
          </p>
        </div>
        <div class="h-full flex flex-col justify-center">
          <p
            class="
              p-0
              uppercase
              font-MyriadProRegular
              text-[1.0625rem] md:text-[1.25rem] lg:text-[1.5rem]
            "
            :class="!isAvailable ? 'text-[#747474]' : 'text-black'"
          >
            {{ available }} Seats Remaining
          </p>
        </div>
      </div>
    </div>
    <div class="
      mb-[.75rem] md:mb-0
      md:mx-[4rem]
      text-white
      text-[1rem] md:text-[1.25rem]
      tracking-[0.035rem] lg:tracking-[0.045rem]
      font-MyriadProLight
      font-bold
      w-full
      max-w-[12.5rem]
      h-full
      flex
      flex-col
      justify-center
    ">
      <button v-if="isAvailable"
        class="
          bg-dynamic
          w-full
          h-[2.75rem] md:h-[3.5rem]
          mx-auto
          rounded-[.875rem]
          block
          text-center
        "
        @click="navigateTo(`/registration?action=register&id=${sessionId}&location=${locationId}`)"
        :aria-label="`Go to Registration for the ${formattedTime} slot`">
        REGISTER
      </button>
      <button v-if="!isAvailable"
        class="
          bg-[#747474]
          w-full
          h-[2.75rem] md:h-[3.5rem]
          mx-auto
          rounded-[.875rem]
          block
          text-center
        "
        @click="navigateTo(`/registration?action=waitlist&id=${sessionId}&location=${locationId}`)"
        :aria-label="`Go to Registration for the waitlist for the ${formattedTime} slot`">
        JOIN WAITLIST
      </button>
    </div>
  </div>
</template>
