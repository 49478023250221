<script setup>
/* eslint-disable */
import { computed } from 'vue';
import HeroHeader from "@/components/global/HeroHeader.vue";
import HeroHeaderVideo from "@/components/global/HeroHeaderVideo.vue";
import LocationInfo from "@/components/location/LocationInfo.vue";
import EventSchedule from "@/components/location/EventSchedule.vue";
import store from '@/store';

const props = defineProps({
  sessions: Array,
  location: Object,
});

const locations = computed(() => store.state.locations.locations);
const eventType = computed(() => store.getters['user/eventType']);

const handleLocationImage = () => {
    const image = locations.value.filter((location) => location.locationId === props.location.locationId)[0].heroBg;
    return image + ' xl:bg-[length:102%_auto]';
};
</script>
<template>
  <div
    v-if="location"
    class="
      pb-24
      mx-5
      lg:pb-[9.5rem] lg:bg-auto
    "
  >
    <hero-header 
      class="
        h-[100px]
        lg:h-[594px]
      "
      :title="location.city"
      :bgClass="handleLocationImage()"
    >
    </hero-header>
    <div class="w-full mx-auto max-w-[100rem] pt-[1.5rem] md:pt-[5.625rem]">
      <location-info
        v-bind="location"
        class="mb-[2.5rem] lg:mb-[8rem]"
      ></location-info>
      <event-schedule
        :eventType="eventType"
        :multipleDays="location.dates.length > 1"
        :sessions="sessions"
        :locationId="location.locationId"
      ></event-schedule>
    </div>
  </div>
</template>
