<template>
  <p class="text-xl font-bold text-center">
    RELEASE OF CLAIMS, HOLD HARMLESS AND INDEMNITY AGREEMENT, AND WAIVERS
  </p>
  <p class="text-xl text-center underline">Evolution Tour (Honda/Acura Tour)</p>
  <p>
    In Consideration of, and as a condition to being permitted to attend and/or participate in the
    Honda Drive Event at the date and location indicated above (“Event”), the undersigned person
    (the “Attendee”), for and on behalf of i) himself/herself, his/her personal representatives,
    heirs and next of kin; and ii) if applicable, one or more minor children or other minor persons
    (who must be at least 16 years of age to ride in a vehicle) committed into Attendee’s care at
    the Event (each a “Charge” and collectively the “Charges” of Attendee), acknowledges, represents
    and agrees as follows:
  </p>
  <p>
    1. Attendee Representations: If Attendee will operate a motor vehicle at the Event, Attendee
    (individually and on behalf of any Charge) represents and warrants that he/she: <br />
    a) Possesses a current valid driver’s license, is 18 years of age or older, and is a citizen or
    legal resident of the United States;<br />
    b) can see and hear well enough to operate a motor vehicle properly and safely on public
    roadways and in off-roadway driving and is wearing any eye wear or hearing apparatus required to
    operate a motor vehicle properly and safely;<br />
  </p>

  <p class="html2pdf__page-break">
    c) will operate the vehicle in a safe manner, will properly wear seat belts while in a vehicle
    in operation, and will follow instructions, recommendations and cautions of the representatives
    of Jackson Dawson Communications, Inc., a Michigan corporation (“JDC”) and their vendors at all
    times;
  </p>
  d) will not be under the influence of any alcohol or illegal drug; and e) will not be under the
  influence of any legal drug which would impair his/her driving ability or ability to understand or
  comply with oral or written directions regarding the use of a vehicle, and does not have any
  physical or mental condition which would impair his or her driving ability to operate the vehicle,
  nor does Attendee have any physical or mental condition which would make participation in a
  performance drive test an undue risk to Attendee, other vehicle occupants, workers, spectators or
  vehicle. 2. The Attendee (individually and on behalf of any Charge) hereby acknowledges and agrees
  as follows: <br />
  a) That he/she is duly aware of the risks and hazards inherent in participating in operating or
  riding as a passenger in a motorized vehicle and hereby elects to participate and voluntarily
  assumes all risk of loss, danger or injury, including death, that may be sustained by he/her or
  any loss or damage to any of his/her property. The Attendee further agrees and warrants that if at
  any time he/she is participating in any portion of the event which he/she deems or believes to be
  unsafe, that he/she will immediately advise the officials or operators of the event of such and
  will leave the area and discontinue participation in that portion of the event which he/she
  considers to be unsafe. THE ATTENDEE (INDIVIDUALLY AND ON BEHALF OF ANY CHARGE) EXPRESSLY
  ACKNOWLEDGES AND AGREES THAT THE ACTIVITIES INCLUDED IN PARTICIPATING IN THE EVENT MAY BE
  DANGEROUS AND MAY INVOLVE RISK OF SERIOUS INJURY AND/OR DEATH AND/OR PROPERTY DAMAGE; <br />
  <p class="html2pdf__page-break">
    b) That he/she will only be permitted to participate in the Event if representatives of JDC or
    their contractors determine in their sole discretion that Attendee is in compliance with Event
    guidelines and the representatives’ instructions, and is not engaging in any activity which
    presents any additional danger to herself/ himself or others participating in the Event;
  </p>
  c) That he/she is aware that in connection with the Event, JDC may make video and/or audio
  recordings of Attendee and other participants in the Event (the “Videotape”). Attendee and other
  participants agrees to relinquish all rights, if any, that he/she may have related to and/or
  associated with the Videotape and acknowledges and agrees that all rights appertaining thereto,
  including any right, title and interest in or to any present or future copyrights related to the
  Videotape or any derivative works, as well as any still photos, audiotapes, advertisements,
  releases, or derivations associated with or related to the Videotape and the Event, shall belong
  exclusively and unconditionally to JDC and its affiliates and subsidiaries, and their respective
  successors and assigns, without payment of any royalties, compensation, fees or consideration of
  any kind above and beyond the consideration described in the Waiver and Release. JDC shall, now
  and forever, have the exclusive right, title, and interest to use, edit, display, reproduce, sell,
  distribute, or otherwise disseminate the Videotape and any derivative works for any commercial or
  promotional or other purposes and in any manner that JDC (and its agents, publicists, and
  authorized persons or entities) desires; and <br />
  <p>
    d) That he/she understands that sport utility vehicles (SUVs) handle differently than
    traditional passenger cars. In particular, sharp turns or abrupt maneuvers on the Event course
    can result in unanticipated loss of control or even a vehicle rollover
  </p>
  <p class="html2pdf__page-break">
    3. Release by Attendee. <br />
    Attendee (individually and on behalf of any Charge) hereby releases, waives, and discharges all
    claims and potential claims against, and covenants not to sue JDC and its parent corporation,
    subsidiaries or affiliated entities, and all of the respective officers, directors,
    shareholders, employees and agents of the foregoing and the facilities (the “Released Parties”),
    from and against any and all claims, causes of action, damages, demands, liens, rights,
    controversies, losses, costs and expenses (including, but not limited to, attorneys’ fees and
    costs), or charges of whatsoever nature, whether known or unknown, developed or undeveloped,
    suspected or unsuspected, fixed or contingent, past, present or future, including, without
    limitation, wrongful death and bodily injury, any injuries to property, real or personal,
    whether or not such liability is alleged to arise from NEGLIGENCE OR OTHER TORTIOUS CONDUCT of
    Released Parties, or any of them (“Claim” or “Claims”), with respect to any damages, injuries or
    losses of any kind to the Attendee or any vehicle or any property damage or loss or injury to
    any other party arising in connection with the Attendee’s participation in this Event or of the
    Attendee’s operation or use of the vehicle.
  </p>
  <p>
    4. Release of Unknown or Unanticipated Claims. The Attendee (individually and on behalf of any
    Charge) further agrees and acknowledges that there is a risk that subsequent to the execution
    hereof, the Attendee or a Charge will discover, incur or suffer Claims which were unknown or
    unanticipated at the time of execution, including without limitation, unknown or unanticipated
    Claims which arose from, are based upon, or are related to the Attendee’s or a Charge’s physical
    proximity to, or other connection with any vehicles, equipment, or other items made available by
    or through the Released Parties, which if known by the Attendee or Charge(s) on the date of
    execution hereof may have materially affected the Attendee’s decision to agree to this release.
  </p>
  <p class="html2pdf__page-break">
    5. Waiver of Cal. Civil Code § 1542. <br />
    The Attendee (individually and on behalf of any Charge) acknowledges and agrees that by reason
    of the release contained above, the Attendee and each Charge is assuming all risk of bodily
    injury, death or property damage, and all other unknown and unanticipated Claims and agrees that
    the Attendee’s release of Released Parties applies thereto. The Attendee (individually and on
    behalf of any Charge) expressly waives whatever benefits the Attendee or Charge may have under
    Section 1542 of the California Civil Code (or similar applicable law from another state), which
    reads as follows: A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW
    OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM
    MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR. Thus, notwithstanding the
    provisions of California Civil Code Section 1542, and for the purpose of implementing a full and
    complete release and discharge of the Released Parties, the Attendee (individually and on behalf
    of any Charge) expressly acknowledges that this release is intended to include in its effect,
    without limitation, all Claims which the Attendee or any Charge does not know or suspect to
    exist in Attendee’s or a Charge’s favor at the time of execution hereof, and that this release
    contemplates the extinguishment of any such Claim or Claims.
  </p>
  <p>
    6. COVID-19 Acknowledgment. <br />
    Attendee (individually and on behalf of any Charge) acknowledges the contagious nature of
    coronavirus disease 2019 and all variants thereof (“COVID”) and that applicable public health
    authorities recommend social distancing practices. Attendee (individually and on behalf of any
    Charge) further acknowledges that the Released Parties cannot guarantee that Attendee or a
    Charge will not contract COVID through participation in the Event. Attendee (individually and on
    behalf of any Charge) understands that the risk of becoming exposed to and/or infected by COVID
    may result from the acts or omissions of Attendee or a Charge, Event staff and others Event
    attendees, and that such exposure may result in personal injury, illness, temporary or permanent
    disability, or even death. Attendee (individually and on behalf of any Charge) hereby voluntary
    elects to participate in the Event and acknowledges that Attendee and any Charge is increasing
    his/her risk of exposure to COVID.
  </p>
  <p class="html2pdf__page-break">
    7. Indemnity; Waiver. <br />
    In consideration for the Event, JDC agrees to allow i) Attendee to drive in its vehicle and on
    its course(s); and ii) the Charge to ride as a passenger during the Event, Attendee
    (individually and on behalf of any Charge) agrees to hold harmless, indemnify and defend the
    Released Parties (as defined above) from and against any and all claims, demands, actions
    (including attorney’s fees and costs) liability, losses, damages, injuries (including, without
    limitation death and dismemberment) and accidents of whatever kind resulting in any way from
    participating in events at the Event. Attendee (individually and on behalf of any Charge)
    further understands and agrees that he/she assumes full financial responsibility up to full
    replacement value of the Event vehicle assigned to Attendee for any and all damage Attendee
    causes to the vehicle.
  </p>
  <p>
    8. Vehicle Loan Conditions. <br />
    Borrower and Authorized User(s) of the Vehicle acknowledge that Vehicle is a pre-production
    Vehicle that does not conform to some or all governmental regulations, potentially including
    Federal Motor Vehicle Safety Standards and Regulations regarding required safety equipment
    and safety performance.
  </p>
  <p class="html2pdf__page-break">
    9. Miscellaneous. <br />
    Attendee (individually an on behalf of any Charge) acknowledges that this waiver and release
    agreement and the releases contained herein are given in exchange for good and valuable
    consideration. Further, this agreement reflects the entire agreement, and no statements,
    promises or inducements made by JDC, or its directors, officers, employees, agents, and
    representatives that are not contained herein shall be valid or binding. If any provision in
    this agreement is held invalid or unenforceable, in whole or in part, by a court of competent
    jurisdiction, the remainder of the provisions or enforceable parts of this agreement shall not
    be affected, and shall be enforced to the fullest extent permitted by law. This agreement shall
    be governed by the laws of the state of Michigan. Ambiguities herein shall not be construed
    against the drafter. Attendee (individually an on behalf of any Charge) further expressly agrees
    that the foregoing release and waiver is intended to be as broad and inclusive as is permitted
    by applicable law, and, if any portion thereof is held invalid, it is agreed that the balance
    shall, notwithstanding, continue in full legal force and effect.
  </p>
  <p class="html2pdf__page-break">
    <b>ATTENDEE CONDUCT REQUIREMENTS FOR EVENT PARTICIPATION</b> <br />
    I, the UNDERSIGNED (on behalf of myself and any Charge), further agree that, as a condition of
    participation the EVENT, I will… <br />
    1. …not drive a vehicle at the EVENT while impaired by alcohol, drugs, medication, illness,
    fatigue, or injury. <br />
    2. …not drive a vehicle at the EVENT if any physical or medical condition exists that may be
    aggravated by my participation in, or that may impair my ability to participate in, the EVENT.
    <br />
    3. …properly wear my safety belt at all times while in a vehicle at the EVENT. <br />
    4. …have two hands on the steering wheel while driving a vehicle at the EVENT. <br />
    5. …obey all applicable motor vehicle laws, codes, and regulations. <br />
    6. …follow all instructions and rules posted at the EVENT or provided by EVENT officials.
    <br />
    7. …drive in a defensive manner, anticipating situations where incidents are likely to occur.
    <br />
    Furthermore, I verify that I have not been convicted within the past 36 months of any of the
    following motor vehicle violations: <br />
    1. Driving while operator’s license is suspended, revoked, or denied. <br />
    2. Vehicular manslaughter, negligent homicide, felonious driving or felony with a vehicle.
    <br />
    3. Operating a vehicle while impaired, under the influence of alcohol or illegal drugs, or
    refusing a sobriety test. <br />
    4. Failure to stop or identify under a crash (includes leaving the scene of a crash; hit and
    run; giving false information to an officer). <br />
    5. Eluding or attempting to elude a law enforcement officer. <br />
    6. Traffic violation resulting in death or serious injury. <br />
    7. Any other significant violation warranting suspension of license.
  </p>
  <p>
    I HAVE READ AND UNDERSTAND THIS WAIVER AND RELEASE AGREEMENT AND (INDIVIDUALLY AND ON BEHALF OF
    ANY CHARGE) VOLUNTARILY SIGN IT, AND FURTHER ACKNOWLEDGE AND AGREE THAT NO OTHER ORAL
    REPRESENTATIONS, STATEMENTS OR INDUCEMENTS OR PROMISES HAVE BEEN MADE BY JDC OR ITS DIRECTORS,
    OFFICERS, EMPLOYEES, AGENTS, AND REPRESENTATIVES THAT ARE NOT CONTAINED HEREIN, AND THAT NO SUCH
    FUTURE STATEMENTS SHALL BE VALID OR BINDING UNLESS MADE IN WRITING SIGNED BY AN AUTHORIZED
    REPRESENTATIVE, AND THAT I HAVE BEEN OFFERED THE OPPORTUNITY NOT TO PARTICIPATE IN THE PROGRAM
    IF I DO NOT AGREE
  </p>
</template>

<style scoped lang="scss">
p {
  @apply my-4;
}
</style>
