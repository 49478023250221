<template>
  <TheFieldset legend="Filter Waivers" class="grid grid-cols-4 gap-4 mx-2 mb-4 text-nowrap">
    <div>
      <NewSelect
        fixedLabel
        label="Event"
        v-model="state.locationName" inlineOption="Select Event" :options="locationsToFilter" />
    </div>
    <TheDatePicker v-model="state.from" label="From:" />
    <TheDatePicker v-model="state.to" label="To:" />

    <div class="grid grid-cols-2 items-center gap-4 mt-auto">
      <TheButton
        @click="submitFilters()"
        class="border-2 h-[54px]"
        custom-bg="bg-[#2D7AB9] border-[#2D7AB9]"
        label="SEARCH"
      />
      <TheButton
      @click="clearFilters()"
      class="border-2 h-[54px]"
      custom-bg="bg-[#2D7AB9] border-[#2D7AB9]"
      label="Clear Filters" />
    </div>
  </TheFieldset>
</template>

<script setup>
import {
  defineProps,
  onMounted,
  reactive,
  ref,
} from 'vue';
import TheFieldset from '@/components/global/TheFieldset.vue';
import NewSelect from '@/components/global/NewSelect.vue';
import TheButton from '@/components/global/TheButton.vue';
import TheDatePicker from '@/components/global/TheDatePicker.vue';
import { useToast } from 'vue-toastification';
import api from '@/api';

const toast = useToast();

const state = reactive({
  from: '',
  to: '',
  locationName: '',
});

defineProps({
  token: String,
});

const emit = defineEmits(['emit']);

const locationsToFilter = ref([]);

const fetchLocation = async () => {
  try {
    const { data } = await api.waiver.getWaiverLocations();
    locationsToFilter.value = data.map((item) => ({ name: item, value: item }));
  } catch ({ message }) {
    toast.error(message || 'An unexpected error occurred');
  }
};

onMounted(() => {
  fetchLocation();
});

const clearFilters = () => {
  state.from = '';
  state.to = '';
  state.locationName = '';

  emit('filter', state);
};

const submitFilters = () => {
  emit('filter', state);
};
</script>
