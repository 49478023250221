<script setup>
import apiManager from '@/api';
import locationsContext from '@/store/modules/locations/locationsContext';
import 'v-calendar/dist/style.css';
import {
  ref,
  reactive,
  computed,
  watch,
  onMounted,
} from 'vue';
import { formatTime, formatDate2 } from '@/utils/format';
import TheSelect from '../global/TheSelect.vue';
import TheFieldset from '../global/TheFieldset.vue';
import TheButton from '../global/TheButton.vue';

// form inputs
const initialInputs = {
  eventType: '',
  location: '',
  session: '',
  sessionTime: '',
  date: '',
};

const inputs = reactive({ ...initialInputs });

const event = [
  {
    name: 'Consumer Event',
    value: '0',
  },
  {
    name: 'Acura Training',
    value: '1',
  },
  {
    name: 'Honda Training',
    value: '2',
  },
  {
    name: 'ZDX Drive',
    value: '3',
  },
  {
    name: 'Prologue Drive',
    value: '4',
  },
];

const locations = computed(() => Object.values(locationsContext).map((location) => ({
  name: location.linkName,
  value: location.id,
})));

const sessions = ref([]);

const dates = ref([]);

const locationsFilter = ref([]);

const getLocations = async (eventType) => {
  const { data } = await apiManager.reservations.getAllLocations(eventType);
  return data;
};

const getSessions = async (eventType, locationId, eventDate) => {
  const { data } = await apiManager.report.getSessions({
    eventType, locationId, eventDate,
  });
  return data;
};

const getDates = async () => {
  const { data } = await apiManager.report.getSessionsAll();
  return data;
};

const removeDuplicateDates = (datesArray) => {
  const uniqueDates = {};
  const newData = datesArray
    .filter((date) => {
      const formattedDate = date.name;
      if (!uniqueDates[formattedDate]) {
        uniqueDates[formattedDate] = true;
        return true;
      }
      return false;
    });

  return newData;
};

const fetchDatesBySessions = (data) => {
  const newData = Object.values(data).map((date) => ({
    name: `${formatDate2(date.startTime)}`,
    value: `${formatDate2(date.startTime).split('T')[0]}`,
  }));
  dates.value = removeDuplicateDates(newData);
};

const loadSessions = () => {
  getSessions(inputs.eventType, inputs.location, inputs.date).then((data) => {
    sessions.value = Object.values(data).map((session) => ({
      name: `${formatDate2(session.startTime)} - ${formatTime(session.startTime)}`,
      value: session.sessionId,
    }));

    fetchDatesBySessions(data);
  }).catch((error) => {
    console.error(error);
  });
};

const loadDates = () => {
  getDates().then((data) => {
    fetchDatesBySessions(data);
  }).catch((error) => {
    console.error(error);
  });
};

const loadLocations = () => {
  if (!inputs.eventType) return;
  getLocations(inputs.eventType).then((data) => {
    locationsFilter.value = Object.values(data).map((location) => ({
      name: location.city,
      value: location.locationId,
    }));
  }).catch((error) => {
    console.error(error);
  });
};

const uniqueStartDates = computed(() => {
  const allDates = sessions.value.map((item) => item.name.split(' - ')[0]);
  const uniqueDates = [...new Set(allDates)];
  return uniqueDates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime()).map((date) => ({
    name: date,
    value: date,
  }));
});

const filteredSessionsByDate = computed(() => {
  if (!inputs.date) return [];

  const targetDate = inputs.date;
  return sessions.value.filter((session) => {
    const sessionDate = session.name.split(' - ')[0];
    return sessionDate === targetDate;
  });
});

const clearOptions = () => {
  inputs.eventType = initialInputs.eventType;
  inputs.location = initialInputs.location;
  inputs.date = initialInputs.date;
  inputs.session = initialInputs.session;
  inputs.sessionTime = initialInputs.sessionTime;
};

// Emits
const emit = defineEmits(['submit']);

const clearOptionsAndHideTable = () => {
  clearOptions();
  emit('clearAndHideTable');
};

const getMetrics = () => emit('submit', inputs);

watch(() => inputs.eventType, (newEventType) => {
  getLocations(newEventType);
}, { immediate: true });

watch(() => [inputs.eventType, inputs.location], loadSessions, { immediate: true });

watch(() => inputs.session, () => {
  const sessionsList = inputs.date ? filteredSessionsByDate.value : sessions.value;
  inputs.sessionTime = sessionsList.find(
    (sessionItem) => sessionItem.value === inputs.session,
  )?.name;
}, { immediate: true });

watch(() => getDates(), loadDates, { immediate: true });

watch(() => inputs.eventType, loadLocations, { immediate: true });

onMounted(
  loadSessions,
  loadDates,
);
</script>
  <template>
    <div>
      <TheFieldset
        legend="Filter Metrics"
      >
        <form @submit.prevent="getMetrics"
          class="grid md:grid-cols-1 xl:grid-cols-4  gap-4"
        >
          <TheSelect
            name="event-locations"
            placeholder="All Events"
            :options="event"
            v-model:modelValue="inputs.eventType"
          />
          <TheSelect
            name="locations"
            placeholder="All Locations"
            :options="inputs.eventType ? locationsFilter : locations"
            v-model:modelValue="inputs.location"
          />
          <TheSelect
            name="date"
            placeholder="All Dates"
            :options="inputs.eventType && inputs.location ? uniqueStartDates : dates"
            v-model:modelValue="inputs.date"
          />
          <TheSelect
            name="session"
            placeholder="All Sessions"
            :options="inputs.date ? filteredSessionsByDate : sessions"
            v-model:modelValue="inputs.session"
          />
          <TheButton
            @click.prevent="clearOptionsAndHideTable"
            class="
              md:col-start-1
              xl:col-start-3
              border-2
            "
            label="CLEAR FILTERS"
          />
          <TheButton
            type="submit"
            class="border-2"
            label="GET METRICS"
          />
        </form>
      </TheFieldset>
    </div>
  </template>
  <style scoped>
    select option {
      text-transform: capitalize;
    }

    select, input {
      border: 1px solid black;
    }
  </style>
