<template>
<div class="container">
    <h2 class="mb-5 text-center" v-html="text">
    </h2>
    <div class="flex justify-center gap-4">
      <button
      :key="option"
      v-for="option in options"
      @click.stop="handleClick(option)"
        class="
        uppercase text-white border
        border-box border-white py-2
        px-8
      ">
        {{ option.name }}
      </button>
    </div>
  </div>
</template>

<script setup>
defineProps({
  text: { type: String, default: 'Are you sure?' },
  options: {
    type: Array,
    default: () => [
      { name: 'Yes', triggerClick: true },
      { name: 'No', triggerClick: false },
    ],
  },
});

const emit = defineEmits(['click', 'close-toast']);

const handleClick = (option) => {
  if (option.triggerClick) {
    emit('click');
  }
  emit('close-toast');
};
</script>
