import { baseCRUDAPI } from './base';

export default class Home extends baseCRUDAPI {
  constructor(axiosObject) {
    super(axiosObject, 'Report');
  }

  async get({
    locationId,
    eventType,
    eventDate,
    sessionId,
  }) {
    return this.axiosObject.get(
      `${this.apiBaseName}/report`,
      {
        params: {
          locationId,
          eventType,
          eventDate,
          sessionId,
        },
      },
    );
  }

  async getFilterAll() {
    return this.axiosObject.get(`${this.apiBaseName}/filter`);
  }

  async getSessionsAll() {
    return this.axiosObject.get(`${this.apiBaseName}/sessions`);
  }

  async getSessions({
    locationId,
    eventType,
    eventDate,
  }) {
    return this.axiosObject.get(
      `${this.apiBaseName}/sessions`,
      {
        params: {
          locationId,
          eventType,
          eventDate,
        },
      },
    );
  }
}
