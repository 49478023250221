<script setup>
import { ref, defineProps } from 'vue';
import { useToast } from 'vue-toastification';

import apiManager from '@/api';
import SinglePrint from './workMethods/SinglePrint.vue';
import BatchPrint from './workMethods/BatchPrint.vue';
import TheButton from '../global/TheButton.vue';
import TheModal from '../global/TheModal.vue';
import TheTabs from '../global/TheTabs.vue';
import BatchInstructionsModal from './modals/BatchInstructionsModal.vue';

const toast = useToast();

const tabs = [
  { name: 'Single Badge Printing', slot: 'singleBadge' },
  { name: 'Bulk Badge Printing', slot: 'bulkBadge' },
];

const props = defineProps({
  token: String,
});

const isClearing = ref(false);
const batchInstructionsModal = ref(false);

const clearPrinterQueue = async () => {
  isClearing.value = true;
  try {
    await apiManager.print.clearQueue();
    toast.success('Success! Printer Queue Cleared.');
  } catch (err) {
    toast.error('Something wrong happened. Please, try again.');
  } finally {
    isClearing.value = false;
  }
};

const activeTab = ref(0);

const changeTab = (selectedTab) => {
  activeTab.value = selectedTab;
};

const toggleInstructionsModal = () => {
  batchInstructionsModal.value = !batchInstructionsModal.value;
};
</script>

<template>
  <header class="flex justify-between">
    <h1 class="mb-10 text-2xl sm:text-3xl lg:text-4xl font-MyriadProLight uppercase">
      Badge Printing
      <span class="border-b-[6px] border-dynamic block w-[4rem]"></span>
    </h1>
    <TheButton
      @click="clearPrinterQueue"
      label="Clear Printer Queue"
      class="sm:px-8 mb-4 lg:col-start-4 lg:border-2"
      custom-bg="bg-[#2D7AB9] border-[#2D7AB9]"
      :loading="isClearing"
    />
  </header>
  <TheTabs
    class="col-span-10"
    :tabs="tabs"
    :active-tab="activeTab"
    @changeTab="changeTab">
    <template v-slot:singleBadge>
      <SinglePrint :token="props.token" />
    </template>
    <template v-slot:bulkBadge>
      <BatchPrint :token="props.token" @openInstructionsModal="toggleInstructionsModal" />
    </template>
  </TheTabs>

  <TheModal :isOpen="batchInstructionsModal" @handleClose="toggleInstructionsModal">
    <BatchInstructionsModal />
  </TheModal>
</template>
