<template>
  <div class="section mt-2 pb-8 py-4 md:p-4">
    <div class="container mx-auto mb-10 sm:mb-16">
      <h1
        class="
        font-MyriadProLight
        text-4xl
        md:text-6xl
        xl:text-[5.25rem]
        leading-[3rem]
        xl:leading-[5rem]
        font-medium
        uppercase"
        aria-label="Acura Locations"
      >
        {{ cards?.length > 1 ? "Locations" : "Location" }}
      </h1>
      <p class="font-MyriadProRegular text-lg md:text-xl mb-10 lg:mb-14" v-if="cards?.length">
        {{
          cards?.length > 1
            ? "Click on a location to begin registration."
            : "Click below to begin registration."
        }}
      </p>
      <div
        class="cards grid grid-cols-1 gap-[16px] lg:gap-[12px]"
        :class="
          originalCardsLength === 1
            ? 'xl:grid-cols-1'
            : originalCardsLength === 2
            ? 'xl:grid-cols-2'
            : originalCardsLength >= 3
            ? 'md:grid-cols-2 xl:grid-cols-3'
            : 'xl:grid-cols-1'
        "
        v-if="cards?.length"
      >
        <CardWithTitle
          class="card"
          :class="
            originalCardsLength === 1
              ? 'w-full h-[200px] md:h-[385px] 2xl:h-[582px]'
              : 'max-w-[592px] h-[300px] md:h-[390px]'
          "
          v-for="card in cards"
          :key="card"
          :img="card.cardBg"
          :isOne="originalCardsLength === 1"
          :altText="card.altText"
          :ariaText="card.ariaText"
          custom-class="bg-center"
          @clicked="navigateTo(card.path)"
        >
          <template v-slot:title>
            {{ card.city }}
          </template>
          <template v-slot:footer>
          <template v-if="card.dates.length > 0">
            <section :key="date" v-for="date in card.datesWithTimes">
            {{ formatDate(date.startTime) }}
            <b class="text-dynamic">|</b>
            {{ timeFormat(date.startTime) }} - {{ timeFormat(date.endTime) }}
            </section>
          </template>
          <template v-else>
            <p>Coming soon!</p>
          </template>
          </template>
        </CardWithTitle>
      </div>
      <div class="rounded-[1.8rem] bg-[rgb(245,245,245)] py-[2rem]" v-else>
        <p class="text-center text-lg md:text-xl lg:text-2xl">
          This event is now over. <br />
          Thank you for checking it out.
        </p>
      </div>
    </div>
  </div>
</template>
<script setup>
import store from '@/store';
import CardWithTitle from '@/components/global/CardWithTitle/CardWithTitle.vue';
import { handleRouteWithEvent } from '@/utils/eventType';
import { computed } from 'vue';
import router from '@/router';
import filterActiveEventLocations from '@/utils/filterEventLocations';

const eventType = computed(() => store.getters['user/eventType']);
const originalCardsLength = computed(() => store.state.locations.locations.length);

const cards = computed(() => {
  const data = filterActiveEventLocations(store.state.locations.locations);
  return data;
});

const navigateTo = (path) => {
  const routeWithEvent = handleRouteWithEvent(eventType.value, path);
  router.push(routeWithEvent);
};

const timeFormat = (time) => {
  if (time) {
    const [hour, minutes] = time.split('T')[1].split(':');
    const date = new Date(0);
    date.setHours(hour);
    date.setMinutes(minutes);
    return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) || 'undefined';
  }
  return 'undefined';
};

const formatDate = (date) => {
  if (!date) return '';
  const dateObj = new Date(date);
  return `${dateObj.toLocaleString('en-US', { month: 'long', day: 'numeric' })}, ${dateObj.getFullYear()}`;
};

</script>
