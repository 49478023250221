<template>
  <div
    class="modal-backdrop"
    @click="handleClick"
    aria-hidden="true"
    v-show="props.isOpen">
    <section
      class="modal-container"
      :class="props.isOpen ? 'active' : ''"
      @click.stop="handleContainerClick"
      aria-hidden="true">
    <button
      class="absolute right-2 top-2 cursor-pointer px-4 py-4"
      @click="handleClick">
      <font-awesome-icon
              :icon="faXmark"
              color="#4391d1"
              size="2xl"
            />
      </button>
      <slot> </slot>
    </section>
  </div>
</template>

<style scoped lang="scss">
.modal-backdrop {
  @apply w-full
    h-full
    fixed
    bg-[#00000080]
    top-0
    left-0
    z-50
    flex
    items-center
    justify-center;
  .modal-container {
    @apply
      relative
      max-w-[80%]
      max-h-[90%]
      md:max-h-[80%]
      bg-[#FFF]
      p-12
      rounded-lg
      overflow-hidden
      overflow-y-scroll;
  }
}
</style>

<script setup>
import { defineEmits } from 'vue';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';

const emit = defineEmits(['handleClose']);
const props = defineProps({
  isOpen: { type: Boolean, required: true },
});

const handleClick = () => {
  emit('handleClose');
};

const handleContainerClick = (event) => {
  event.stopPropagation();
};

</script>
