<template>
<label :for="id"
    class="
      block
      font-MyriadProRegular
      text-sm
      lg:text-base
    ">
    <span
      v-if="label"
      class="input-label mb-2 block"
      >
      {{ label }}
    </span>
    <div id="select" class="relative w-full">
  <input class="
    block
    px-3
    py-1
    h-[52px]
    w-full
    outline-none
    rounded-[6px]
    bg-acura-gray-light
    border-2
    focus:border-black"
    :class="
        props.errorMessage ?
          'border-dynamic focus:border-dynamic'
          : 'border-acura-gray-medium'
        "
    :id="id"
    autocomplete="off"
    ref="selectRef"
    type="text"
    name="input"
    :placeholder="placeholder"
    @input="handleInput"
    @change="changeInput"
    @click="focusElement = true"
    @keydown.down.prevent="focusNextOption"
    @keydown.up.prevent="focusPreviousOption"
    @blur.prevent="handleBlur"
    v-model.trim="filterText" />
    <div class="absolute right-4 top-3" :class="focusElement && 'rotate-180'">
      <img :src="DropdownArrow" alt="arrow down for dropdown options" />
    </div>
    </div>
  <div id="list" @focus="focusElement = true" v-show="focusElement">
    <a
      class="dropdown-options"
      :key="'option-' + index"
      ref="options"
      @keypress.enter="selectOption(option)"
      @keydown.down.prevent="focusNextOption"
      @keydown.up.prevent="focusPreviousOption"
      @click.prevent="selectOption(option)"
      v-for="(option, index) in filteredOptions"
      tabindex="0">{{option.name}}</a>
  </div>
<small
      v-if="props.errorMessage"
      class="text-acura-primary">
      {{ props.errorMessage }}
    </small>
</label>
</template>

<script setup>
import {
  computed,
  ref,
  onMounted,
  // nextTick,
  watch,
} from 'vue';
import DropdownArrow from '@/assets/img/icons/dropdown-arrow.svg';

const props = defineProps({
  id: { type: String, required: true },
  options: { type: Array, required: true },
  label: { type: String, required: true },
  placeholder: { type: String, required: true },
  errorMessage: { type: String, required: false },
  modelValue: { required: true },
  disabled: { type: Boolean, required: false },
});

const emit = defineEmits(['update:modelValue']);

const selectRef = ref();
const filterText = ref('');
const options = ref([]);
const focusElement = ref(false);

const filteredOptions = computed(() => props
  .options.filter((option) => option
    .name
    .toLowerCase()
    .includes((filterText.value || '').trim().toLowerCase())));

const onTypeOpen = () => {
  if (!focusElement.value) focusElement.value = true;
};
const handleInput = (e) => {
  filterText.value = e.target.value;
  if (!filterText.value) emit('update:modelValue', '');
  onTypeOpen();
};

const focusNextOption = () => {
  if (document.activeElement === selectRef.value) {
    const optionsList = selectRef.value.nextElementSibling.querySelectorAll('a');
    if (optionsList.length > 0) {
      optionsList[0].focus();
    }
  } else {
    const nextOption = document.activeElement.nextElementSibling;
    if (nextOption) {
      nextOption.focus();
    }
  }
};

const focusPreviousOption = () => {
  if (document.activeElement === selectRef.value) return;
  const previousOption = document.activeElement.previousElementSibling;
  if (previousOption) {
    previousOption.focus();
  }
};

const handleBlur = () => {
  setTimeout(() => {
    if (!document.activeElement.classList.contains('dropdown-options')) {
      focusElement.value = false;
    }
  }, 100);
};

const selectOption = (option) => {
  emit('update:modelValue', option.value);
  filterText.value = option.name;
  focusElement.value = false;
};

const changeInput = (e) => {
  const { target: { value } } = e;
  const selected = props.options.find((option) => option.name === value);
  if (selected) {
    selectOption(selected);
  }
};

onMounted(() => {
  if (props.modelValue) {
    const valueFilter = props.options.filter(
      (option) => option.value === props.modelValue,
    )[0]?.name;
    filterText.value = valueFilter;
  }
});

watch(() => props.modelValue, (newValue) => {
  const valueFilter = !newValue ? '' : props.options.filter((option) => option.value === props.modelValue)[0].name;
  filterText.value = valueFilter;
});

</script>

<style lang="scss" scoped>
label {
@apply relative;
}
#select{
  @apply relative w-full;
  span {
    padding: 3px;
    cursor: pointer;
  }
}
input{
  @apply cursor-pointer;
  &:focus {
    @apply cursor-text;
  }
  &::after {
    @apply absolute;
    content: '#';
  }
}

#list{
  position: absolute;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  z-index: 2;
  &:c{
    display: none;
  }
  a{
    @apply
      block
      text-black
      bg-[#adadad]
      px-4
      py-2
      cursor-pointer;
    text-decoration: none;
    &:hover {
      @apply bg-[#8b8b8b];
    }
  }
}
</style>
