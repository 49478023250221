const sortArray = (array, { name, order }) => array.sort((a, b) => {
  const x = a[name] ?? '';
  const y = b[name] ?? '';
  const orderAsc = order === 'asc' ? 1 : -1;
  if (x < y) return -1 * orderAsc;
  if (x > y) return 1 * orderAsc;
  return 0;
});

export default sortArray;
