const validEventTypes = ['consumer', 'acuratraining', 'hondatraining', 'zdxdrive', 'prologuedrive'];

const colorMappings = {
  consumer: '#4391D1',
  acuratraining: '#E82C2A',
  hondatraining: '#1F61A9',
  zdxdrive: '#E82C2A',
  prologuedrive: '#1F61A9',
  default: '#4391D1',
};

const handleEventType = (x) => (validEventTypes.includes(x) ? x : 'consumer');

const handleRouteWithEvent = (eventType, path) => {
  const routeWithEvent = eventType === 'consumer' ? path : `/${eventType}${path}`;
  return routeWithEvent;
};

const setDynamicColor = (eventType) => {
  const color = colorMappings[eventType] || colorMappings.default;
  document.documentElement.style.setProperty('--dynamic-color', color);
};

export { handleEventType, handleRouteWithEvent, setDynamicColor };
