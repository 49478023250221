<template>
    <div class="fixed-label__container" :class="dirty && errorMessage ? 'error' : ''">
        <label class="font-bold" for="ms-cities">{{ label }}</label>
        <DatePicker
          label="Date input"
          id="for-date"
          v-model="date"
          class="h-[56px]"
          showIcon
          fluid
          variant="filled"
          iconDisplay="input"
        />
        <small v-if="dirty && errorMessage">
        {{ errorMessage }}
      </small>
      </div>
</template>

<script setup>
import DatePicker from 'primevue/datepicker';
import { computed } from 'vue';

const emit = defineEmits(['update:value']);
const props = defineProps({
  label: String,
  modelValue: Date,
});

const date = computed({
  get: () => props.modelValue,
  set(value) {
    emit('update:modelValue', value);
  },
});
</script>

<style scoped lang="scss">
.fixed-label__container {
  display: flex;
  flex-direction: column;
  label {
    font-size: 16px;
    margin-bottom: 0.25rem;
  }
  .p-datepicker {
    border: 1px solid #CDCDCD;
    border-radius: 0.5rem !important;
    color: #393939;
  }

  &.error {
    .p-select {
      border: 2px solid red;
    }
    small {
      color: red;
      font-size: 0.875rem;
      margin-top: 0.25rem;
    }
  }
}
</style>
