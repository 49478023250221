<script setup>
import { ref } from 'vue';
import apiManager from '@/api';
import LoginPage from '@/components/global/LoginPage.vue';
import { useMeta } from 'vue-meta';
import { useToast } from 'vue-toastification';
import WaiverReview from '@/components/eventWaiver/waiversReview/WaiverReview.vue';

const showCheckIn = ref(false);
const token = ref('');
const toast = useToast();
const loadingLogin = ref(false);
useMeta({
  robots: 'noindex',
});

const enableCheckIn = async (e) => {
  loadingLogin.value = true;
  try {
    const { data } = await apiManager.checkIn.postLoginCheckIn(e);
    if (data) {
      showCheckIn.value = true;
      token.value = data;
    } else {
      toast.error('Please provide a valid key');
    }
  } catch (err) {
    toast.error('Please provide a valid key');
  } finally {
    loadingLogin.value = false;
  }
};

useMeta({
  robots: 'noindex',
});
</script>
<template>
  <h2 class="text-center text-3xl mt-6">EVolution Experience | Waiver Review</h2>
  <template v-if="!showCheckIn">
    <login-page
      title="Waiver Review"
      legend="Login to the Waiver Review Page"
      :loading="loadingLogin"
      @enableCheckIn="enableCheckIn($event)"
    />
  </template>
  <template v-else>
    <WaiverReview :token="token" />
  </template>
</template>
