<script setup>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import apiManager from '@/api';
import RegistrationPage from '@/components/registration/RegistrationPage.vue';
import { useMeta } from 'vue-meta';
import { useRoute } from 'vue-router';

const props = defineProps({
  action: String,
  id: Number,
  location: String,
});

const store = useStore();
const location = computed(() => store.getters['locations/location'](props.location));
const eventType = computed(() => store.getters['user/eventType']);

const route = useRoute();

useMeta({
  meta: [
    { name: 'description', content: route.meta.description },
    { 'http-equiv': 'Content-Type', content: 'text/html;charset=utf-8' },
  ],
});

const session = ref({});

const getSession = async ({
  locationId,
}) => {
  const { data } = await apiManager.reservations.getSessions({
    eventType: eventType.value, locationId,
  });
  return data;
};

const loadSession = async ({ id }) => {
  const data = await getSession({ eventType, locationId: id });
  session.value = data.find((item) => item.sessionId === props.id);
};

onMounted(() => {
  loadSession(location.value);
});
</script>

<template>
  <registration-page
    :action="action"
    :location="location"
    :session="session"
  />
</template>
