<template>
  <div>
    <h2
      class="
      font-MyriadProLight
      text-[2.875rem]
      after:content-['']
      after:bg-dynamic
      after:block
      after:h-1.5
      after:w-[160px]
      after:mt-4
      after:mb-8
      "
    >
      Need Help?
    </h2>
    <p
      class="
      font-MyriadProRegular
      tracking-[0.036em]
      text-lg
      lg:text-2xl
      pr-0
      lg:pr-10
      mb-12
      max-w-xl
      "
    >
      Check out our <b><u>FAQs</u></b> for answers to common questions you may have.
      If you can’t find the answer you’re looking for, please contact us.
    </p>
    <a
      href="mailto:info@evolution-experience.com?subject=Evolution Experience Program Inquiry"
      title="Click here to email us"
      class="
      bg-dynamic
      text-white
      uppercase
      flex
      h-[3.625rem]
      lg:max-w-[184px]
      items-center
      justify-center
      font-AvenirHeavy
      font-bold
      tracking-[0.036em]
      text-xl
      rounded-[10px]
      "
      @click="trackContact('PrivacyPolicy')"
      >contact us</a
    >
  </div>
</template>

<script setup>
import { trackCustomEvent } from '@/utils/analytics';
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const eventType = computed(() => store.getters['user/eventType']);

const props = defineProps({
  page: String,
  location: String,
});

const trackContact = () => {
  if (props.page === 'registration') {
    trackCustomEvent('ContactUs', eventType.value, props.page, { location: props.location });
  } else {
    trackCustomEvent('ContactUs', eventType.value, props.page);
  }
};
</script>
