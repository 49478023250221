import { baseCRUDAPI } from './base';

export default class Print extends baseCRUDAPI {
  constructor(axiosObject) {
    super(axiosObject, 'Print');
  }

  async postPrintLabel(data) {
    return this.axiosObject.post(
      `${this.apiBaseName}/AddLabel`,
      data,
      {
        headers: {
          'Content-type': 'application/json',
        },
      },
    );
  }

  async postPrintLabelFile(file, authToken) {
    const data = new FormData();
    data.append('file', file);
    return this.axiosObject.post(
      `${this.apiBaseName}/File`,
      data,
      {
        headers: {
          authToken,
          'Content-type': 'multipart/form-data',
        },
      },
    );
  }

  async clearQueue() {
    return this.axiosObject.delete(`${this.apiBaseName}/ClearQueue`);
  }
}
