import { baseCRUDAPI } from './base';

export default class Home extends baseCRUDAPI {
  constructor(axiosObject) {
    super(axiosObject, 'Home');
  }

  async experienceByEventType(eventType) {
    return this.axiosObject.get(
      `${this.apiBaseName}/experience`,
      {
        params: {
          eventType,
        },
      },
    );
  }

  async faqs(eventType) {
    return this.axiosObject.get(
      `${this.apiBaseName}/faqs`,
      {
        params: {
          eventType,
        },
      },
    );
  }
}
