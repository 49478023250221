const checkedFlag = require('@/assets/img/icons/checked-flag.svg');
const question = require('@/assets/img/icons/question-chat.svg');
const recharge = require('@/assets/img/icons/recharge-electric.svg');
const speedometer = require('@/assets/img/icons/speedometer.svg');

const icons = {
  checkedFlagIcon: {
    src: checkedFlag,
    alt: 'An outline of two finish flags crossing to form an X',
  },
  questionIcon: {
    src: question,
    alt: 'Two chat bubbles, one of it with the question mark',
  },
  rechargeIcon: {
    src: recharge,
    alt: 'A cable forming an outline clock with a plug in the middle',
  },
  speedometerIcon: {
    src: speedometer,
    alt: 'A speedometer representing performance',
  },
};

const consumer = [
  {
    ...icons.rechargeIcon,
    text: 'Demystify the EV experience by getting all your questions answered by our product specialists',
  },
  {
    ...icons.speedometerIcon,
    text: 'Master EV driving skills under the instruction of professional driving coaches',
  },
  {
    ...icons.checkedFlagIcon,
    text: 'See how fun-to-drive the Prologue and ZDX are on two closed courses and a focused street drive',
  },
];

const acuratraining = [
  {
    ...icons.questionIcon,
    text: 'Demystify the EV experience by getting all your questions answered by our product specialists',
  },
  {
    ...icons.checkedFlagIcon,
    text: 'Master EV driving skills under the instruction of professional driving coaches',
  },
  {
    ...icons.rechargeIcon,
    text: 'Take a deep dive into the EV ownership experience with plenty of hands on learning',
  },
];

const hondatraining = [
  {
    ...icons.questionIcon,
    text: 'Demystify the EV experience by getting all your questions answered by our product specialists',
  },
  {
    ...icons.checkedFlagIcon,
    text: 'Master EV driving skills under the instruction of professional driving coaches',
  },
  {
    ...icons.rechargeIcon,
    text: 'Take a deep dive into the EV ownership experience with plenty of hands on learning',
  },
];

export default {
  consumer,
  acuratraining,
  hondatraining,
};
