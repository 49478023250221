<template>
  <h1 class="attendee-edit-title">Edit Attendee</h1>

  <form @submit.prevent="submitForm">
    <section class="grid grid-cols-2 gap-4 mb-8" v-if="props.eventType !== '0'">
      <template v-if="props.eventType === '1' || props.eventType === '2'">
        <TheTextfield
          name="dptsid"
          label="DPTS ID"
          placeholder="DPTS ID"
          v-model:value="state.dptsid"
        />
      </template>
      <template v-if="props.eventType === '3' || props.eventType === '4'">
        <TheTextfield
          name="dealernumber"
          label="Dealer Number"
          placeholder="Dealer Number"
          v-model:value="state.dealerNumber"
        />
      </template>
    </section>
    <section class="grid grid-cols-2 gap-4">
      <TheTextfield
        name="firstname"
        label="First Name"
        placeholder="First Name"
        :dirty="v$.firstName.$dirty"
        :errorMessage="v$.firstName.$errors[0]?.$message"
        v-model:value="state.firstName"
      />
      <TheTextfield
        name="lastname"
        label="Last Name"
        placeholder="Last Name"
        :dirty="v$.lastName.$dirty"
        :errorMessage="v$.lastName.$errors[0]?.$message"
        v-model:value="state.lastName"
      />
      <TheTextfield
        name="email"
        label="Email"
        placeholder="Email"
        :dirty="v$.emailAddress.$dirty"
        :errorMessage="v$.emailAddress.$errors[0]?.$message"
        v-model:value="state.emailAddress"
      />
      <TheTextfield
        name="mobile"
        label="Mobile"
        placeholder="Mobile"
        mask="(000) 000-0000"
        maxLength="14"
        :dirty="v$.phoneNumber.$dirty"
        :errorMessage="v$.phoneNumber.$errors[0]?.$message"
        v-model:value="state.phoneNumber"
      />
      <TheTextfield
        name="zip"
        label="ZIP Code"
        placeholder="ZIP Code"
        :dirty="v$.zipCode.$dirty"
        :errorMessage="v$.zipCode.$errors[0]?.$message"
        v-model:value="state.zipCode"
      />
    </section>

    <section class="grid grid-cols-2 gap-4 mt-4" v-if="props.eventType === '0'">
      <p class="text-lg col-span-2">Guest Information</p>
      <TheTextfield
        name="guestFirstName"
        label="Guest First Name"
        placeholder="Guest First Name"
        :dirty="v$.guestFirstName.$dirty"
        :errorMessage="v$.guestFirstName.$errors[0]?.$message"
        v-model:value="state.guestFirstName"
      />
      <TheTextfield
        name="guestLastName"
        label="Guest Last Name"
        placeholder="Guest Last Name"
        :dirty="v$.guestLastName.$dirty"
        :errorMessage="v$.guestLastName.$errors[0]?.$message"
        v-model:value="state.guestLastName"
      />
    </section>

    <TheCheckbox
      class="mt-6 mb-6"
      name="sign"
      label="I would like to receive the confirmation and reminders"
      :value="state.communicationOptIn"
      @input="state.communicationOptIn = $event"
    />
  </form>
  <section class="w-full flex justify-center gap-6">
    <TheButton
      :loading="isLoading"
      @click="hasChanges(row)"
      label="Update Information"
      class="px-8"
    />
    <TheButton
      :loading="isLoading"
      @click="openCancelToast"
      label="Cancel Reservation"
      class="px-8"
    />
  </section>
</template>
<script setup>
import {
  computed,
  defineProps,
  ref,
  onBeforeMount,
  onBeforeUnmount,
} from 'vue';
import apiManager from '@/api';
import TheTextfield from '@/components/registration/TheTextfield.vue';
import TheButton from '@/components/global/TheButton.vue';
import TheCheckbox from '@/components/registration/TheCheckbox.vue';
import ConfirmationToast from '@/components/global/ConfirmationToast.vue';
import useValidate from '@vuelidate/core';
import { useToast } from 'vue-toastification';
import {
  email,
  helpers,
  required,
  requiredIf,
} from '@vuelidate/validators';
import { unmaskPhoneNumber } from '@/utils/format';

const props = defineProps({
  token: { type: String, required: true },
  initialState: { type: Object, required: true },
  eventType: { type: String },
});

const emit = defineEmits(['closeModal']);

const state = ref({ ...props.initialState });

const requiredValidator = helpers.withMessage('Field is required', required);
const emailValidator = helpers.withMessage('Please provide a valid email', email);
const regexPhone = helpers.regex(/^(1[- .]?)?(\([2-9]\d{2}\)[. -]?|[2-9]\d{2}[. -]?)[2-9]\d{2}[- .]?\d{4}$/);
const phoneValidator = helpers.withMessage('Please provide a valid phone number', regexPhone);
const regexZipCode = helpers.regex(/^\d{5}(?:[-]\d{4})?$/);
const zipCodeValidator = helpers.withMessage('Please provide a valid zip code', regexZipCode);

const rules = computed(() => (
  {
    firstName: { requiredValidator },
    lastName: { requiredValidator },
    emailAddress: {
      requiredValidator,
      emailValidator,
    },
    phoneNumber: {
      requiredValidator,
      phoneValidator,
    },
    zipCode: {
      requiredValidator,
      zipCodeValidator,
    },
    guestFirstName: {
      requiredGFN: helpers.withMessage('Field is required', requiredIf(state.value.guestLastName)),
    },
    guestLastName: {
      requiredGLN: helpers.withMessage('Field is required', requiredIf(state.value.guestFirstName)),
    },
  }
));
const v$ = useValidate(rules, state.value, { $autoDirty: true });

const toast = useToast();
const isLoading = ref(false);

const saveChanges = async (changesToSave) => {
  const {
    childCount,
    checkedIn,
    attendeeType,
    carNumber,
    guest,
    reservationId,
    startTime,
    uic,
    waiver,
    walkIn,
    ...body
  } = changesToSave;
  body.phoneNumber = unmaskPhoneNumber(body.phoneNumber);
  isLoading.value = true;
  try {
    await apiManager.reservations.updateReservation({
      authToken: props.token,
      id: reservationId,
      body,
    });
    emit('closeModal', 'updated');
  } catch (error) {
    toast.error(`An error ocurred when saving your data (${error.response.data.message})`);
  } finally {
    isLoading.value = false;
  }
};

const cancelReservation = async () => {
  isLoading.value = true;
  try {
    await apiManager.reservations.cancel({ id: state.value.reservationId });
    emit('closeModal', 'cancelled');
  } catch (error) {
    toast.error(`An error ocurred when cancelling your reservation (${error.response.data.message})`);
  } finally {
    isLoading.value = false;
  }
};

const openCancelToast = () => {
  toast.error(
    {
      component: ConfirmationToast,
      props: {
        text: 'Are you sure you want to cancel the reservation?',
      },
      listeners: {
        click: () => {
          cancelReservation();
        },
      },
    },
    {
      timeout: false,
      pauseOnFocusLoss: false,
      pauseOnHover: true,
      icon: false,
      closeButton: false,
    },
  );
};
const hasChanges = () => {
  const changed = Object
    .keys(state.value)
    .some((stateKey) => state.value[stateKey] !== props.initialState[stateKey]);
  if (!changed) return;
  v$.value.$validate();
  if (!v$.value.$error) {
    saveChanges(state.value);
  }
};

onBeforeMount(() => {
  document.body.style.overflow = 'hidden';
});
onBeforeUnmount(() => {
  document.body.style.overflow = 'auto';
});

</script>

<style>
.attendee-edit-title {
  @apply text-6xl font-MyriadProRegular text-center text-dynamic mb-6;
}
@media (height <= 550px) {
  .modal-container {
    @apply !p-4;
  }
  .attendee-edit-title {
    @apply text-4xl;
  }
}

</style>
