import paramsSerializer from '@/utils/paramsSerializer';
import { baseCRUDAPI } from './base';

export default class Waiver extends baseCRUDAPI {
  constructor(axiosObject) {
    super(axiosObject, 'Waiver');
  }

  async getWaivers(authToken, filters) {
    const queryString = paramsSerializer(filters);
    return this.axiosObject.get(`${this.apiBaseName}?${queryString}`, {
      headers: {
        authToken,
      },
    });
  }

  async postWaiver(waiver) {
    return this.axiosObject.post(
      `${this.apiBaseName}`,
      waiver,
    );
  }

  async getWaiverCSV(authToken, filters) {
    const queryString = paramsSerializer(filters);
    return this.axiosObject.get(`${this.apiBaseName}/csv?${queryString}`, {
      headers: {
        authToken,
      },
    });
  }

  async getWaiverLocations() {
    return this.axiosObject.get(`${this.apiBaseName}/Locations`);
  }
}
