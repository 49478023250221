<script setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import { useRoute } from 'vue-router';

const route = useRoute();
</script>
<template>
  <metainfo />

  <template v-if="route.meta.layout">
    <component :is="route.meta.layout" />
  </template>
  <template v-else>
    <DefaultLayout />
  </template>

</template>
