import { createStore } from 'vuex';
import user from './modules/user';
import locations from './modules/locations';

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    locations,
  },
});
