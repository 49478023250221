<template>
      <section class="px-6" id="pdf-container">
      <header class="flex flex-col items-center justify-center mt-6">
          <img :src="evolutionLogo" alt="Evolution logo"/>
          <h1> Event Waiver </h1>
      </header>
      <p>Date: {{ todayUSFormatted }}</p>

      <p class="my-4"><b>Fields with an asterisk (*) are required</b></p>

      <form @submit.prevent="submitForm">

          <div
          class="col-span-2 md:w-1/2 md:pr-4">
            <new-select
              v-if="availableLocations"
              name="event"
              fixedLabel
              autocomplete="off"
              placeholder="Select Location"
              label="Event Location*"
              id="event-select"
              :options="availableLocations"
              :dirty="v$.locationId.$dirty"
              :errorMessage="v$.locationId.$errors[0]?.$message"
              v-model="state.locationId"
              @changed="selectChanged(value)"
            />
          </div>

          <the-textfield
            name="first-name"
            label="First Name*"
            placeholder="First Name"
            autocomplete="off"
            :dirty="v$.firstName.$dirty"
            :errorMessage="v$.firstName.$errors[0]?.$message"
            v-model:value="state.firstName"
          />
          <the-textfield
            name="last-name"
            label="Last Name*"
            autocomplete="off"
            placeholder="Last Name"
            :dirty="v$.lastName.$dirty"
            :errorMessage="v$.lastName.$errors[0]?.$message"
            v-model:value="state.lastName"
          />
          <the-textfield
            type="email"
            name="email"
            autocomplete="off"
            label="Email*"
            placeholder="Enter the email address you registered with"
            :dirty="v$.email.$dirty"
            :errorMessage="v$.email.$errors[0]?.$message"
            v-model:value="state.email"
          />
          <the-textfield
            name="phone-number"
            label="Phone*"
            placeholder="Enter the phone number you registered with"
            autocomplete="off"
            mask="(000) 000-0000"
            :maxLength="14"
            :dirty="v$.phoneNumber.$dirty"
            :errorMessage="v$.phoneNumber.$errors[0]?.$message"
            v-model:value="state.phoneNumber"
          />

          <the-textfield
            name="street-address"
            :id="!initialState ? 'streetAddress' : 'streetAddressNoSearch'"
            autocomplete="off"
            label="Street Address*"
            placeholder="Enter your street address"
            :dirty="v$.streetAddress.$dirty"
            :errorMessage="v$.streetAddress.$errors[0]?.$message"
            v-model:value="state.streetAddress"
          />

          <the-textfield
            name="city"
            autocomplete="off"
            label="City*"
            placeholder="Enter your city"
            :dirty="v$.city.$dirty"
            :errorMessage="v$.city.$errors[0]?.$message"
            v-model:value="state.city"
          />
          <div>
            <new-select
                name="state"
                fixedLabel
                filter
                autocomplete="off"
                placeholder="Select your state"
                label="State*"
                id="state-select"
                :options="STATE_OPTIONS"
                :dirty="v$.state.$dirty"
                :errorMessage="v$.state.$errors[0]?.$message"
                v-model="state.state"
              />
          </div>
          <the-textfield
            name="zip-code"
            autocomplete="off"
            label="Zip Code*"
            placeholder="00000"
            tooltip="Please provide a five or nine digit zip code"
            mask="00000[-0000]"
            :maxLength="10"
            :dirty="v$.zipCode.$dirty"
            :errorMessage="v$.zipCode.$errors[0]?.$message"
            v-model:value="state.zipCode"
          />

          <section class="license-details-container html2pdf__page-break">
              <span
                class="absolute -mt-[2.8rem] ml-2 md:-mt-[.8rem] md:ml-4 bg-white px-2">
                License Details
              </span>
              <the-textfield
              name="licenseNumber"
              autocomplete="off"
              label="Valid Driver's License*"
              placeholder="Enter your driver's license #"
              :dirty="v$.licenseNumber.$dirty"
              :errorMessage="v$.licenseNumber.$errors[0]?.$message"
              v-model:value="state.licenseNumber"
              />

              <div>
                <new-select
                    name="licenseState"
                    fixedLabel
                    filter
                    autocomplete="off"
                    placeholder="Select state issued"
                    label="State Issued*"
                    id="issued-state-select"
                    :options="STATE_OPTIONS"
                    :dirty="v$.licenseState.$dirty"
                    :errorMessage="v$.licenseState.$errors[0]?.$message"
                    v-model="state.licenseState"
                  />
              </div>

              <the-textfield
              type="date"
              name="licenseExpirationDate"
              label="Expiration Date*"
              placeholder="Expiration Date*"
              :dirty="v$.licenseExpirationDate.$dirty"
              :errorMessage="v$.licenseExpirationDate.$errors[0]?.$message"
              v-model:value="state.licenseExpirationDate"
              :min="todayDate"
              />
              <the-textfield
              type="date"
              name="dateOfBirth"
              label="Date of Birth*"
              placeholder="Date of Birth*"
              :dirty="v$.licenseOwnerDateofBirth.$dirty"
              :errorMessage="v$.licenseOwnerDateofBirth.$errors[0]?.$message"
              v-model:value="state.licenseOwnerDateofBirth"
              :max="yesterdayDate"
              />
          </section>

          <fieldset class="text-lg col-span-2">
          <legend class="font-MyriadProRegular mb-2">
            Honda Waiver:
          </legend>
          <div class="text-acura-gray-darker">
          <WaiverContainer :showFullWaiver="!!props.initialState" ref="hondaWaiverComponent">
            <HondaWaiver />
          </WaiverContainer>
          <the-textfield
              class="mb-6"
              autocomplete="off"
              name="drivingRecordsExceptions"
              label="You warrant that your motor vehicle driving record contains NO accidents or
              violations with the exception of the following:"
              placeholder="Enter any accidents or violations here"
              v-model:value="state.drivingRecordsExceptions"
              />
          <the-checkbox
            name="checkHondaWaiver"
            label="Check if you agree to the waiver rules, this counts as an electronic signature.*"
            v-model:value="state.checkAgreeHondaWaiverRules"
            :errorMessage="v$.checkAgreeHondaWaiverRules.$errors[0]?.$message"
            ></the-checkbox>
          </div>
        </fieldset>

        <fieldset class="mt-4 text-lg  col-span-2">
          <legend class="font-MyriadProRegular mb-2">
            Event Waiver:
          </legend>
          <div class="text-acura-gray-darker">
          <WaiverContainer :showFullWaiver="!!props.initialState" ref="eventWaiverComponent">
            <EventWaiver />
          </WaiverContainer>
          <the-checkbox
            name="checkEventWaiver"
            label="Check if you agree to the waiver rules, this counts as an electronic signature.*"
            v-model:value="state.checkAgreeEventWaiverRules"
            :errorMessage="v$.checkAgreeEventWaiverRules.$errors[0]?.$message"
          />
          </div>
        </fieldset>

        <p class="col-span-2" :class="initialState ? 'mb-4' : ''">
          If any minors are accompanying you today, please provide their names.
        </p>

        <template  v-for="(minor, index) in state.minors" :key="'minor-' + index">
          <the-textfield
              name="minorFirstName"
              autocomplete="off"
              label="Minor First Name"
              placeholder="Enter the child's first name"
              :initialFocus="index === state.minors.length - 1 && index > 0"
              :errorMessage="
                  (submissionAttempt && !minor.firstName && minor.lastName)
                  ? 'Field is required'
                  : ''
              "
              v-model:value="minor.firstName"
          />

          <the-textfield
              name="minorLastName"
              autocomplete="off"
              label="Minor Last Name"
              placeholder="Enter the child's last name"
              :errorMessage="
                  (submissionAttempt && !minor.lastName && minor.firstName)
                  ? 'Field is required'
                  : ''
              "
              v-model:value="minor.lastName"
          />
      </template>
        <div class="col-span-2">
        <button class="
          uppercase text-white py-[0.5rem]
            px-[1.75rem] w-auto my-auto
            text-[1.175rem]
            font-MyriadProLight
            tracking-[2px]
            font-bold
            bg-[#4391D1] rounded-[.5rem]
            disabled:bg-acura-gray-dark
            disabled:cursor-not-allowed
        "
        @click.prevent="addMinor()"
        :disabled="savingInProgress"
        v-show="!props.initialState">
          Add Another Minor +
        </button>
        </div>

      <div
        class="col-span-2 flex flex-col md:flex-row justify-end gap-4 mb-4"
        v-show="!props.initialState">
          <vue-recaptcha
          :sitekey="captchaSiteKey"
          @verify="validateRecaptcha"
          @expire="recaptchaRef.reset()"
          ref="recaptchaRef"/>
        <button
          @click.prevent="submitForm()"
          class="
            uppercase text-white py-[0.925rem]
            px-[3.75rem] lg:w-auto lg:ml-6 my-auto
            text-[1.175rem]
            font-MyriadProLight
            tracking-[2px]
            font-bold
            bg-[#4391D1] rounded-[.5rem]
            disabled:bg-acura-gray-dark
            disabled:cursor-not-allowed
          "
          :disabled="savingInProgress"
          >
          <template v-if="!savingInProgress">Submit</template>
          <template v-else>
            <font-awesome-icon
              :icon="faSpinnerThird"
              class="mx-[5rem] animate-spin"
              />
        </template>
        </button>
      </div>
    </form>
  </section>
</template>

<script setup>
/* eslint-disable */
import evolutionLogo from '@/assets/img/evolution-logo.svg';
import {
  helpers,
  required,
  email,
  sameAs,
} from '@vuelidate/validators';
import useValidate from '@vuelidate/core';
import {
  reactive,
  computed,
  ref,
  onMounted,
} from 'vue';
import apiManager from '@/api';
import WaiverContainer from '@/components/eventWaiver/WaiverContainer.vue';
import EventWaiver from '@/components/eventWaiver/waivers/EventWaiver.vue';
import HondaWaiver from '@/components/eventWaiver/waivers/HondaWaiver.vue';
import vueRecaptcha from 'vue3-recaptcha2';
import TheTextfield from '@/components/registration/TheTextfield.vue';
import TheCheckbox from '@/components/registration/TheCheckbox.vue';
import NewSelect from '@/components/global/NewSelect.vue';
// import TheSelect from '@/components/global/TheSelect.vue';
import STATE_OPTIONS from '@/constants/states';
import store from '@/store';
import { setDynamicColor } from '@/utils/eventType';
import { Loader } from '@googlemaps/js-api-loader';
import { useToast } from 'vue-toastification';
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';
import { unmaskPhoneNumber } from '@/utils/format';
import filterActiveEventLocations from '@/utils/filterEventLocations';

const props = defineProps({
  initialState: { type: Object, required: false },
});

const state = ref(props.initialState ? props.initialState : {
  locationId: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  streetAddress: '',
  city: '',
  state: '',
  zipCode: '',
  licenseNumber: '',
  licenseState: '',
  licenseExpirationDate: '',
  licenseOwnerDateofBirth: '',
  checkAgreeHondaWaiverRules: false,
  drivingRecordsExceptions: '',
  checkAgreeEventWaiverRules: false,
  minors: [
    { firstName: '', lastName: '' },
  ],
  captchaVerified: false,
});

const todayDate = ref(new Date().toISOString().split('T')[0]);
const yesterdayDate = computed(() => {
  const today = new Date();
  const yesterday = new Date(today.setDate(today.getDate() - 1));
  return yesterday.toISOString().split('T')[0];
});

const availableLocations = computed(() => {
  const avLocations = filterActiveEventLocations(store.state.locations.waiverLocations);
  const finalLocations = [{ name: 'Las Vegas Storytelling Competition', value: 998, activeEvent: true }, ...avLocations];
  return finalLocations;
});


const todayUSFormatted = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
}).format(new Date());

const pastDateValidator = helpers.withMessage('Expiration Date cannot be in the past', (value) => {
  const inputDate = new Date(value)
  inputDate.setHours(0, 0, 0, 0);
  inputDate.setDate(inputDate.getDate() + 1);
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  return inputDate >= currentDate;
});
const futureDateValidator = helpers.withMessage('Date of Birth should be in the past', (value) => {
  const inputDate = new Date(value);
  const currentDate = new Date();
  return inputDate < currentDate;
});
const requiredValidator = helpers.withMessage('Field is required', required);
const emailValidator = helpers.withMessage('Please provide a valid email', email);
const regexPhone = helpers.regex(/^(1[- .]?)?(\([2-9]\d{2}\)[. -]?|[2-9]\d{2}[. -]?)[2-9]\d{2}[- .]?\d{4}$/);
const phoneValidator = helpers.withMessage('Please provide a valid phone number', regexPhone);
const regexZipCode = helpers.regex(/^\d{5}(?:[-]\d{4})?$/);
const zipCodeValidator = helpers.withMessage('Please provide a valid zip code', regexZipCode);
const submissionAttempt = ref(false);

const captchaSiteKey = computed(() => process.env.VUE_APP_RECAPTCHA_SITE_KEY);

const emit = defineEmits(['sentForm']);

const rules = computed(() => (
  {
    locationId: { requiredValidator },
    firstName: { requiredValidator },
    lastName: { requiredValidator },
    email: {
      requiredValidator,
      emailValidator,
    },
    phoneNumber: {
      requiredValidator,
      phoneValidator,
    },
    streetAddress: { requiredValidator },
    city: { requiredValidator },
    state: { requiredValidator },
    zipCode: {
      requiredValidator,
      zipCodeValidator,
    },
    licenseNumber: { requiredValidator },
    licenseState: { requiredValidator },
    licenseExpirationDate: { requiredValidator, pastDateValidator },
    licenseOwnerDateofBirth: { requiredValidator, futureDateValidator },
    checkAgreeHondaWaiverRules: {
      sameAs: helpers.withMessage('Please agree to the waiver.', sameAs(true)),
    },
    checkAgreeEventWaiverRules: {
      sameAs: helpers.withMessage('Please agree to the waiver.', sameAs(true)),
    },
    captchaVerified: {
      sameAs: helpers.withMessage('Captcha is required', sameAs(true)),
    },
  }
));

const toast = useToast();
const v$ = useValidate(rules, state.value, { $autoDirty: true });

const recaptchaRef = ref();
const eventWaiverComponent = ref();
const hondaWaiverComponent = ref();
const drivingRecordsExceptions = reactive('');

const validateRecaptcha = () => {
  state.value.captchaVerified = true;
};

function addMinor() {
  const minor = { firstName: '', lastName: '' };
  state.value.minors.push(minor);
}

function validateMinors() {
  let isValid = true;
  state.value.minors.forEach((minor) => {
    if (minor.firstName && !minor.lastName) isValid = false;
    if (minor.lastName && !minor.firstName) isValid = false;
  });
  state.value.minors = state.value.minors.filter((minor) => minor.firstName || minor.lastName);
  return isValid;
}

function resetFormValues() {
  state.value.firstName = '';
  state.value.lastName = '';
  state.value.email = '';
  state.value.phoneNumber = '';
  state.value.streetAddress = '';
  state.value.city = '';
  state.value.state = '';
  state.value.zipCode = '';
  state.value.licenseNumber = '';
  state.value.licenseState = '';
  state.value.licenseExpirationDate = '';
  state.value.licenseOwnerDateofBirth = '';
  state.value.checkAgreeHondaWaiverRules = false;
  state.value.drivingRecordsExceptions = '';
  state.value.checkAgreeEventWaiverRules = false;
  state.value.minors = [];
  state.value.minors.push({ firstName: '', lastName: '' });
  state.value.captchaVerified = false;
}

function resetFormDirtyState() {
  const fields = [
    'firstName',
    'lastName',
    'email',
    'phoneNumber',
    'streetAddress',
    'city',
    'state',
    'zipCode',
    'licenseNumber',
    'licenseState',
    'licenseExpirationDate',
    'licenseOwnerDateofBirth',
    'checkAgreeHondaWaiverRules',
    'drivingRecordsExceptions',
    'checkAgreeEventWaiverRules',
    'captchaVerified',
  ];

  fields.forEach((field) => {
    if(v$.value[field]) {
      v$.value[field].$dirty = false;
    }
  });
}

function resetForm() {
  setTimeout(() => {
    resetFormValues();
    resetFormDirtyState();
    recaptchaRef.value.reset();
  });
}

const showFullWaivers = ref(false);
const savingInProgress = ref(false);

function submitForm() {
  eventWaiverComponent.value.resetToTop();
  hondaWaiverComponent.value.resetToTop();
  v$.value.$validate();
  const validMinors = validateMinors();
  submissionAttempt.value = true;
  if (!v$.value.$error && validMinors) {
    showFullWaivers.value = true;
    savingInProgress.value = true;
    if (!state.value.minors[state.value.minors.length - 1]?.firstName) {
      state.value.minors.pop();
    }
    state.value.phoneNumber = unmaskPhoneNumber(state.value.phoneNumber);
    if (state.value.locationId === 998) {
      state.value.locationName = 'Las Vegas Storytelling Competition';
    }
    if (state.value.locationId === 999) {
      state.value.locationName = 'Long Beach Grand Prix';
    }
    if(!state.value.drivingRecordsExceptions) delete state.value.drivingRecordsExceptions;
    emit('sentForm', state.value);
  } else {
    const message = v$.value.$errors.some((error) => error.$params.type === 'required') || !validMinors ? 'Please provide values for all required fields.' : v$.value.$errors[0].$message;
    toast.error(message);
  }
}

const pdfHasDownloaded = async (base64) => {
  const body = { 
    ...state.value, 
    locationId: (state.value.locationId === 999 || state.value.locationId === 998) ? null : state.value.locationId,
    pdfData: base64
   };
  try {
    await apiManager.waiver.postWaiver(body);
    toast.success(`The waiver for ${state.value.firstName} ${state.value.lastName} has been completed. Please show this message along with your license to the check-in team so they can finish checking you in.`, { timeout: false });
    window.scrollTo({ top: 0, behavior: 'smooth' });
    resetForm();
  } catch (err) {
    toast.error(`${err.message}`);
  } finally {
    savingInProgress.value = false;
  }
};

defineExpose({ pdfHasDownloaded });

const autocompleteService = ref();

const onPlaceChanged = () => {
  const { formatted_address: formattedAddress } = autocompleteService.value.getPlace();

  if (!formattedAddress) return;

  const [name, cityName, stateAddress] = formattedAddress.split(',');
  const [stateInitials, zipCodeValue] = stateAddress.trim().split(' ');

  state.value.city = cityName.trim();
  state.value.zipCode = zipCodeValue || '';
  state.value.state = stateInitials || '';
  state.value.streetAddress = name;
};

const activateGoogleAddress = async () => {
  const loader = new Loader({
    apiKey: process.env.VUE_APP_AUTOCOMPLETE_API_KEY,
    version: 'weekly',
    libraries: ['places'],
  });

  const { Autocomplete } = await loader.importLibrary('places');

  autocompleteService.value = new Autocomplete(document.querySelector('#streetAddress'), {
    componentRestrictions: { country: ['US'] },
    types: ['address'],
    fields: ['formatted_address'],
  });

  autocompleteService.value.addListener('place_changed', onPlaceChanged);
};

const fetchLocation = async () => {
  try {
    store.dispatch('locations/loadWaiverLocations');
  } catch (error) {
    if (error.response.data.message.toLowerCase().includes('no events today')) {
      toast.error('No Event found for today');
    }
  }
};

onMounted(() => {
  setDynamicColor('acuratraining');
  fetchLocation();
  setTimeout(() => window.scrollTo(0,0));
  if (!props.initialState) {
    activateGoogleAddress();
  }
});

</script>

<style scoped>
form {
    @apply
    flex flex-col
    md:grid
    md:grid-cols-2
    gap-x-8
    gap-y-4;
}

.license-details-container {
    @apply
    col-span-2
    flex
    flex-col
    md:grid
    md:grid-cols-2
    relative
    gap-x-8
    gap-y-4
    mt-8
    px-4
    py-8
    border;
}

.vue-html2pdf section {
  background: #FFF;
}
</style>
