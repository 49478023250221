<script setup>
import { ref } from 'vue';
import apiManager from '@/api';
import { useMeta } from 'vue-meta';
import { useToast } from 'vue-toastification';
import LoginPage from '@/components/global/LoginPage.vue';
import BadgePrinting from '@/components/eventBadgePrinting/BadgePrinting.vue';

const showBadgePrintingPage = ref(false);
const token = ref('');
const toast = useToast();
const loadingLogin = ref(false);
useMeta({
  robots: 'noindex',
});

const enableCheckIn = async (e) => {
  loadingLogin.value = true;
  try {
    const { data } = await apiManager.checkIn.postLoginCheckIn(e);
    if (data) {
      showBadgePrintingPage.value = true;
      token.value = data;
    } else {
      toast.error('Please provide a valid key');
    }
  } catch (err) {
    toast.error('Please provide a valid key');
  } finally {
    loadingLogin.value = false;
  }
};

useMeta({
  robots: 'noindex',
});
</script>
<template>
  <div class="p-5 lg:p-14 mb-10">
    <login-page
      title="Badge Printing Login"
      legend="Login to the badge printing page"
      :loading="loadingLogin"
      @enableCheckIn="enableCheckIn($event)"
      v-if="!showBadgePrintingPage"
    />
    <badge-printing v-if="showBadgePrintingPage" :token="token" />
  </div>
</template>
