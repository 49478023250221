<script setup>
import { computed } from 'vue';
import store from '@/store';
import { setDynamicColor } from '@/utils/eventType';
import HeroHeader from '@/components/global/HeroHeader.vue';
import FaqAccordion from '@/components/faqAccordion/FaqAccordion.vue';
import HelpAside from '@/components/global/HelpAside.vue';

const eventType = computed(() => store.getters['user/eventType']);
setDynamicColor(eventType.value);

defineProps({
  faqs: Array,
});
</script>

<template>
  <div
    class="
      pb-24
      lg:pb-[17.5rem] lg:bg-auto
      mx-5
    "
  >
    <hero-header
      class="h-[100px] lg:h-[442px]"
      bgClass="bg-page-header"
      title="FAQs" />
    <div
     class="flex flex-col-reverse lg:flex-row gap-4 max-w-[103.25rem] mx-auto pt-12 lg:pt-32">
      <help-aside class="basis-[39%]" page="faqs" />
      <faq-accordion class="basis-[61%] mb-14" :qaPairs="faqs" />
    </div>
  </div>
</template>
