<template>
  <WaiverReviewFilters @filter="handleFilter" :token="token" />
  <TheFieldset class="mx-2 mb-4" legend="View Completed Waivers">
    <DataTable
      :value="waivers"
      :sortField="sorting.sortField"
      :sortOrder="sorting.sortOrder"
      scrollable
      scrollHeight="540px"
      tableStyle="min-width: 50rem"
      @update:sortField="updateSortField"
      @update:sortOrder="updateSortOrder"
      v-if="!loadingWaivers && waivers.length">
      <Column field="locationName" header="Event" sortable></Column>
      <Column field="createdOn" header="Completed Date" sortable>
        <template #body="slotProps">
          <p>{{ formatDate2(slotProps.data.createdOn) }}</p>
        </template>
      </Column>
      <Column field="firstName" header="First Name" sortable></Column>
      <Column field="lastName" header="Last Name" sortable></Column>
      <Column field="email" header="Email" sortable></Column>
      <Column field="hasViolation" header="A/V?" sortable>
        <template #body="slotProps">
          <Checkbox v-model="slotProps.data.hasViolation" binary disabled />
        </template>
      </Column>
    </DataTable>

    <span
      v-if="!waivers.length && !loadingWaivers"
      class="p-2 min-h-[200px] flex justify-center items-center">
      <p>No results found</p>
    </span>
    <span v-if="loadingWaivers" class="p-2 min-h-[200px]">
      <font-awesome-icon
        :icon="faSpinner"
        color="#B5B5B5"
        size="xl"
        class="animate-spin relative left-[50%]"
      />
    </span>

    <TheButton
      custom-bg="bg-[#2D7AB9] border-[#2D7AB9]"
      @click="exportCSV"
      :loading="exportingCSV"
      class="!w-1/4 mt-6"
      label="Export Data"
      />
  </TheFieldset>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Checkbox from 'primevue/checkbox';
import { useToast } from 'vue-toastification';

import api from '@/api';
import TheButton from '@/components/global/TheButton.vue';
import TheFieldset from '@/components/global/TheFieldset.vue';
import { formatDate2 } from '@/utils/format';
import WaiverReviewFilters from './WaiverReviewFilters.vue';

const toast = useToast();

const props = defineProps({
  token: String,
});

const waivers = ref([]);

const lastFilters = ref({});
const loadingWaivers = ref(false);
const exportingCSV = ref(false);
const sorting = ref({ sortField: 'createdOn', sortOrder: 1 });

const updateSortField = (event) => {
  sorting.value.sortField = event;
};
const updateSortOrder = (event) => {
  sorting.value.sortOrder = event;
};

const fetchWaivers = async (filters) => {
  loadingWaivers.value = true;
  try {
    const { data } = await api.waiver.getWaivers(props.token, filters);
    waivers.value = data;
  } catch ({ message }) {
    toast.error(message || 'An unexpected error occurred');
  } finally {
    loadingWaivers.value = false;
  }
};

const handleFilter = (filters) => {
  const parsedFilters = { ...filters };
  if (filters.from) {
    parsedFilters.from = new Date(filters.from).toISOString();
  }
  if (filters.to) {
    parsedFilters.to = new Date(filters.to).toISOString();
  }
  lastFilters.value = parsedFilters;
  fetchWaivers(parsedFilters);
};

const exportCSV = async () => {
  exportingCSV.value = true;
  try {
    const { data } = await api.waiver.getWaiverCSV(props.token, lastFilters.value);
    const blob = new Blob([data], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'EVolution_Experience_Waiver_Export.csv';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch ({ message }) {
    toast.error(message || 'An unexpected error occurred');
  } finally {
    exportingCSV.value = false;
  }
};

onMounted(() => {
  fetchWaivers({});
});
</script>
