<script setup>
import { computed, defineEmits, defineProps } from 'vue';
import CancelText from '@/components/cancelRegistration/CancelText.vue';
import store from '@/store';
import { useRoute } from 'vue-router';
import { useMeta } from 'vue-meta';

defineProps({
  disableButton: { type: Boolean, required: true },
});
const emit = defineEmits(['cancel-registration']);

const siteType = computed(() => {
  const eventType = store.getters['user/eventType'];

  if (eventType === 'acuratraining' || eventType === 'zdxdrive') {
    return 'acura';
  }
  if (eventType === 'hondatraining' || eventType === 'prologuedrive') {
    return 'honda';
  }
  return 'consumer';
});

const route = useRoute();
useMeta({
  meta: [
    { name: 'description', content: route.meta.description },
    { 'http-equiv': 'Content-Type', content: 'text/html;charset=utf-8' },
  ],
});

const cancelRegistration = () => emit('cancel-registration');
</script>
<template>
<div class="md:grid md:grid-cols-2">
    <template v-if="siteType === 'consumer' || siteType === 'acura'">
      <img
        class="hidden md:block"
        src="@/assets/img/cancel-registration/zdx.png"
        alt="Blue ZDX front focused on the LED headlights" />
    </template>
    <template v-else>
      <img
        class="hidden md:block"
        src="@/assets/img/cancel-registration/prologue.png"
        alt="Prologue side mirror wet by rain" />
    </template>
  <cancel-text
    :disableButton="disableButton"
    @cancel-registration="cancelRegistration"
    class="text-center mb-[2rem] md:mb-0"
  />
</div>
</template>
