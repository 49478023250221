<script setup>
import { AccordionList, AccordionItem } from 'vue3-rich-accordion';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import '@/faqs-accordion-styles.scss';
import { computed, onUpdated, ref } from 'vue';
import { trackCustomEvent } from '@/utils/analytics';
import store from '@/store';

const props = defineProps({
  qaPairs: Array,
});

const openedAccordion = ref();
const eventType = computed(() => store.getters['user/eventType']);

const trackAccordion = (event) => {
  if (openedAccordion.value !== event) {
    trackCustomEvent('FAQ:Accordion', eventType.value, 'faqs', { action: 'expand', actionValue: props.qaPairs[event].question });
  }
  openedAccordion.value = event;
};
onUpdated(() => {
  const accordionHeaders = document.querySelectorAll('.accordion-item__summary');
  accordionHeaders.forEach((header, index) => {
    header.addEventListener('click', () => trackAccordion(index));
  });
});
</script>

<template>
  <AccordionList @click="accordionClicked">
    <AccordionItem v-for="(qaPair, index) in qaPairs" :key="index">
      <template #summary>
        <div
          class="
            flex
            items-center
            font-MyriadProLight
            tracking-[0.036em]
            lg:text-xl
          "
          data-accordion="header"
        >
          <div
            class="
              h-9
              w-9
              lg:h-10 lg:w-10
              bg-dynamic
              flex
              items-center
              justify-center
              rounded-full
              font-MyriadProLight
              text-white text-lg
              lg:text-[1.375rem]
              flex-shrink-0
              mr-6
            "
          >
            Q
          </div>
          {{ qaPair.question }}
        </div></template
      >
      <template #icon>
        <font-awesome-icon
          :icon="faChevronRight"
          class="
            text-base text-dynamic
            stroke-[3.75rem] stroke-dynamic
          "
        />
      </template>
      <div class="flex">
        <div
          class="
            h-9
            w-9
            lg:h-10 lg:w-10
            border-[3px]
            border-dynamic
            flex
            items-center
            justify-center
            rounded-full
            font-MyriadProLight
            text-dynamic text-lg
            lg:text-[1.375rem]
            flex-shrink-0
            mr-6
          "
        >
          A
        </div>
        <h3
          class="
            font-MyriadProLight
            tracking-[0.036em]
            lg:text-xl lg:leading-loose
          "
        >
          {{ qaPair.answer }}
        </h3>
      </div>
    </AccordionItem>
  </AccordionList>
</template>
