<script setup>
import isNil from '@/utils/isNil';
import { computed, ref, onMounted } from 'vue';
import store from '@/store';
import GenericLocation from '@/components/location/GenericLocation.vue';
import apiManager from '@/api';
import { useRoute } from 'vue-router';
import { useMeta } from 'vue-meta';
import locationsContext from '@/store/modules/locations/locationsContext';

const props = defineProps({
  locationName: String,
});

const route = useRoute();

const changeTitle = (title) => { document.title = title; };

const location = computed(() => {
  const location2 = store.getters['locations/location'](props.locationName);
  if (!isNil(location2?.id)) changeTitle(`${route.meta.title} | ${location2.city.split(',')[0]}`);
  return {
    ...location2,
    locationName: location2?.lot ? `${location2?.locationName} - ${location2?.lot}` : location2?.locationName,
  };
});

const findLocationInContext = () => Object
  .values(locationsContext)
  .filter((locationContext) => locationContext.locationId === props.locationName)[0];

useMeta({
  meta: [
    { name: 'description', content: `Evolution Experience - ${location.value?.city?.split(',')[0] || findLocationInContext().linkName}` },
    { 'http-equiv': 'Content-Type', content: 'text/html;charset=utf-8' },
  ],
});

const eventType = computed(() => store.getters['user/eventType']);

const sessions = ref([]);

const getSessions = async (locationId) => {
  const { data } = await apiManager.reservations.getSessions({
    eventType: eventType.value, locationId,
  });
  return data;
};

const loadSessions = async (selectedLocation) => {
  const locationId = selectedLocation?.id ?? findLocationInContext().id;
  const data = await getSessions(locationId);
  sessions.value = data;
};

onMounted(async () => {
  await loadSessions(location.value);
});
</script>
<template>
  <template v-if="location?.id">
    <generic-location :location="location" :sessions="sessions"/>
  </template>
</template>
