<template>
  <p class="text-xl text-center">
    <b>RELEASE and WAIVER of LIABILITY, ASSUMPTION of RISK, and INDEMNITY AGREEMENT</b>
  </p>
  <p>
    In consideration of being allowed to compete, officiate, observe, work for, or participate in
    any way in the Event referred to above, the undersigned, for themselves, personal
    representatives, heirs, estate, next of kin, guardian, successors, or assigns:
  </p>
  <p>
    1. ACKNOWLEDGES, agrees, and represents that the undersigned has or will immediately upon
    entering the event, and will continuously thereafter, inspect the area which the undersigned
    has entered, and further agrees and warrants that if at any time the undersigned feels to be
    unsafe, the undersigned will immediately advise the officials of such, and will leave the
    Event and/or refuse to participate further in the Event.
  </p>
  <p>
    2. AGREES that during the Event, participation is prohibited while under the influence of
    alcohol, drugs, or any substance which affects the ability to operate or control a vehicle, or
    while affected by any medical condition that will impair the safe operation of any vehicle.
  </p>
  <p class="html2pdf__page-break">
    3. RELEASES, WAIVES, AGREES NOT TO SUE, WILL INDEMNIFY, AND HOLD HARMLESS AMERICAN HONDA MOTOR
    CO., INC. (AHM), and all affiliates, sanctioning organizations, or any subdivision thereof,
    track operator, promoters, sponsors, advertisers, owners and/or lessees of premises used to
    conduct the Event, premises and Event inspectors, surveyors, underwriters, consultants, and
    others who give recommendations, directions, or instructions, or engage in risk evaluations or
    loss control activities regarding the premises or Event, and each of them, their directors,
    officers, agents, representatives, and employees (herein referred to as the “RELEASEES”) FROM
    ALL LIABILITY to the undersigned, their personal representatives, heirs, estate, next of kin,
    guardian, successors, and/or assigns, FOR ANY AND ALL LOSS OR DAMAGE, AND ANY CLAIM OR DEMANDS
    THEREOF ON ACCOUNT OF INJURY TO THE PERSON OR PROPERTY, OR RESULTING IN DEATH OF THE
    UNDERSIGNED ARISING OUT OF, OR RELATED TO THE EVENT, WHETHER CAUSED BY THE NEGLIGENCE OR
    CARELESSNESS OF THE RELEASEES OR OTHERWISE.
  </p>
  <p>
    4. AGREES TO INDEMNIFY AND HOLD HARMLESS the Releasees and each of them from any loss,
    liability, damage, or cost they may incur arising out of or related to the Event, whether
    caused by the negligence or carelessness of the Releasees or otherwise.
  </p>
  <p>
    5. ASSUMES FULL RESPONSIBILITY FOR ANY RISK OF BODILY INJURY, DEATH, OR PROPERTY DAMAGE
    arising out of or related to the Event, whether caused by the negligence or carelessness of
    the Releasees or otherwise.
  </p>
  <p>
    6. ACKNOWLEDGES that the activities of the Event ARE VERY DANGEROUS and involve risk of
    SERIOUS INJURY and/or DEATH, and/or property damage; and ACKNOWLEDGES that injuries received
    may be COMPOUNDED OR INCREASED by negligent rescue operations or procedures of the Releasees.
  </p>
  <p class="html2pdf__page-break">
    7. AGREES that this Release and Waiver of Liability, Assumption of Risk, and Indemnity
    Agreement extends to all acts of negligence by the Releasees, including negligent rescue
    operations or procedures, and is intended to be as broad and inclusive as is permitted by the
    laws of the state in which the Event is conducted; and that if any portion thereof is held
    invalid, it is agreed that the balance of the Agreement shall continue in full force and
    effect.
  </p>
  <p class="text-center">
    <b>HONDA PROVING CENTER CONFIDENTIALITY AGREEMENT (If applicable)</b>
  </p>
  <p>
    I understand that photography, filming, or recording at Honda Proving Center (HPC) is strictly
    prohibited without permission, and that American Honda Motor Co., Inc., its personnel and/or
    representatives may confiscate my camera, cellular telephone, and/or smart phone while I am on
    site. My camera, cellular telephone, and/or smart phone will be returned to me upon my
    departure from HPC. I will not at any time directly or indirectly reproduce, disclose,
    divulge, disseminate, publish, reveal, reverse engineer, or otherwise make known to anyone
    outside of AHM any information, design, specification, idea, concept, plan, copy, formula,
    picture, drawing, process, procedure, sample or other confidential information (collectively,
    “Confidential Information”) disclosed to me in connection with my visit to HPC.
  </p>
  <p class="text-center">
    <b>MEDIA RELEASE AGREEMENT</b>
  </p>
  <p class="html2pdf__page-break">
    I hereby agree to irrevocably grant to American Honda Motor Co., Inc. its parent, employees,
    directors, officers, subsidiaries, affiliates, authorized agents, and representatives (“the
    Honda Parties”) the right and license to: (a) film, photograph and/or record me while I am
    participating or engaged in, involved with, or attending the Event; and (b) use the resulting
    recordings, films, and photographs, as well as any other content I provide (including but not
    limited to my name, social media handle(s), image, picture, likeness, voice, and biographical
    information (collectively, my “Persona”)), edited or altered as the Honda Parties may see fit,
    together with any other materials (collectively, the “Materials”), in any manner or media now
    known or hereinafter invented, anywhere in the world, at any time and without time
    limitations, for purposes of advertising and promoting the Honda Parties and/or their products
    and services. I agree to waive all rights under privacy, publicity, defamation, and
    proprietary rights relating to my name or likeness in connection with the Materials, including
    but not limited to moral rights of authors. Honda Parties may transfer or license any of its
    rights hereunder. <br />
  </p>
  <p>
    I also agree that (a) I have the right to grant the rights granted here, (b) I am eighteen
    (18) years of age or older, or my parent/guardian has also signed below [please indicate below
    if you are signing on behalf of a minor] (c) I have read and understood this Agreement, (d)
    this Agreement does not conflict with any existing commitment of mine, (e) my appearance in
    the Materials shall not be subject to any union, guild or collective bargaining agreement, (f)
    Honda Parties’ use of the Materials shall not require payment to, or additional permissions
    from, any third party, (g) I have no right to approve (or stop the use of) the Materials, no
    claim to compensation, special credit, or other compensation, and no other claim arising out
    of any use of my Persona/the Materials - provided that the Honda Parties adhere to this
    Agreement. <br />
  </p>
  <p>
    I agree to release Honda Parties and agree to hold them harmless from and against any costs,
    claims, damages, liabilities, losses of any kind, including, without limitation, any claim
    alleging libel, defamation, invasion of any right of privacy or publicity, copyright
    infringement, personal injury, wrongful death, property damage or expenses (including without
    limitation, attorneys’ fees and costs) arising from or related to a breach or alleged breach
    of my warranties, representations and agreements herein or for any permitted exercise by Honda
    Parties of the rights granted herein.
  </p>
  <p>
    This release constitutes the parties' entire understanding with respect to the subject matter
    hereof, and supersedes any and all written or oral, prior or contemporaneous understandings
    and agreements. This release shall be construed and interpreted in accordance with the laws of
    the State of California, USA applicable to contracts made and performed entirely therein,
    without regard to conflicts of laws principles. You agree and consent that jurisdiction and
    venue of all matters relating to this Agreement shall be vested exclusively in the federal,
    state and local courts within Los Angeles, California, USA.
  </p>
  <p class="html2pdf__page-break">
    I CERTIFY THAT I HAVE READ AND AGREE TO the Release and Waiver of Liability, Assumption of
    Risk and Indemnity Agreement, Honda Proving Center Confidentiality Agreement (if applicable),
    and Media Release Agreement, fully understand its terms and conditions, and fully understand
    that I have given up substantial rights by signing them, and have signed them freely and
    voluntarily without any inducement, assurance, or guarantee being made to me, and intend by
    signature to be a complete and unconditional release of all liability to the fullest extent
    allowed by law.
  </p>
</template>

<style scoped lang="scss">
p {
  @apply my-4;
}
</style>
