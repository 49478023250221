<script setup>
import isNil from '@/utils/isNil';
import { useStore } from 'vuex';
import apiManager from '@/api';
import {
  computed,
  ref,
  onMounted,
  onUnmounted,
} from 'vue';
import { handleRouteWithEvent } from '@/utils/eventType';
import { useRouter, useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';
import AddTime from './AddTime.vue';

const store = useStore();

const eventType = computed(() => store.getters['user/eventType']);
const sessionUIC = computed(() => store.getters['user/sessionUIC']);

const router = useRouter();
// eslint-disable-line
const route = useRoute();

const sessionTime = ref(0);

const timer = ref(60000);

const reserveRegistration = async ({
  sessionId,
  uic,
}) => {
  const { data } = await apiManager.reservations.postReserveRegistration(
    { sessionId, uic },
  );
  return data;
};

const loadReserveRegistration = async ({ sessionId }) => {
  const data = await reserveRegistration({ sessionId });
  store.commit('user/setSessionUIC', data.uic);
  const t = data.time.split(':');
  const time = (+t[0]) * 60 + (+t[1]);
  sessionTime.value = time;
  timer.value = time;
};

if (!isNil(eventType.value)) {
  loadReserveRegistration({
    eventType: eventType.value,
    sessionId: route.query.id,
  });
}

const addTimeToReservation = async () => {
  const { data } = await apiManager.reservations.putAddTime(
    { uic: sessionUIC.value, sessionId: route.query.id },
  );

  updateTimer(data);
};

const toast = useToast();

let interval;

const navigateTo = (path) => {
  const routeWithEvent = handleRouteWithEvent(eventType.value, path);
  router.push(routeWithEvent);
};

function countTimer() {
  if (!interval) {
    interval = setInterval(() => {
      timer.value -= 1;
      if (timer.value === 299) {
        toast.error(
          {
            component: AddTime,
            props: {
              uic: sessionUIC.value,
              id: route.query.id,
            },
            listeners: {
              click: () => addTimeToReservation(),
            },
          },
          {
            timeout: false,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            icon: false,
            closeButton: false,
          },
        );
      }
      if (timer.value === 0) {
        toast.error('Your registration has timed out. Please try registering again', {
          timeout: false,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
        });
        navigateTo('/');
      }
    }, 1000);
  } else {
    interval = null;
  }
}

const updateTimer = async (data) => {
  const t = data.split(':');
  const time = (+t[0]) * 60 + (+t[1]);
  sessionTime.value = time + 1;
  timer.value = sessionTime.value;
};

const convertTime = computed(() => {
  const minutes = Math.floor(timer.value / 60);
  const seconds = timer.value - minutes * 60;
  const digitMinutes = String(minutes).length;
  const digitSeconds = String(seconds).length;
  const suffix = minutes === 0 ? 'SECS' : 'MINS';
  return `${digitMinutes === 1 ? '0' : ''}${minutes}:${digitSeconds === 1 ? '0' : ''}${seconds} ${suffix}`;
});

onMounted(() => {
  countTimer();
});

onUnmounted(() => {
  clearInterval(interval);
});
</script>
<template>
  <h2 class="
    text-2xl
    text-center
    md:text-right
    font-MyriadProBold">
    Time remaining
  </h2>
  <p class="
  text-2xl
  text-center
  md:text-right
  font-MyriadProBold
  text-dynamic">
    {{ convertTime }}
  </p>
</template>
<script>
</script>
