<script setup>
import EventReporting from '@/components/reporting/EventReporting.vue';
import { reactive } from 'vue';
import apiManager from '@/api';
import locationsContext from '@/store/modules/locations/locationsContext';
import EVENT_TYPES from '@/constants/events';

const formatTableData = (reportObj) => {
  const keys = Object.keys(reportObj);
  return keys.map((key) => [reportObj[key].name, reportObj[key].value]);
};

const loadReport = async ({
  locationId,
  eventType,
  eventDate,
  sessionId,
}) => {
  const { data } = await apiManager.report.get({
    locationId,
    eventType,
    eventDate,
    sessionId,
  });
  return data;
};

const tableDataInitial = {
  eventType: undefined,
  location: undefined,
  date: undefined,
  session: undefined,
  data: [],
};

const state = reactive({
  tableData: { ...tableDataInitial },
  filterData: undefined,
});
const resetTableData = () => {
  state.tableData.eventType = tableDataInitial.eventType;
  state.tableData.location = tableDataInitial.location;
  state.tableData.date = tableDataInitial.date;
  state.tableData.session = tableDataInitial.session;
  state.tableData.data = tableDataInitial.data;
};

const getMetrics = async ({
  location,
  eventType,
  date,
  session,
  sessionTime,
}) => {
  resetTableData();
  const report = await loadReport({
    locationId: location,
    eventType,
    sessionId: session,
    eventDate: date,
  });
  const eventTypeName = EVENT_TYPES.find((e) => e.value === eventType)?.name;
  state.tableData.eventType = eventTypeName || 'All Events';
  state.tableData.location = locationsContext[location]?.linkName || 'All Locations';
  state.tableData.date = date || 'All Dates';
  state.tableData.session = sessionTime || 'All Sessions';
  state.tableData.data = formatTableData(report);
};

const loadFilterOptions = async () => {
  const { data: { data } } = await apiManager.report.getFilterAll();
  state.filterData = data;
};

loadFilterOptions();

</script>
<template>
  <event-reporting
    @get-metrics="getMetrics"
    :tableData="state.tableData"
    :filterData="state.filterData"
  />
</template>
