import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import Cookies from 'js-cookie';

const connectionString = process.env.VUE_APP_INSIGHTS_CONNECTION_STRING || '';

const analytics = new ApplicationInsights({
  config: {
    connectionString,
  },
});

const trackCustomEvent = (name, eventType, page, customProps) => {
  let customObj = {};
  const source = Cookies.get('experiencedrive_source');

  if (source) customObj.source = source;
  customObj.eventType = eventType;
  customObj.page = page;

  customObj = { ...customObj, ...customProps };

  analytics.trackEvent({ name }, customObj);
};

const trackPageView = (...args) => {
  analytics.trackPageView(...args);
};

analytics.loadAppInsights();

export { analytics, trackCustomEvent, trackPageView };
