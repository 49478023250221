<script setup>
import apiManager from '@/api';
import EVENT_TYPES from '@/constants/events';
import locationsContext from '@/store/modules/locations/locationsContext';
import {
  ref,
  reactive,
  computed,
  watch,
  onMounted,
} from 'vue';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import useValidate from '@vuelidate/core';
import { useToast } from 'vue-toastification';
import {
  email,
  helpers,
  required,
  requiredIf,
} from '@vuelidate/validators';
import { useMeta } from 'vue-meta';
import TheTabs from '@/components/global/TheTabs.vue';
import { trackCustomEvent } from '@/utils/analytics';
import { useRoute } from 'vue-router';
import convertArrayIntoCSV from '@/utils/csvGenerator';
import {
  formatTime,
  formatDate2,
  formatPhoneNumber,
  unmaskPhoneNumber,
} from '@/utils/format';
import sortArray from '@/utils/sort';
import TheFieldset from '../global/TheFieldset.vue';
import TheButton from '../global/TheButton.vue';
import TheTextfield from '../registration/TheTextfield.vue';
import TheSelect from '../global/TheSelect.vue';
import TheModal from '../global/TheModal.vue';
import AttendeeEdit from './modals/AttendeeEditModal.vue';

const route = useRoute();

const tabs = [
  { name: 'Check-in', slot: 'checkIn' },
  { name: 'Contact', slot: 'attendeeData' },
];

const props = defineProps({
  token: String,
});

useMeta({
  meta: [
    { name: 'robots', content: 'noindex' },
  ],
});

const locations = ref([]);
const activeTab = ref(0);

const isSearching = ref(false);
const wasSearched = ref(false);
const isClearing = ref(false);
const sortCheckInTable = ref({ name: '', order: '' });
const sortAttendeeDataTable = ref({ name: '', order: '' });

const state = reactive({
  eventType: '',
  location: '',
  session: '',
  email: '',
  phone: '',
  searchLastName: '',
  attendeeTypeOptions: [
    { id: 0, name: 'Waitlist' },
    { id: 1, name: 'Registered' },
    { id: 3, name: 'Checked In' },
  ],
  attendeeTypeSearch: [0, 1, 3],
  columns: [
    {
      title: 'First Name',
      name: 'firstName',
    },
    {
      title: 'Last Name',
      name: 'lastName',
    },
    {
      title: 'Session Date',
      name: 'sessionDate',
    },
    {
      title: 'Start Time',
      name: 'startTime',
    },
    {
      title: 'Attendee Type',
      name: 'attendeeType',
    },
  ],
  checkInColumns: [
    {
      title: 'Waiver?',
      name: 'waiver',
    },
    {
      title: 'Walk-in?',
      name: 'walkIn',
    },
    {
      title: 'Passenger?',
      name: 'passenger',
    },
    {
      title: 'Child Count',
      name: 'childCount',
    },
    {
      title: 'Edit',
      name: 'editAttendee',
    },
    {
      title: 'Check-in',
      name: 'checkedIn',
    },
    {
      title: 'Print Label',
      name: 'printLabel',
    },
  ],
  attendeeDataColumns: [
    {
      title: 'Phone',
      name: 'phoneNumber',
    },
    {
      title: 'Email',
      name: 'emailAddress',
    },
  ],
  trainingAttendeeColumns: [
    {
      title: 'Dealer Name',
      name: 'dealerName',
    },
    {
      title: 'Dealer Number',
      name: 'dealerNumber',
    },
    {
      title: 'Zone Code',
      name: 'zoneCode',
    },
    {
      title: 'District Code',
      name: 'districtCode',
    },
  ],
  rows: [],
});

const registrationLink = computed(() => {
  const locationId = state.location;
  const location = locations.value.find((loc) => loc.value === locationId);
  return location ? location.link : '';
});

const trackLink = (linkName) => {
  const pageName = route.name || route.path;

  trackCustomEvent(`EventCheckIn:${linkName}`, state.eventType, pageName);
};

const navigateRegistrationLink = (linkName) => {
  trackLink(linkName);
  const routerEventType = EVENT_TYPES.find((e) => e.value === state.eventType).route;
  const routerPath = routerEventType ? linkName : linkName.substring(1);
  window.open(`${routerEventType}${routerPath}`, '_blank');
};

const sessions = ref([]);
const toast = useToast();

const requiredValidator = helpers.withMessage('Field is required', required);
const emailValidator = helpers.withMessage('Please provide a valid email', email);

const rulesSearch = computed(() => (
  {
    eventType: {
      requiredValidator,
    },
    location: {
      requiredValidator,
    },
    attendeeTypeSearch: {
      requiredValidator,
    },
    session: {
      requiredEmail: helpers.withMessage('Field is required', requiredIf(!state.email && !state.searchLastName && !state.phone)),
    },
    phone: {
      requiredEmail: helpers.withMessage('Field is required', requiredIf(!state.email && !state.searchLastName && !state.session)),
    },
    email: {
      requiredEmail: helpers.withMessage('Field is required', requiredIf(!state.searchLastName && !state.phone && !state.session)),
      emailValidator,
    },
    searchLastName: {
      requiredEmail: helpers.withMessage('Field is required', requiredIf(!state.email && !state.phone && !state.session)),
    },
  }
));

const vSearch$ = useValidate(rulesSearch, state, { $autoDirty: true });
// const vGuest$ = useValidate(rulesGuest, state, { $autoDirty: true });
function walkupTurnedAway() {
  if (window.confirm('I confirm that I want to record a walkup that was turned away.')) {
    const now = new Date();
    const date = new Date(
      now.getTime() - (now.getTimezoneOffset() * 60000),
    ).toISOString().replace('T', ' ').substring(0, 23);

    const walkupTurned = () => {
      apiManager.checkIn.postWalkupTurned({ timeStamp: date });
    };
    walkupTurned();
  }
}
const getSessions = async (eventType, locationId) => {
  const { data } = await apiManager.reservations.getSessions({
    eventType, locationId,
  });
  return data;
};
const isTrainingEvent = ref(false);
function search() {
  vSearch$.value.$validate();
  if (!vSearch$.value.$error) {
    const searchRequest = {
      authToken: props.token,
      locationId: state.location,
      email: state.email,
      lastName: state.searchLastName,
      phone: unmaskPhoneNumber(state.phone),
      sessionId: state.session,
      eventType: state.eventType,
      attendeeTypes: state.attendeeTypeSearch,
    };

    const searchCheckIn = async () => {
      isTrainingEvent.value = state.eventType === '1' || state.eventType === '2';
      try {
        isSearching.value = true;
        const { data } = await apiManager.checkIn.postSearchCheckIn(searchRequest);
        state.rows = activeTab.value === 0
          ? sortArray(data, sortCheckInTable.value)
          : sortArray(data, sortAttendeeDataTable.value);
        wasSearched.value = true;
        isSearching.value = false;
      } catch (err) {
        isSearching.value = false;
        toast.error('An error ocurred while fetching your data. Please try again later.');
      }
    };
    searchCheckIn();
  }
}

function searchClear() {
  state.eventType = '';
  state.location = '';
  state.session = '';
  state.phone = '';
  state.email = '';
  state.searchLastName = '';
  state.rows = [];
  state.attendeeTypeSearch = [0, 1, 3];
  vSearch$.value.$reset();
  wasSearched.value = false;
  sortAttendeeDataTable.value = { name: '', order: '' };
  sortCheckInTable.value = { name: '', order: '' };
  activeTab.value = 0;
  isTrainingEvent.value = false;
}

function checkIn(row) {
  const postCheckIn = async () => {
    try {
      const { data } = await apiManager.checkIn.postCheckIn(row, props.token);
      toast.success('The attendee has been successfully checked-in.');
      if (!data) {
        search();
      }
    } catch (err) {
      toast.success('Something wrong happened. Please, try again.');
    }
  };
  postCheckIn();
}

function printLabel(row) {
  const payload = {
    firstName: row.firstName,
    lastName: row.lastName,
    dptsid: row.dptsid,
    dealerName: row.dealerName,
  };
  const postPrintLabel = async () => {
    try {
      await apiManager.print.postPrintLabel(payload);
      toast.success('The print request has been successfully submitted.');
    } catch (err) {
      toast.success('Something wrong happened. Please, try again.');
    }
  };
  postPrintLabel();
}

const loadSessions = () => {
  if (state.eventType && state.location) {
    getSessions(state.eventType, state.location).then((data) => {
      sessions.value = Object.values(data).map((session) => ({
        name: `${formatDate2(session.startTime)} - ${formatTime(session.startTime)}`,
        value: session.sessionId,
      }));
    }).catch((error) => {
      console.error(error);
    });
  }
};

const attendeeModal = ref({ opened: false, data: {}, eventType: null });
const openModal = (row) => {
  attendeeModal.value = {
    opened: true,
    data: !row.guest ? row : state.rows.find((item) => item.id === row.id && !item.guest),
    eventType: state.eventType,
  };
};

const closeModal = () => {
  attendeeModal.value = {
    opened: false,
    data: {},
  };
};

const handleDownloadCSV = () => {
  const body = [];
  /* eslint-disable quote-props */
  state.rows.forEach((row) => {
    let obj = {
      'First Name': row.firstName,
      'Last Name': row.lastName,
      'Session Date': formatDate2(row.startTime),
      'Start Time': formatTime(row.startTime),
      'Attendee Type': row.attendeeType,
      'Phone': row.phoneNumber,
      'Email': row.emailAddress,
    };
    if (isTrainingEvent.value) {
      obj = {
        ...obj,
        'Dealer Name': row.dealerName,
        'Dealer Number': row.dealerNumber,
        'Zone Code': row.zoneCode,
        'District Code': row.districtCode,
      };
    }
    body.push(obj);
  });

  convertArrayIntoCSV(body);
};

const closeWithToast = (action) => {
  closeModal();
  const toastMessage = action === 'updated'
    ? 'Success! Information Updated.'
    : 'Success! Reservation Cancelled.';
  toast.success(toastMessage);
  search();
};

const clearPrinterQueue = () => {
  isClearing.value = true;
  const clearQueue = async () => {
    try {
      await apiManager.print.clearQueue();
      toast.success('Success! Printer Queue Cleared.');
      isClearing.value = false;
    } catch (err) {
      toast.success('Something wrong happened. Please, try again.');
      isClearing.value = false;
    }
  };
  clearQueue();
};

const findLocationInContext = (locationId) => {
  const locationsArray = Object.values(locationsContext);
  return locationsArray
    .find((locationContext) => locationContext.id === locationId);
};

const handleSortCheckIn = ({ name }) => {
  if (sortCheckInTable.value.name === name) {
    sortCheckInTable.value.order = sortCheckInTable.value.order === 'asc' ? 'desc' : 'asc';
  } else {
    sortCheckInTable.value.order = 'asc';
  }
  sortCheckInTable.value.name = name;
  state.rows = sortArray(
    state.rows,
    sortCheckInTable.value,
  );
};
const handleSortAttendeeData = ({ name }) => {
  if (sortAttendeeDataTable.value.name === name) {
    sortAttendeeDataTable.value.order = sortAttendeeDataTable.value.order === 'asc' ? 'desc' : 'asc';
  } else {
    sortAttendeeDataTable.value.order = 'asc';
  }
  sortAttendeeDataTable.value.name = name;
  state.rows = sortArray(
    state.rows,
    sortAttendeeDataTable.value,
  );
};

const changeTab = (selectedTab) => {
  state.rows = selectedTab === 'checkIn'
    ? sortArray(state.rows, sortCheckInTable.value)
    : sortArray(state.rows, sortAttendeeDataTable.value);
  activeTab.value = selectedTab;
};

watch(() => [state.location], async () => {
  state.session = '';
  loadSessions();
}, { immediate: true });

watch(() => state.eventType, async () => {
  state.location = '';
  const { data } = await apiManager.reservations.getLocations(state.eventType);
  locations.value = data.map((location) => ({
    name: location.city,
    value: location.locationId,
    link: findLocationInContext(location.locationId).path,
  }
  ));
});
onMounted(loadSessions);
</script>
<template>
  <div class="p-5 lg:p-14 grid lg:grid-cols-6 mb-10">
    <div class="col-span-6">
      <div class="grid sm:grid-cols-2 gap-4">
        <h1 class="
          mb-10
          text-2xl
          sm:text-3xl
          lg:text-4xl
          font-MyriadProLight
          uppercase
        ">
          <span class="border-b-[6px] border-dynamic">Eve</span>nt Check-in
        </h1>
        <TheButton
          @click="clearPrinterQueue()"
          label="Clear Printer Queue"
          class="sm:px-8 mb-4 lg:col-start-4 lg:border-2"
          custom-bg="bg-[#2D7AB9] border-[#2D7AB9]"
          :loading="isClearing"
          />
          <TheButton
          @click="walkupTurnedAway()"
          label="Walkup turned away"
          custom-bg="bg-[#2D7AB9] border-[#2D7AB9]"
          class="sm:px-8 mb-4 lg:col-start-5 lg:border-2"
        />
      </div>
          <TheFieldset
            legend="Check-in Attendee"
            class="lg:grid-cols-10"
          >
          <form
            @submit.prevent="search"
            class="col-span-10"
          >
            <TheFieldset
              legend="Search"
              class="
                md:grid-cols-3
                xl:grid-cols-6
                gap-4
                mb-4
              ">
              <TheSelect
                name="locations"
                placeholder="Event*"
                :options="EVENT_TYPES"
                :dirty="vSearch$.eventType.$dirty"
                :errorMessage="vSearch$.eventType.$errors[0]?.$message"
                v-model:modelValue="state.eventType"
              />
              <TheSelect
                name="locations"
                placeholder="Event Location*"
                :options="locations"
                :dirty="vSearch$.location.$dirty"
                :errorMessage="vSearch$.location.$errors[0]?.$message"
                v-model:modelValue="state.location"
              />
              <TheSelect
                name="session"
                placeholder="Session*"
                :options="sessions"
                :dirty="vSearch$.session.$dirty"
                :errorMessage="vSearch$.session.$errors[0]?.$message"
                v-model:modelValue="state.session"
              />
              <TheTextfield
                type="phone"
                name="Phone"
                placeholder="Phone*"
                mask="(000) 000-0000"
                maxLength="14"
                tooltip="Please provide the phone number that you registered with."
                :dirty="vSearch$.phone.$dirty"
                :errorMessage="vSearch$.phone.$errors[0]?.$message"
                v-model:value="state.phone"
              />
              <TheTextfield
                type="email"
                name="Email"
                placeholder="Email*"
                tooltip="Please provide the email you registered with."
                :dirty="vSearch$.email.$dirty"
                :errorMessage="vSearch$.email.$errors[0]?.$message"
                v-model:value="state.email"
              />
              <TheTextfield
                name="lastname"
                placeholder="Last Name*"
                :dirty="vSearch$.searchLastName.$dirty"
                :errorMessage="vSearch$.searchLastName.$errors[0]?.$message"
                v-model:value="state.searchLastName"
              />
              <div class="lg:col-span-2 font-MyriadProLight">
                <span>Attendee Type*</span> <br />
                <div class="flex space-between gap-4">
                  <label
                    v-for="(option, index) in state.attendeeTypeOptions"
                    :key="`checkbox-filter-${index}`"
                    :for="`checkbox-filter-${index}`">
                      <input
                        :id="`checkbox-filter-${index}`"
                        type="checkbox"
                        :value="option.id"
                        v-model="state.attendeeTypeSearch" />
                        {{ option.name }}
                  </label>
                </div>
                  <small
                    class="text-acura-primary font-MyriadProRegular"
                    v-if="vSearch$.attendeeTypeSearch.$errors[0]?.$message
                      && vSearch$.$dirty">
                    Field is required
                  </small>
              </div>
              <TheButton
                @click="searchClear()"
                class="
                  md:col-start-2
                  xl:col-start-5
                  border-2
                "
                custom-bg="bg-[#2D7AB9] border-[#2D7AB9]"
                label="CLEAR SEARCH"
                />
                <TheButton
                type="submit"
                class="border-2"
                custom-bg="bg-[#2D7AB9] border-[#2D7AB9]"
                label="SEARCH"
                :loading="isSearching"
              />
            </TheFieldset>
          </form>
          <span
            v-if="wasSearched && !state.rows?.length && !isSearching"
            class="border col-span-10 p-2">
            <p>No results found</p>
          </span>
          <span
            v-if="isSearching"
            class="border col-span-10 p-2">
            <font-awesome-icon
              :icon="faSpinner"
              color="#B5B5B5"
              size="xl"
              class="animate-spin relative left-[50%]"
            />
          </span>
          <TheTabs
            class="col-span-10"
            :tabs="tabs"
            :active-tab="activeTab"
            @changeTab="changeTab"
            enableDownload
            @onDownload="handleDownloadCSV"
            v-if="wasSearched && state.rows?.length && !isSearching">
            <template v-slot:checkIn>
              <table
            v-if="state.rows?.length && !isSearching"
            class="w-full table-fixed col-span-10 xl:border mb-4">
            <thead class="
              hidden
              xl:table-header-group
              overflow-hidden
              xl:overflow-visible
            ">
              <tr class="bg-acura-gray-medium">
                <th
                  v-for="(column, index) in state.columns" :key="index"
                  scope="col"
                  tabindex="0"
                  class="
                    p-2
                    cursor-pointer
                    select-none
                    focus:border-2
                    focus:border-solid
                    focus:border-black"
                  @click="() => handleSortCheckIn(column)"
                  @keypress.enter="() => handleSortCheckIn(column)"
                >
                <span class="flex items-center text-center">
                  {{ column.title }}
                  <template v-if="sortCheckInTable.name === column.name">
                    <img
                      src="@/assets/img/icons/up-arrow.svg"
                      class="w-4 ml-2"
                      :class="sortCheckInTable.order === 'desc' ? 'rotate-180' : ''"
                      alt="" />
                  </template>
                  <template v-else>
                    <img
                    class="w-4 ml-2"
                    src="@/assets/img/icons/sort.svg"
                    alt="sort icon with two arrows up and down" />
                  </template>
                </span>
                </th>
                <th
                  v-for="(column, index) in state.checkInColumns" :key="index"
                  scope="col"
                  class="p-2 select-none"
                >
                  {{ column.title }}
                </th>
              </tr>
            </thead>
            <tbody class="text-center py-2">
              <tr
                v-if="isChecking || isPrinting"
                class="relative w-full left-[50%]">
                <font-awesome-icon
                  :icon="faSpinner"
                  color="#B5B5B5"
                  size="2xl"
                  class="p-4 animate-spin"
                />
              </tr>
              <tr
                v-else
                v-for="(row, index) in state.rows" :key="index"
                class="xl:odd:bg-acura-gray-light block xl:table-row border border-b-2 mb-4"
              >
                <td
                  v-for="(column, indexColumn) in [...state.columns, ...state.checkInColumns]"
                  :key="indexColumn"
                  :data-label="column.title"
                  class="
                    py-2
                    xl:py-4
                    px-4
                    xl:px-0
                    block
                    min-h-[42px]
                    xl:table-cell
                    text-right
                    xl:text-center
                    border-b
                    xl:border-none
                    before:content-[attr(data-label)]
                    xl:before:content-none
                    before:float-left
                    before:font-bold
                    odd:bg-acura-gray-light
                    xl:odd:bg-transparent
                  ">
                  <span
                    v-if="
                      column.name === 'waiver'
                      || column.name === 'walkIn'
                      || column.name === 'passenger'">
                    <label :for='`checkbox-${column.name}-${index}`'>
                      <input
                        :id="`checkbox-${column.name}-${index}`"
                        type="checkbox"
                        v-model="row[column.name]
                      " />
                    </label>
                  </span>
                  <span v-if="column.name === 'childCount'"
                  class="flex justify-end xl:justify-center">
                    <TheTextfield
                      class="w-16 "
                      type="number"
                      name="child-count"
                      min="0"
                      max="99"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      v-model:value="row[column.name]"
                    />
                  </span>
                  <span v-if="column.name === 'editAttendee'">
                    <TheButton
                      @click="openModal(row)"
                      label="Edit"
                      class="px-2"
                    />
                  </span>
                  <span v-if="column.name === 'checkedIn'">
                    <TheButton
                      :disabled="!row.waiver"
                      @click="checkIn(row)"
                      :label="column.title"
                      class="px-2"
                    />
                  </span>
                  <span v-if="column.name === 'printLabel'">
                    <TheButton
                      @click="printLabel(row)"
                      label="Print"
                      class="px-2"
                    />
                  </span>
                  <span
                    v-if="
                      column.name !== 'walkIn'
                      && column.name !== 'waiver'
                      && column.name !== 'checkedIn'
                      && column.name !== 'passenger'
                      && column.name !== 'childCount'
                    ">
                    {{
                      column.name === 'startTime' ? formatTime(row[column.name])
                      : column.name === 'sessionDate' ? formatDate2(row['startTime'])
                      : row[column.name]
                    }}
                  </span>
                </td>
              </tr>
            </tbody>
              </table>
            </template>
            <template v-slot:attendeeData>
              <div>
                <table
            v-if="state.rows?.length && !isSearching"
            class="w-full table-fixed col-span-10 xl:border mb-4">
            <thead class="
              hidden
              xl:table-header-group
              overflow-hidden
              xl:overflow-visible
            ">
              <tr class="bg-acura-gray-medium">
                <th
                  v-for="(column, index) in [...state.columns, ...state.attendeeDataColumns]"
                  :key="index"
                  scope="col"
                  tabindex="0"
                  class="
                    p-2
                    cursor-pointer
                    select-none
                    focus:border-2
                    focus:border-solid
                    focus:border-black"
                  @click="() => handleSortAttendeeData(column)"
                  @keypress.enter="() => handleSortAttendeeData(column)"
                >
                <span class="flex items-center text-center">

                  {{ column.title }}
                  <template v-if="sortAttendeeDataTable.name === column.name">
                    <img
                      src="@/assets/img/icons/up-arrow.svg"
                      class="w-4 ml-2"
                      :class="sortAttendeeDataTable.order === 'desc' ? 'rotate-180' : ''"
                      alt="" />
                  </template>
                  <template v-else>
                    <img
                    class="w-4 ml-2"
                    src="@/assets/img/icons/sort.svg"
                    alt="sort icon with two arrows up and down" />
                  </template>
                </span>
                </th>
                <template v-if="isTrainingEvent">
                  <th
                  v-for="(column, index) in state.trainingAttendeeColumns"
                  :key="index"
                  scope="col"
                  tabindex="0"
                  class="
                    p-2
                    cursor-pointer
                    select-none
                    focus:border-2
                    focus:border-solid
                    focus:border-black"
                  @click="() => handleSortAttendeeData(column)"
                  @keypress.enter="() => handleSortAttendeeData(column)"
                >
                <span class="flex items-center text-center">

                {{ column.title }}
                <template v-if="sortAttendeeDataTable.name === column.name">
                  <img
                    src="@/assets/img/icons/up-arrow.svg"
                    class="w-4 ml-2"
                    :class="sortAttendeeDataTable.order === 'desc' ? 'rotate-180' : ''"
                    alt="" />
                </template>
                <template v-else>
                  <img
                  class="w-4 ml-2"
                  src="@/assets/img/icons/sort.svg"
                  alt="sort icon with two arrows up and down" />
                </template>
                </span>
                </th>
                </template>
              </tr>
            </thead>
            <tbody class="text-center py-2">
              <tr
                v-if="isChecking || isPrinting"
                class="relative w-full left-[50%]">
                <font-awesome-icon
                  :icon="faSpinner"
                  color="#B5B5B5"
                  size="2xl"
                  class="p-4 animate-spin"
                />
              </tr>
              <tr
                v-else
                v-for="(row, index) in state.rows" :key="index"
                class="xl:odd:bg-acura-gray-light block xl:table-row border border-b-2 mb-4"
              >
                <td
                  v-for="(column, indexColumn) in
                  (isTrainingEvent
                    ? [
                      ...state.columns,
                      ...state.attendeeDataColumns,
                      ...state.trainingAttendeeColumns,
                    ]
                    : [...state.columns, ...state.attendeeDataColumns])"
                  :key="indexColumn"
                  :data-label="column.title"
                  class="
                    py-2
                    xl:py-4
                    px-4
                    xl:px-0
                    block
                    xl:table-cell
                    text-right
                    xl:text-center
                    border-b
                    xl:border-none
                    min-h-[42px]
                    before:content-[attr(data-label)]
                    xl:before:content-none
                    before:float-left
                    before:font-bold
                    odd:bg-acura-gray-light
                    xl:odd:bg-transparent
                  "
                  :class="
                    column.name === 'emailAddress' || column.name === 'phoneNumber'
                    ? 'overflow-hidden text-ellipsis'
                    : ''
                  ">
                    <a
                    class="underline"
                    :title="row[column.name]"
                    v-if="
                      column.name === 'emailAddress'
                    "
                      :href="`mailto:${row[column.name]}`">
                      {{ row[column.name] }}</a>
                   <a
                      class="underline"
                      :title="formatPhoneNumber(row[column.name])"
                      v-if="column.name === 'phoneNumber'"
                      :href="`tel:${row[column.name]}`">
                      {{ formatPhoneNumber(row[column.name]) }}</a>
                  <span
                    v-if="
                      column.name !== 'emailAddress'
                      && column.name !== 'phoneNumber'
                    ">
                    {{
                      column.name === 'startTime' ? formatTime(row[column.name])
                      : column.name === 'sessionDate' ? formatDate2(row['startTime'])
                      : row[column.name]
                    }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
              </div>
            </template>
          </TheTabs>
        <a
          v-if="state.eventType && state.location && registrationLink"
          @keydown.enter="navigateRegistrationLink(registrationLink)"
          @click="navigateRegistrationLink(registrationLink)"
          target="_blank"
          class="underline cursor-pointer col-span-10 xl:col-span-3 mt-2 p-2"
        >
          Registration Link
        </a>
        </TheFieldset>
    </div>
  </div>
  <TheModal :isOpen="attendeeModal.opened" @handleClose="closeModal">
    <AttendeeEdit
      v-if="attendeeModal.opened"
      :token="props.token"
      :initial-state="attendeeModal.data"
      :event-type="attendeeModal.eventType"
      @closeModal="closeWithToast"/>
  </TheModal>
</template>
