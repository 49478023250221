import { trackPageView } from '@/utils/analytics';
import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import EventInformationView from '@/views/EventInformationView.vue';
import LocationView from '@/views/LocationView.vue';
import FaqsView from '@/views/FaqsView.vue';
import RegistrationView from '@/views/RegistrationView.vue';
import CancellationView from '@/views/CancellationView.vue';
import EventCheckInView from '@/views/EventCheckInView.vue';
import EventBadgePrintingView from '@/views/EventBadgePrintingView.vue';
import URLGeneratorView from '@/views/URLGeneratorView.vue';
import EventReportingView from '@/views/EventReportingView.vue';
import PostTestCompletedView from '@/views/PostTestCompletedView.vue';
import store from '@/store';
import { handleEventType, setDynamicColor } from '@/utils/eventType';
import Cookies from 'js-cookie';
import EventWaiverView from '@/views/EventWaiverView.vue';
import BlankLayout from '@/layouts/BlankLayout.vue';
import WaiverReviewView from '@/views/WaiverReviewView.vue';

const setEventType = (eventType) => {
  store.commit('user/setEventType', eventType);
  store.dispatch('locations/loadLocations', eventType);
};

const defaultMetaTitle = 'Evolution Experience';

const routes = [
  {
    path: '/:eventType?',
    name: 'home',
    component: HomeView,
    props: ({ query }) => ({ source: query.source }),
    meta: {
      title: `${defaultMetaTitle} | Homepage`,
      description: `${defaultMetaTitle} - Homepage`,
    },
  },
  {
    path: '/:eventType?/event-information',
    name: 'eventInformation',
    component: EventInformationView,
    props: ({ query }) => ({ source: query.source }),
    meta: {
      title: `${defaultMetaTitle} | Event Information`,
      description: `${defaultMetaTitle} - Event Information`,
    },
  },
  {
    path: '/:eventType?/event-waiver',
    name: 'event-waiver',
    component: EventWaiverView,
    meta: {
      title: `${defaultMetaTitle} | Event Waiver`,
      layout: BlankLayout,
    },
  },
  {
    path: '/:eventType?/location/:locationName',
    name: 'location',
    component: LocationView,
    props: ({ query, params }) => ({ locationName: params.locationName, source: query.source }),
    meta: {
      title: `${defaultMetaTitle}`,
    },
  },
  {
    path: '/:eventType?/faqs',
    name: 'faqs',
    component: FaqsView,
    props: ({ query }) => ({ source: query.source }),
    meta: {
      title: `${defaultMetaTitle} | FAQs`,
      description: `${defaultMetaTitle} - FAQs`,
    },
  },
  {
    path: '/:eventType?/registration',
    name: 'registration',
    component: RegistrationView,
    meta: {
      title: `${defaultMetaTitle} | Registration`,
      description: `${defaultMetaTitle} - Registration`,
    },
    props: ({ query }) => ({
      action: query.action,
      id: parseInt(query.id, 10),
      source: query.source,
      location: query.location,
    }),
  },
  {
    path: '/:eventType?/cancel-registration/:id',
    name: 'cancelRegistration',
    component: CancellationView,
    meta: {
      title: `${defaultMetaTitle} | Cancel Registration`,
      description: `${defaultMetaTitle} - Cancel Registration`,
    },
    props: ({ query, params }) => ({ id: params.id, source: query.source }),
    beforeEnter: (to) => {
      if (!to.params.id) return { path: '/' };
      return true;
    },
  },
  {
    path: '/:eventType?/event-check-in',
    name: 'eventCheckIn',
    component: EventCheckInView,
    props: ({ query }) => ({ source: query.source }),
    meta: {
      title: `${defaultMetaTitle} | Event Check-in`,
    },
  },
  {
    path: '/:eventType?/waiver-review',
    name: 'waiverReview',
    component: WaiverReviewView,
    props: ({ query }) => ({ source: query.source }),
    meta: {
      title: `${defaultMetaTitle} | Waiver Review`,
      layout: BlankLayout,
    },
  },
  {
    path: '/:eventType?/event-badge-printing',
    name: 'eventBadgePrinting',
    component: EventBadgePrintingView,
    props: ({ query }) => ({ source: query.source }),
    meta: {
      title: `${defaultMetaTitle} | Event Badge Printing`,
    },
  },
  {
    path: '/:eventType?/url-generator',
    name: 'urlGenerator',
    component: URLGeneratorView,
    props: ({ query }) => ({ source: query.source }),
    meta: {
      title: `${defaultMetaTitle} | URL Generator`,
    },
  },
  {
    path: '/:eventType?/event-reporting',
    name: 'eventReporting',
    component: EventReportingView,
    props: ({ query }) => ({ source: query.source }),
    meta: {
      title: `${defaultMetaTitle} | Event Reporting`,
    },
  },
  {
    path: '/:eventType?/post-test-completed',
    name: 'postTestCompleted',
    component: PostTestCompletedView,
    props: ({ query }) => ({ source: query.source }),
    meta: {
      title: `${defaultMetaTitle} | Post Test Completed`,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { top: 0 };
  },
});

router.beforeEach(async (to) => {
  const { name, path, query } = to;

  const eventType = handleEventType(to.params.eventType);
  const source = query.source || Cookies.get('experiencedrive_source');
  const customObj = {};

  if (source) customObj.source = source;
  if (name === 'location' || name === 'registration') customObj.location = to.params.locationName || to.query.location;
  customObj.eventType = eventType;
  trackPageView({ name: name || path, url: path }, customObj);
  document.title = to.meta.title || defaultMetaTitle;

  if (to.query.source) {
    Cookies.set('experiencedrive_source', to.query.source);
  }

  setDynamicColor(eventType);
  setEventType(eventType);
});

export default router;
