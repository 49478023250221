import apiManager from '@/api';
import { useToast } from 'vue-toastification';
import locationsContext from './locationsContext';

const toast = useToast();

const module = {
  namespaced: true,
  state: () => ({
    locations: [],
    datesAvailable: [],
    locationsAvailable: [],
    waiverLocations: [],
    locationsInvitee: [
      {
        inviteeTypeID: 0,
        inviteeTypeName: '',
        locations: [],
      },
    ],
  }),
  mutations: {
    setLocations(state, locations) {
      state.locations = locations;
    },
    setLocationsInvitee(state, invitees) {
      state.locationsInvitee = invitees;
    },
    setLocationsAvailable(state, locations) {
      state.locationsAvailable = locations;
    },
    setWaiverLocations(state, locations) {
      state.waiverLocations = locations;
    },
    setDatesAvailable(state, dates) {
      state.datesAvailable = dates;
    },
  },
  getters: {
    location: (state) => (locationName) => state.locations
      .find((location) => location.locationId === locationName),
    locationsInvitee: (state) => state.locationsInvitee,
    locationsAvailable: (state) => state.locationsAvailable,
    waiverLocationsAvailable: (state) => state.waiverLocations,
    datesAvailable: (state) => state.datesAvailable,
  },
  actions: {
    async loadAllLocations({ commit }) {
      const results = await apiManager.report.getFilterAll()
        .then((resp) => resp.data)
        .catch(() => toast.error('Sorry, can\'t get the Locations. Try later.'));

      const { data: inviteeTypes, dates: datesAvailable } = results;

      const { locations } = results;

      commit('setLocationsInvitee', inviteeTypes);
      commit('setLocationsAvailable', locations);
      commit('setDatesAvailable', datesAvailable);
    },
    async loadLocations({ commit }, eventType) {
      const { data: locations } = await apiManager.reservations.getLocations(eventType);

      commit('setLocations', locations.map((location) => (
        { ...location, ...locationsContext[location.locationId] }
      )));
    },
    async loadWaiverLocations({ commit }) {
      const { data } = await apiManager.reservations.getWaiverLocations();

      const parsedLocations = data.map((location) => (
        { name: location.city, value: location.locationId, activeEvent: location.activeEvent }
      ));

      commit('setWaiverLocations', parsedLocations);
    },
  },
};

export default module;
