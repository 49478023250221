<script setup>
import EventInformation from '@/components/eventInformation/EventInformation.vue';
import { computed } from 'vue';
import store from '@/store';
import filterActiveEventLocations from '@/utils/filterEventLocations';

const locations = computed(() => filterActiveEventLocations(store.state.locations.locations));
const eventType = computed(() => store.getters['user/eventType']);

</script>
<template>
  <event-information :locations="locations" :eventType="eventType" />
</template>
