<script setup>
const props = defineProps({
  name: { type: String, required: true },
  label: { type: String, required: true },
  value: { type: Boolean, required: false },
  errorMessage: { type: String, required: false },
});

const emit = defineEmits(['update:value', 'input']);

function onChange(e) {
  emit('update:value', e.target.checked);
  emit('input', e.target.checked);
}
</script>
<template>
  <label
    :for="`checkbox--${props.name}`"
    class="
      block
      relative
      pl-[30px]
      mb-2
      cursor-pointer
      select-none
      font-MyriadProRegular
      text-lg
      text-acura-gray-darker
    "
  >
    {{ props.label }}
    <input
      type="checkbox"
      :id="`checkbox--${props.name}`"
      :name="`checkbox--${props.name}`"
      :checked="props.value"
      ref="checkbox"
      @change="onChange"
      class="
        absolute
        opacity-0
      "
    />
    <span
      class="
        absolute
        h-[24px]
        w-[24px]
        top-0
        left-0
        bg-white
        border-2
        border-acura-gray-darker
        after:content-['']
        after:absolute
        after:hidden"
        :class="{
          'border-red-500': props.errorMessage
        }"
    ></span>
    <small
      v-if="props.errorMessage"
      class="text-acura-primary">
      {{ props.errorMessage }}
    </small>
  </label>
</template>
<style scoped>
input:hover ~ span {
  background: #F3F3F3;
}

input:focus ~ span {
  border: 2px solid #000;
}

input:checked ~ span:after {
  display: block;
}

span:after {
  left: 6px;
  top: 1px;
  width: 8px;
  height: 14px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
