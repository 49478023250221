<template>
  <nav
    class="
      h-[4.5rem]
      bg-white
      text-black
      font-AvenirMed
      text-xl
      lg:h-[6.25rem]
      lg:font-AvenirHeavy
      lg:text-xl
      lg:flex
      lg:justify-between
      lg:items-center
      lg:pr-10
      xl:pr-24
    "
  >
    <div
      class="
        h-full
        flex
        items-center
        justify-between
        relative
        z-50
        pl-4
        pr-2
        lg:pl-10
        xl:pl-24
      "
    >
      <button
        @click="
          closeNavDrawer();
          closeLocationsDropdown();
          handleMenuRoute('/');
        "
      >
        <img
          :alt="logoAttributes.alt"
          :src="logoAttributes.url"
          class="w-[10.625rem] lg:w-[14.375rem]"
        />
      </button>
      <button
        @click="toggleNavDrawer"
        class="w-8 h-8 pt-1 text-xl text-dynamic lg:hidden"
      >
        <scale-in-out-center>
          <font-awesome-icon v-if="!navDrawerOpen" key="1" :icon="faBars" />
          <font-awesome-icon v-else key="2" :icon="faTimes" />
        </scale-in-out-center>
      </button>
    </div>
    <ul
      :class="[
        navDrawerOpen ? 'top-[4.5rem]' : '-top-full',
        'absolute w-full bg-white text-black text-center text-xl uppercase',
            'tracking-[0.036em] transition-top duration-300 ease-in-out z-40',
            'lg:relative lg:w-auto lg:bg-transparent lg:text-left lg:top-auto',
            'lg:flex lg:space-x-16 lg:normal-case xl:space-x-24',
        navDrawerOpen ? 'flex flex-col justify-center items-center' : ''
      ]"
    >
      <li v-if="logoAttributes.eventInfo">
        <button
          :class="{
            'text-dynamic font-bold': $route.path.includes('/event-information')
          }"
          class="block py-6 lg:py-0 hover:text-dynamic"
          @click="closeNavDrawer(); handleMenuRoute('/event-information')"
        >
          Event Information
        </button>
      </li>
      <li
        class="lg:h-auto transition-maxHeight duration-300 ease-in-out"
        v-if="locations.length"
      >
        <button
          @click="toggleLocationsDropdown"
          :class="{
            'text-dynamic font-bold':
              $route.path.includes('/location') || locationsDropdownOpen,
            'hover-icon': true
          }"
          class="py-6 lg:normal-case lg:py-0 hover:text-dynamic"
          >Location<span :class="{ hidden: locations.length === 1 }">s</span>
          <font-awesome-icon
            :icon="faChevronRight"
            :class="{
              'rotate-90': locationsDropdownOpen
            }"
            class="
              text-sm
              transition-transform
              duration-300
              ml-2
            "
          />
        </button>
        <transition
          enter-active-class="transition-opacity duration-300 ease-in"
          enter-from-class="opacity-0"
          leave-active-class="transition-opacity duration-200 ease-out"
          leave-to-class="opacity-0"
        >
          <div
            v-show="locationsDropdownOpen"
            @click="handleDropdownClick"
            :aria-hidden="true"
            :class="logoAttributes.eventInfo ?
              'lg:translate-x-[11.25rem] xl:translate-x-[13.5rem]' : 'lg:translate-x-[-3rem]'"
            class="
              bg-white
              flex flex-col
              space-y-6
              font-AvenirMed
              text-xl
              px-4
              pb-6
              lg:text-lg
              lg:px-12
              lg:pt-6
              lg:pb-7
              lg:absolute
              lg:top-0
              lg:left-0
              lg:translate-y-[4.1rem]
              lg:border
              lg:border-[#E5E5E5]
              lg:border-t-0
            "
          >
            <button
              v-for="location in props.locations"
              :key="location.locationId"
              active-class="text-dynamic font-bold"
              @click="
                closeLocationsDropdown();
                closeNavDrawer();
                handleMenuRoute(`/location/${location.locationId}`)
              "
              class="hover:text-dynamic"
              >{{ location.city }}</button
            >
          </div>
        </transition>
      </li>
      <li>
        <button
          active-class="text-dynamic font-bold"
          @click="
            closeNavDrawer();
            handleMenuRoute('/faqs');
          "
          :class="{'text-dynamic font-bold': $route.path.includes('/faqs')}"
          class="block py-6 lg:py-0 hover:text-dynamic"
          >FAQs</button
        >
      </li>
    </ul>
  </nav>
</template>

<script setup>
import { ref, computed } from 'vue';
import { faBars, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import ScaleInOutCenter from '@/components/transitions/ScaleInOutCenter.vue';
import store from '@/store';
import router from '@/router';
import { handleRouteWithEvent } from '@/utils/eventType';

import evolutionLogo from '@/assets/img/evolution-logo.svg';
import acuraLogo from '@/assets/img/acura-logo.svg';
import hondaLogo from '@/assets/img/honda-logo.svg';

const eventType = computed(() => store.getters['user/eventType']);

const eventTypeMappings = {
  consumer: {
    url: evolutionLogo,
    alt: 'Evolution Logo',
    eventInfo: true,
  },
  acuratraining: {
    url: acuraLogo,
    alt: 'Acura Logo',
    eventInfo: true,
  },
  hondatraining: {
    url: hondaLogo,
    alt: 'Honda Logo',
    eventInfo: true,
  },
  zdxdrive: {
    url: acuraLogo,
    alt: 'Acura Logo',
    eventInfo: false,
  },
  prologuedrive: {
    url: hondaLogo,
    alt: 'Honda Logo',
    eventInfo: false,
  },
  default: {
    url: evolutionLogo,
    alt: 'Evolution Logo',
    eventInfo: true,
  },
};

const logoAttributes = computed(() => (
  eventTypeMappings[eventType.value] || eventTypeMappings.default
));

const props = defineProps({
  locations: Array,
});

const navDrawerOpen = ref(false);
const locationsDropdownOpen = ref(false);

const clickOutsideNav = () => {
  // eslint-disable-next-line
  toggleLocationsDropdown();
};

const handleMenuRoute = (path) => {
  const routeWithEvent = handleRouteWithEvent(eventType.value, path);
  window.removeEventListener('click', clickOutsideNav);
  router.push(routeWithEvent);
};

const handleDropdownClick = (e) => {
  e.stopPropagation();
};

const toggleBodyClass = () => {
  const el = document.body;
  if (navDrawerOpen.value === true) {
    el.classList.add('overflow-hidden');
  } else {
    el.classList.remove('overflow-hidden');
  }
};

const toggleNavDrawer = () => {
  navDrawerOpen.value = !navDrawerOpen.value;
  toggleBodyClass();
};

const closeNavDrawer = () => {
  navDrawerOpen.value = false;
  toggleBodyClass();
};

const toggleLocationsDropdown = () => {
  locationsDropdownOpen.value = !locationsDropdownOpen.value;
  if (locationsDropdownOpen.value) {
    setTimeout(() => {
      window.addEventListener('click', clickOutsideNav);
    });
  } else {
    window.removeEventListener('click', clickOutsideNav);
  }
};

const closeLocationsDropdown = () => {
  locationsDropdownOpen.value = false;
};
</script>
<style scoped>
  .hover-icon:hover .faChevronRight,
  .hover-icon:focus .faChevronRight {
      color: var(--dynamic-color);
  }
</style>
