<template>
  <footer class="bg-black text-white">
  <section class="container mx-auto lg:px-24 py-10">
    <div class="flex justify-between md:items-center flex-col md:flex-row">
      <div
        class="
          lg:basis-[50%]
          xl:basis-[60%]
          2xl:basis-[70%]
          xl:flex
          lg:items-center
        "
      >
        <div
          class="
            xl:border-r xl:border-acura-gray-medium
            px-6
            lg:px-0 lg:py-10 lg:pr-10
          "
        >
        <img
          :alt="logoAttributes.alt"
          :src="logoAttributes.logo"
          :class="{ 'invert': logoAttributes.invert }"
          @click="navigateTo('/')"
          @keydown.enter="navigateTo('/')"
          class="w-[12.5rem] lg:w-[17.8125rem] cursor-pointer"
        />
        </div>
        <div class="py-10 lg:py-0 pl-6 lg:pl-0 xl:pl-10">
          <h2 class="font-AvenirHeavy text-2xl mb-2">Support</h2>
          <p class="text-base lg:text-2xl">
            Email us at
            <a
              href="
                mailto:info@evolution-experience.com?subject=Evolution Experience Program Inquiry
              "
              @click="trackLink('email')"
              class="underline hover:text-acura-gray-dark"
            >
              info@evolution-experience.com
            </a>
          </p>
        </div>
      </div>
      <div
        class="
          lg:basis-[50%]
          xl:basis-[40%]
          2xl:basis-[30%]
          lg:flex lg:justify-between
        "
      >
        <div
          class="
            py-10
            lg:py-0
            pl-6
            lg:pl-0 lg:border-0
            border-t md:border-t-0 border-acura-gray-medium
          "
        >
          <h2 class="font-AvenirHeavy text-2xl mb-6 lg:mb-0">Resources</h2>
          <ul
            class="
              text-lg
              leading-loose
            "
          >
            <li>
              <button @click="navigateTo('/')" class="hover:text-acura-gray-dark">
                Home
              </button>
            </li>
            <li v-if="eventTypeMappings[eventType].eventInfo">
              <button
                @click="navigateTo('/event-information')"
                class="hover:text-acura-gray-dark"
              >
                Event Information
              </button>
            </li>
            <li>
              <button @click="navigateTo('/faqs')" class="hover:text-acura-gray-dark">
                FAQs
              </button>
            </li>
            <!-- <li>
              <a
                :href="`https://${logoAttributes.url2}`"
                target="_blank"
                class="hover:text-acura-gray-dark"
                @click="trackLink(logoAttributes.url2)"
              >
                {{ logoAttributes.url2 }}
              </a>
            </li> -->
            <li :key="link" v-for="link in logoAttributes.urls">
              <a
                :href="`https://${link}`"
                target="_blank"
                class="hover:text-acura-gray-dark"
                @click="trackLink(link)"
              >
                {{ link }}
              </a>
            </li>
          </ul>
        </div>
        <div
          class="
            py-10
            lg:py-0
            pl-6
            lg:pl-0 lg:border-0
            border-t md:border-t-0 border-acura-gray-medium
            basis-[56%]
          "
          v-if="props.locations.length"
        >
          <h2 class="font-AvenirHeavy text-2xl mb-6 lg:mb-0">
            Location<span :class="{ hidden: props.locations.length === 1 }">s</span>
          </h2>
          <ul
            class="
              grid grid-cols-2
              lg:grid-cols-1
              grid-rows-3 grid-flow-col
              lg:gap-x-4
              text-lg
              leading-loose
            "
          >
            <li v-for="location in props.locations" :key="location.locationId">
              <button
              @click="navigateTo(`/location/${location.locationId}`)"
                class="hover:text-acura-gray-dark"
              >
                {{ location.linkName }}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="
        pt-10
        lg:pt-0
        pl-6
        lg:pl-0
        border-t border-dynamic
        lg:mt-14
      "
    >
      <ul
        class="
          grid md:grid-cols-2
          lg:grid-cols-1
          grid-rows-2
          lg:grid-rows-1
          grid-flow-col
          gap-y-6
          lg:gap-y-4 lg:gap-x-4 lg:mt-4
          text-xs
          lg:text-sm
        "
      >
        <li><p class="order-1">© 2024 Jackson Dawson</p></li>
        <li class="order-2">
          <a
            href="https://www.honda.com/privacy/privacy-notice"
            target="_blank"
            class="hover:text-acura-gray-dark"
            @click="trackLink('PrivacyPolicy')"
          >
            Privacy Notice
          </a>
          <span class="text-[#707070] text-xl mx-6">|</span>
          <a
            href="https://www.honda.com/privacy/terms-and-conditions"
            target="_blank"
            class="hover:text-acura-gray-dark whitespace-nowrap"
            @click="trackLink('TermsAndConditions')"
          >
            Terms and Conditions
          </a>
        </li>
      </ul>
    </div>
  </section>
  </footer>
</template>
<script setup>
import { defineProps, computed, watchEffect } from 'vue';
import { handleRouteWithEvent } from '@/utils/eventType';
import router from '@/router';
import store from '@/store';

import evolutionLogo from '@/assets/img/evolution-logo-2.svg';
import acuraLogo from '@/assets/img/acura-logo.svg';
import hondaLogo from '@/assets/img/honda-logo.svg';
import { trackCustomEvent } from '@/utils/analytics';
import { useRoute } from 'vue-router';

const route = useRoute();

const eventType = computed(() => store.getters['user/eventType']);

const eventTypeMappings = {
  consumer: {
    logo: evolutionLogo,
    urls: ['Acura.com', 'automobiles.Honda.com'],
    alt: 'Evolution Logo',
    color: '#4391D1',
    eventInfo: true,
  },
  acuratraining: {
    logo: acuraLogo,
    urls: ['Acura.com'],
    alt: 'Acura Logo',
    color: '#E82C2A',
    invert: true,
    eventInfo: true,
  },
  hondatraining: {
    logo: hondaLogo,
    urls: ['automobiles.Honda.com'],
    alt: 'Honda Logo',
    color: '#1F61A9',
    invert: true,
    eventInfo: true,
  },
  zdxdrive: {
    logo: acuraLogo,
    urls: ['Acura.com'],
    alt: 'Acura Logo',
    color: '#E82C2A',
    invert: true,
  },
  prologuedrive: {
    logo: hondaLogo,
    urls: ['automobiles.Honda.com'],
    alt: 'Honda Logo',
    color: '#1F61A9',
    invert: true,
  },
  default: {
    logo: evolutionLogo,
    urls: ['automobiles.Honda.com', 'Acura.com'],
    alt: 'Evolution Logo',
    color: '#4391D1',
    eventInfo: true,
  },
};

const logoAttributes = computed(() => (
  eventTypeMappings[eventType.value] || eventTypeMappings.default
));

const setCSSVariables = () => {
  document.documentElement.style.setProperty('--dynamic-color', logoAttributes.value.color);
};

const props = defineProps({
  locations: Array,
});

const navigateTo = (path) => {
  const routeWithEvent = handleRouteWithEvent(eventType.value, path);
  router.push(routeWithEvent);
};

const trackLink = (linkName) => {
  const pageName = route.name || route.path;

  trackCustomEvent(`FooterClick:${linkName}`, eventType.value, pageName);
};

watchEffect(() => {
  setCSSVariables();
});
</script>
