const module = {
  namespaced: true,
  state: () => ({
    eventType: 'consumer',
    sessionUIC: '',
  }),
  mutations: {
    setEventType(state, eventType) {
      state.eventType = eventType;
    },
    setSessionUIC(state, uic) {
      state.sessionUIC = uic;
    },
  },
  getters: {
    eventType: (state) => state.eventType,
    sessionUIC: (state) => state.sessionUIC,
  },
  actions: {
  },
};

export default module;
