<template>
  <button @click="navigateTo()" :aria-label="props.ariaText" class="group">
    <div
      class="location-card h-full bg-no-repeat"
      :class="`${props.img} ${props.customClass}`"
    >
      <div v-if="hasShadow" class="w-full
        rounded-br-[30px]
        rounded-bl-[30px]
        max-w-[1812px]
        h-[50%]
        top-[50%]
        pb-6
        bg-gradient-to-t
        from-black absolute"></div>
      <div class="card-wrapper">
        <font-awesome-icon :icon="faArrowRight" size="lg" :aria-label="props.ariaText" />
      </div>
      <div
        class="
        w-full
        h-[50%]
        top-[50%]
        pb-6
        bg-gradient-to-t
        from-black
        rounded-bl-[30px]
        rounded-br-[30px]
        absolute
        flex
        flex-col
        justify-end"
        :class="props.isOne ? 'text-left pl-5 md:pl-10' : ''"
      >
        <h2
          :class="
            props.isOne
              ? 'text-xl md:text-2xl lg:text-3xl xl:text-6xl'
              : 'text-xl md:text-2xl lg:text-3xl font-bold tracking-widest mb-2'
          "
          class="font-MyriadProSemiBold text-white uppercase"
        >
          <slot name="title"></slot>
        </h2>
        <p
          :class="
            props.isOne
            ? 'text-sm lg:text-1xl xl:text-2xl font-MyriadProBold mt-3'
            : 'text-sm lg:text-1xl font-MyriadProBold'
          "
          class="text-white uppercase"
        >
          <slot name="footer"></slot>
        </p>
      </div>
    </div>
  </button>
</template>
<style scoped>
.location-card {
  box-shadow: 0px 3px 6px #0000006a;
  @apply w-full
      bg-black
      mb-[1px]
      relative
      flex
      flex-col
      justify-between;
}

.card-wrapper {
  @apply
    flex
    items-center
    justify-center
    self-end
    my-5
    w-[40px]
    h-[41px]
    md:w-[60px]
    md:h-[61px]
    mx-5
    rounded-[50%]
    bg-hover-card
    bg-position-100
    bg-size-200-100
    ease-in-out
    duration-150
    group-hover:bg-position-0-100
    text-black
    group-hover:text-white;
}
img,
.location-card {
  @apply rounded-[1.875rem];
}
</style>
<script setup>
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';

const props = defineProps({
  img: { type: String, required: true },
  customClass: { type: String, required: false },
  altText: { type: String, required: true },
  ariaText: { type: String, required: true },
  isOne: { type: Boolean, required: false, default: false },
  hasShadow: { type: Boolean, required: false, default: false },
});

const emit = defineEmits(['clicked']);

const navigateTo = () => {
  emit('clicked');
};

</script>
