<script setup>
// Images
import PrologueRear from '@/assets/img/event-characteristics/prologue-rear.png';
import ZdxHeadlights from '@/assets/img/event-characteristics/zdx-headlight.png';
import ZdxRear from '@/assets/img/event-characteristics/acuratraining/zdx-rear.png';
import ZdxFront from '@/assets/img/event-characteristics/acuratraining/zdx-front.png';
import ZdxInterior from '@/assets/img/event-characteristics/acuratraining/zdx-interior.png';
import PrologueFront from '@/assets/img/event-characteristics/hondatraining/prologue-front.png';
import PrologueInterior from '@/assets/img/event-characteristics/hondatraining/prologue-interior.png';
import PrologueCharging from '@/assets/img/event-characteristics/hondatraining/prologue-charging.png';

// Components
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import CardWithTitle from '@/components/global/CardWithTitle/CardWithTitle.vue';
import IconSection from '@/components/eventInformation/IconSection.vue';
import HeroHeader from '@/components/global/HeroHeader.vue';
import { trackCustomEvent } from '@/utils/analytics';
import router from '@/router';
import store from '@/store';
import { handleRouteWithEvent } from '@/utils/eventType';
import { computed } from 'vue';
import { useMeta } from 'vue-meta';
import { useRoute } from 'vue-router';
import iconSections from './iconSections';

const vehicles = {
  consumer: [
    {
      title: 'Acura ZDX',
      altText: 'Blue new ZDX parked with the headlights on',
      ariaText: 'Link with Acura ZDX Name and a picture of it that will navigate to Acura ZDX Website',
      url: 'https://www.acura.com/suvs/zdx',
      bgClass: 'bg-zdx-featured bg-center bg-[length:104%_auto]',
    },
    {
      title: 'Honda Prologue',
      altText: 'Gray Prologue parked near by a bridge and a lake and two guys taking its bikes from the bike rack on the car`s roof',
      ariaText: 'Link with Honda Prologue Name and a picture of it that will navigate to Honda Prologue Website',
      url: 'https://automobiles.honda.com/future-cars/prologue',
      bgClass: 'bg-prologue-featured bg-center bg-[length:104%_auto]',
    },
  ],
  acuratraining: [
    {
      title: 'Acura ZDX',
      altText: 'Blue new ZDX parked with the headlights on',
      ariaText: 'Link with Acura ZDX Name and a picture of it that will navigate to Acura ZDX Website',
      url: 'https://www.acura.com/suvs/zdx',
      bgClass: 'bg-zdx-featured md:bg-zdx-wide-featured md:!bg-contain bg-[length:104%_auto] bg-[position:-0.375rem_-0.25rem] md:bg-[position:center] xl:!bg-[length:auto_104%]',
    },
  ],
  hondatraining: [
    {
      title: 'Honda Prologue',
      altText: 'Blue new Prologue running into an off-road street',
      ariaText: 'Link with Honda Prologue Name and a picture of it that will navigate to Honda Prologue Website',
      url: 'https://automobiles.honda.com/future-cars/prologue',
      bgClass: 'bg-prologue-offroad-featured-mobile md:bg-prologue-offroad-featured bg-[length:100%_auto] bg-[position:0rem_0] md:bg-[position:center] bg-contain md:!bg-auto',
    },
  ],
};

const features = {
  consumer: [
    {
      image: ZdxHeadlights,
      altText: 'Blue Acura ZDX Front with the headlight on',
      title: 'EVenture Closed Courses',
      description: 'Experience the acceleration and agility of the all-new ZDX and Prologue on separate autocross courses. Learn from our team of professional driving coaches - then relish the fun-to-drive characteristics of these beautiful crossovers.',
    },
    {
      image: PrologueRear,
      altText: 'Blue Prologue rear on an empty road',
      title: 'EVenture Focused Street Drive',
      description: 'This dynamic introduction to the EV ECOsystem gives you a taste of real world driving with real world technologies such as Google built-in, one pedal driving and more!',
    },
  ],
  acuratraining: [
    {
      image: ZdxFront,
      altText: 'Image of the front of an Orange ZDX inside an all-orange room',
      title: '<b class="text-dynamic">EV</b>enture',
      description: `
        Experience the acceleration and agility of the all-new ZDX on a
        combined autocross and street drive course. Learn from our team
        of professional driving coaches - then relish the fun-to-drive
        characteristics of this beautiful crossover.
      `,
    },
    {
      image: ZdxInterior,
      altText: 'Modern interior of the new-ZDX showing white seats and a few blue lights',
      title: '<b class="text-dynamic">EV</b>olve',
      description: `
      Take a deep dive into the ZDX with hands-on, in-vehicle learning.
      Develop a thorough understanding of the vehicle by operating the
      controls and screens so that you’re confident in demonstrating
      features to your customers.
      `,
    },
    {
      image: ZdxRear,
      altText: 'Rear of Blue ZDX in front of a glass building',
      title: '<b class="text-dynamic">EV</b>eryday',
      description: 'Learn how to communicate the EV ownership experience by looking at EVs through a day in the life of a customer.',
    },
  ],
  hondatraining: [
    {
      image: PrologueFront,
      altText: 'Front of a Blue Prologue parked on the street',
      title: '<b class="text-dynamic">EV</b>enture',
      description: 'Experience the acceleration and agility of the all-new Prologue on a combined autocross and street drive course. Learn from our team of professional driving coaches - then relish the fun-to-drive characteristics of this beautiful crossover.',
    },
    {
      image: PrologueInterior,
      altText: 'Modern interior of the new-Prologue',
      title: '<b class="text-dynamic">EV</b>olve',
      description: 'Take a deep dive into the Prologue with hands-on, in-vehicle learning. Develop a thorough understanding of the vehicle by operating the controls and screens so that you’re confident in demonstrating features to your customers.',
    },
    {
      image: PrologueCharging,
      altText: 'Woman putting the plug of its charging to the Prologue',
      title: '<b class="text-dynamic">EV</b>eryday',
      description: 'Learn how to communicate the EV ownership experience by looking at EVs through a day in the life of a customer.',
    },
  ],
};

const titleText = {
  consumer: 'A limited-time immersive drive experience with the all-new Acura ZDX and Honda Prologue',
  acuratraining: 'A limited-time immersive training experience with the all-new Acura ZDX',
  hondatraining: 'A limited-time immersive training experience with the all-new Honda Prologue',
};

const route = useRoute();

useMeta({
  meta: [
    { name: 'description', content: route.meta.description },
    { 'http-equiv': 'Content-Type', content: 'text/html;charset=utf-8' },
  ],
});

const props = defineProps({
  locations: Array,
  eventType: String,
});

const eventType = computed(() => store.getters['user/eventType']);

const icons = computed(() => iconSections[props.eventType]);

const navigateWithEvent = (path) => {
  const routeWithEvent = handleRouteWithEvent(eventType.value, path);
  router.push(routeWithEvent);
};

const handlePluralSingularWord = (word, condition) => {
  const parsedWord = condition ? `${word}s` : word;
  return parsedWord;
};

const clickedFeatured = (vehicle) => {
  trackCustomEvent('EventInformation:LearnMore', eventType.value, 'eventInformation', { action: 'LearnMoreButton', actionValue: vehicle.title });
  window.open(vehicle.url, '_blank');
};
</script>

<template>
  <div
    class="lg:pb-[10rem] lg:bg-auto px-5"
  >
    <hero-header
      class="h-[100px] lg:h-[442px]"
      bgClass="bg-page-header"
      title="Event Information" />
    <div>
      <p
        class="
          text-[2rem]
          lg:text-[4.0625rem]
          font-MyriadProLight
          leading-none
          text-center
          max-w-[88.75rem]
          mx-auto
          mt-11
          mb-16
        "
      >
        {{
          titleText[eventType]
        }}
      </p>

      <icon-section :icons="icons" />

      <p
        class="
          font-MyriadProRegular
          text-[1.75rem]
          lg:text-[2.875rem]
          text-center
          pb-12
          lg:pb-16
        "
      >
        We can’t wait to share our exciting new EVs with you!
      </p>

      <template v-if="locations.length">
      <p
        class="
          text-[1.25rem]
          lg:text-[1.5rem]
          font-MyriadProBold
          text-center
          mb-8
        "
      >
      {{ eventType === 'consumer'
      ? `THERE IS LIMITED AVAILABILITY FOR ${locations.length > 1 ? 'EACH' : 'THIS'} EVENT,
      SO CLAIM YOUR SPOT TODAY!`
      : 'CLAIM YOUR SPOT TODAY! THERE IS LIMITED AVAILABILITY.' }}
      </p>

      <nav
        aria-label="locations"
        class="
          flex flex-col
          justify-center
          lg:flex-row
          gap-6
          mb-10
        "
      >
        <template v-for="location in locations" :key="location.locationId">
          <button
            class="
              text-dynamic text-[1.375rem]
              font-MyriadProBold
              font-bold
              h-12
              text-center
              flex flex-col
              justify-center
              hover:underline
              focus:underline
              uppercase
            "
            @click="navigateWithEvent(`/location/${location.locationId}`)"
            :aria-label="`Link to location page for ${location.city}`"
          >
            <div>
              <span class="mr-4">{{ location.city }}</span>
              <span class="text-black"><font-awesome-icon :icon="faChevronRight"/></span>
            </div>
          </button>
          <div class="w-0 lg:border-r-2 last-of-type:border-r-0"></div>
        </template>
      </nav>
    </template>

      <h2
        class="
          text-[2rem]
          lg:text-[3.5rem]
          font-MyriadProLight
          tracking-[2.02px]
          text-center
          mb-[1.25rem]
          lg:mb-10
        "
      >
        {{handlePluralSingularWord('Featured Vehicle', vehicles[eventType].length > 1)}}
      </h2>

      <div
        class="
          max-w-[1692px]
          mx-auto
          flex flex-col
          lg:flex-row
          w-full
          gap-4
          drop-shadow-lg
          mb-16
          lg:mb-32
        "
      >
        <CardWithTitle
          v-for="vehicle in vehicles[eventType]"
          v-bind="vehicle"
          :key="vehicle.title"
          :img="vehicle.bgClass"
          :alt-text="vehicle.altText"
          :isOne="vehicles[eventType].length === 1"
          class="w-full h-[15rem] sm:h-[20rem] md:h-[30rem] xl:h-[36.375rem]"
          :class="vehicles[eventType] > 1 && 'lg:w-1/2'"
          hasShadow
          @clicked="clickedFeatured(vehicle)">
              <template v-slot:title>
                <div class="font-MyriadProBold text-[2rem] lg:text-[4rem] lg:mb-2 leading-none">
                  {{ vehicle.title }}
                </div>
              </template>

              <template v-slot:footer>
                <a
                  :href="url"
                  :aria-label="`Learn more about the ${title} (opens new window)`"
                  class="
                  font-MyriadProBold
                  text-base
                  lg:text-[1.625rem]
                  uppercase
                  group-hover:underline"
                  target="_blank"
                ><span class="mr-6">Learn More</span>
                </a>
              </template>
          </CardWithTitle>
      </div>

      <h2
        class="
          text-[2rem]
          lg:text-[3.5rem]
          font-MyriadProLight
          text-center
          mb-[1.25rem]
          lg:mb-10
        "
      >
        {{ eventType === 'consumer' ? 'Dynamic Drives' : 'Training Day' }}
      </h2>

      <div class="
        max-w-[1710px]
        mx-auto
        grid
        gap-16
        lg:gap-8
        2xl:gap-4
        pb-16"
        :class="features[eventType].length === 3 ? 'lg:grid-cols-3' : 'lg:grid-cols-2'">
        <div
          class="
          flex flex-col items-center
          "
          v-for="feature in features[eventType]"
          v-bind:key="feature.title"
        >
        <img loading="lazy" :src="feature.image" :alt="feature.altText" />
        <h2 class="text-4xl font-MyriadProRegular text-center mt-9" v-html="feature.title"></h2>
        <span class="
          text-lg
          font-MyriadProRegular
          tracking-[0.65px]
          lg:mx-10
          text-center
          leading-9
          mt-[1.675rem]"
          :class="features[eventType].length === 3 ? 'lg:mx-8' : 'lg:mx-10'">
          {{ feature.description }}
        </span>
        </div>

      </div>
    </div>
  </div>
</template>
