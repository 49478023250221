<template>
  <section class="overview-container">
    <div class="flex-1 md:pr-[5.275vw]">
      <h2 class="title">Overview</h2>
      <p class="description">{{ descriptionTexts[eventType] }}</p>
      <button class="hidden md:block font-AvenirLight font-bold"
        @click="navigateTo(isDealer)">
        {{isDealer ? 'Select your time & register' : 'Learn more'}}
      </button>
    </div>
    <div class="images-container">
      <!-- <img :src="require(image.url)" alt=""/> -->
      <template v-if="siteType === 'acura'">
        <img :src="ZDXFront" alt=""/>
        <img :src="ZDXBadge" alt=""/>
        <img :src="ZDXWheel" alt=""/>
        <img :src="ZDXRear" alt=""/>
      </template>

      <template v-else-if="siteType === 'honda'">
        <img :src="PrologueRear" alt=""/>
        <img :src="PrologueInterior" alt=""/>
        <img :src="PrologueBadge" alt=""/>
        <img :src="PrologueFront" alt=""/>
      </template>

      <template v-else>
        <img :src="ZDXFront" alt=""/>
        <img :src="ZDXBadge" alt=""/>
        <img :src="PrologueRear" alt=""/>
        <img :src="PrologueDriving" alt=""/>
      </template>
    </div>

    <button class="block md:hidden"
        @click="navigateTo(isDealer)">
        {{isDealer ? 'Select your time & register' : 'Learn more'}}
      </button>
  </section>
</template>

<style scoped lang="scss">
.overview-container {
  @apply
    bg-acura-gray-lighter
    flex
    flex-col
    md:flex-row
    rounded-[30px]
    px-[1.75rem]
    md:px-[3.7rem]
    py-[2rem]
    2xl:py-[6.25rem]
    items-center;
  .title {
    @apply uppercase text-4xl lg:text-6xl 2xl:text-[84px] font-MyriadProLight pb-[0.5em];
  }
  .description {
    letter-spacing: 0.86px;
    @apply text-base 2xl:text-2xl font-MyriadProRegular;
  }
  button {
    @apply uppercase
      mt-7
      px-11
      h-[4rem]
      rounded-[0.625rem]
      bg-dynamic
      text-white
      text-[1.25rem]
      font-AvenirHeavy;
  }
}

.images-container {
  @apply grid grid-cols-2 gap-[0.25em] md:gap-[0.75em];
  img {
    @apply rounded-[20px] md:rounded-[30px] w-[45vw] md:w-[26.25vw];
  }
}
</style>

<script setup>
import { computed } from 'vue';
import store from '@/store';
import { useRouter } from 'vue-router';

// Acura Images
import ZDXBadge from '@/assets/img/overview-section/acura/zdx-badge.png';
import ZDXFront from '@/assets/img/overview-section/acura/zdx-front.png';
import ZDXRear from '@/assets/img/overview-section/acura/zdx-rear.png';
import ZDXWheel from '@/assets/img/overview-section/acura/zdx-wheel.png';

// Honda Images
import PrologueBadge from '@/assets/img/overview-section/honda/prologue-badge.png';
import PrologueFront from '@/assets/img/overview-section/honda/prologue-front.png';
import PrologueRear from '@/assets/img/overview-section/honda/prologue-rear.png';
import PrologueInterior from '@/assets/img/overview-section/honda/prologue-interior.png';
import PrologueDriving from '@/assets/img/overview-section/honda/prologue-driving.png';

import { handleRouteWithEvent } from '@/utils/eventType';

const router = useRouter();

const eventType = computed(() => store.getters['user/eventType']);
const siteType = computed(() => {
  if (eventType.value === 'acuratraining' || eventType.value === 'zdxdrive') {
    return 'acura';
  }
  if (eventType.value === 'hondatraining' || eventType.value === 'prologuedrive') {
    return 'honda';
  }
  return 'consumer';
});

const isDealer = computed(() => eventType.value === 'zdxdrive' || eventType.value === 'prologuedrive');

const descriptionTexts = {
  consumer:
    'Seize a unique opportunity to experience the all-new, all-electric Acura ZDX and Honda Prologue in a hands on driving event coming to six cities during the first half of 2024!',
  acuratraining: 'The ZDX EVolution Drive offers an immersive training environment to provide the knowledge and experience needed to craft compelling customer stories.',
  hondatraining: 'The Prologue EVolution Drive offers an immersive training environment to provide the knowledge and experience needed to craft compelling customer stories.',
  zdxdrive: 'Be one of the first to drive the all-new ZDX! This introductory drive begins with a brief shuttle to a nearby dynamic closed course. There you will have the chance to take some exciting laps to get a taste of the fun-to-drive characteristics of the ZDX!',
  prologuedrive: 'Be one of the first to drive the all-new Prologue! This introductory drive begins with a brief shuttle to a nearby dynamic closed course. There you will have the chance to take some exciting laps to get a taste of the fun-to-drive characteristics of the Prologue!',
};

const navigateTo = (redirectToLocation) => {
  const routeWithEvent = handleRouteWithEvent(eventType.value, redirectToLocation ? '/location/las-vegas' : '/event-information');
  router.push(routeWithEvent);
};
</script>
