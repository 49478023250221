<template>
  <div class="section-bg">
    <template v-if="eventType === 'consumer'">
      <img src="@/assets/img/home-header/consumer.png" alt="" />
    </template>
    <template v-else-if="eventType === 'acuratraining' || eventType === 'zdxdrive'">
      <img src="@/assets/img/home-header/acuratraining.png" alt="" />
    </template>
    <template v-else>
      <img src="@/assets/img/home-header/hondatraining.png" alt="" />
    </template>
    <template class="md:block">
      <div class="blur-bg absolute"
      :class="eventType === 'consumer'
        ? 'bottom-[1.5rem] md:bottom-10'
        : 'bottom-[1.5rem] xl:bottom-3 2xl:bottom-10'"></div>
    <section
      class="text-container absolute"
      :class="eventType === 'consumer'
        ? 'bg-black/75 bottom-[1.5rem] md:bottom-10'
        : 'bg-black/50 bottom-[1.5rem] xl:bottom-3 2xl:bottom-10'"
    >
      <template v-if="handleTextsByEventType().title && handleTextsByEventType().paragraph">
        <h2>{{ handleTextsByEventType().title }}</h2>
        <p>{{ handleTextsByEventType().paragraph }}</p>
      </template>
      <template v-else>
        <h3>{{ handleTextsByEventType().title }}</h3>
      </template>
    </section>
    </template>
  </div>
  <template class="block md:hidden">
    <section
      class="text-container !w-full mt-2"
      :class="eventType === 'consumer' ? 'bg-black/75' : 'bg-black/50'"

    >
      <template v-if="handleTextsByEventType().title && handleTextsByEventType().paragraph">
        <h2>{{ handleTextsByEventType().title }}</h2>
        <p>{{ handleTextsByEventType().paragraph }}</p>
      </template>
      <template v-else>
        <h3>{{ handleTextsByEventType().title }}</h3>
      </template>
    </section>
    </template>
</template>
<script setup>
import store from '@/store';
import { computed } from 'vue';

const eventType = computed(() => store.getters['user/eventType']);

const texts = {
  consumer: {
    title:
      'The arrival of the Acura ZDX and Honda Prologue signal the EVolution: The Electric Experience!',
  },
  acura: {
    title: 'The future of Acura is here!',
    paragraph: 'The ZDX takes Precision Crafted Performance into the electrified future!',
  },
  honda: {
    title: 'The future of Honda is here!',
    paragraph: 'The Prologue signals the Honda EVolution: The Electric Experience!',
  },
};

const handleTextsByEventType = () => {
  if (eventType.value === 'acuratraining' || eventType.value === 'zdxdrive') {
    return texts.acura;
  }
  if (eventType.value === 'hondatraining' || eventType.value === 'prologuedrive') {
    return texts.honda;
  }
  return texts.consumer;
};
</script>

<style scoped lang="scss">
.section-bg {
  @apply relative;
}
.blur-bg,
.text-container {
  @apply
    backdrop-blur
    h-[5rem]
    md:h-[10vw]
    xl:h-[130px]
    2xl:h-[237px]
    max-h-[237px]
    w-[93%]
    left-[3.5%]
    rounded-lg
    md:rounded-[20px];
}
.blur-bg {
  @apply blur-3xl;
}
.text-container {
  @apply
    text-white
    flex
    flex-col
    justify-center
    items-center
    border-[1px]
    border-[#FFF]
    font-MyriadProRegular;
}
.text-container h2 {
  @apply
  px-4
  md:px-0
  text-[14px]
  leading-normal
  lg:text-[28px]
  xl:text-[40px]
  2xl:text-[48px]
  tracking-[1.44px]
  text-center;
}
.text-container p {
  @apply
  px-4
  md:px-0
  text-[8px]
  leading-normal
  text-center
  lg:text-[20px]
  xl:text-[26px]
  2xl:text-[36px]
  tracking-[1.44px];
}
.text-container h3 {
  letter-spacing: 1.98px;
  @apply
    text-[.5rem]
    leading-normal
    px-4
    md:px-0
    md:text-base
    md:leading-[2rem]
    xl:leading-[4rem]
    xl:text-[32px]
    text-center
    md:px-[110px]
    2xl:px-[175px]
    2xl:text-[55px];
}
</style>
