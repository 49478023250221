import imageNewYork from '@/assets/img/locations/ny-still.png';
import imageChicago from '@/assets/img/locations/chicago-still.png';
import imageMiami from '@/assets/img/locations/miami-still.png';
import imageDallas from '@/assets/img/locations/dallas-still.png';
import imageLasVegas from '@/assets/img/locations/las-vegas-still.png';
import imageLosAngeles from '@/assets/img/locations/la-still.png';
import imageSanFrancisco from '@/assets/img/locations/san-francisco-still.png';

const locations = {
  // Los Angeles, CA
  1: {
    id: 1,
    mapSrc: `https://www.google.com/maps/embed/v1/place?key=${process.env.VUE_APP_GOOGLE_API_KEY}
    &q=American+Honda+Motor+Co.+Inc.+Torrance,+CA`,
    locationId: 'los-angeles',
    linkName: 'Los Angeles',
    heroBg: 'bg-los-angeles-hero',
    cardBg: 'bg-los-angeles-card',
    img: imageLosAngeles,
    path: '/location/los-angeles',
    altText: 'aerial view of the Los Angeles city center',
    ariaText: 'Los Angeles location page',
  },
  // San Francisco, CA
  2: {
    id: 2,
    mapSrc: `https://www.google.com/maps/embed/v1/place?key=${process.env.VUE_APP_GOOGLE_API_KEY}
    &q=Alameda+Point+Gymnasium`,
    locationId: 'san-francisco',
    linkName: 'San Francisco',
    heroBg: 'bg-san-francisco-hero',
    cardBg: 'bg-san-francisco-card',
    img: imageSanFrancisco,
    path: '/location/san-francisco',
    altText: 'aerial view of the San Francisco city center',
    ariaText: 'San Francisco location page',
  },
  // New York, NY
  3: {
    id: 3,
    mapSrc: `https://www.google.com/maps/embed/v1/place?key=${process.env.VUE_APP_GOOGLE_API_KEY}
    &q=Metlife+stadium,East+rutherford,New+Jersey`,
    locationId: 'new-york',
    linkName: 'New York',
    heroBg: 'bg-new-york-hero',
    cardBg: 'bg-new-york-card',
    img: imageNewYork,
    path: '/location/new-york',
    altText: 'aerial view of the New York city center',
    ariaText: 'New York location page',
  },
  // Dallas, TX
  4: {
    id: 4,
    mapSrc: `https://www.google.com/maps/embed/v1/place?key=${process.env.VUE_APP_GOOGLE_API_KEY}
    &q=ATT+stadium,Arlington+Tx`,
    locationId: 'dallas',
    linkName: 'Dallas',
    heroBg: 'bg-dallas-hero',
    cardBg: 'bg-dallas-card',
    img: imageDallas,
    path: '/location/dallas',
    altText: 'aerial view of the Dallas city center',
    ariaText: 'Dallas location page',
  },

  // Orlando, FL
  5: {
    id: 5,
    mapSrc: `https://www.google.com/maps/embed/v1/place?key=${process.env.VUE_APP_GOOGLE_API_KEY}
    &q=Hard+rock+stadium,+Miami+Fl`,
    locationId: 'miami',
    linkName: 'Miami',
    heroBg: 'bg-miami-hero',
    cardBg: 'bg-miami-card',
    img: imageMiami,
    path: '/location/miami',
    altText: 'aerial view of the Miami city center',
    ariaText: 'Miami location page',
  },
  // Chicago, IL
  6: {
    id: 6,
    mapSrc: `https://www.google.com/maps/embed/v1/place?key=${process.env.VUE_APP_GOOGLE_API_KEY}
    &q=Waldron+Deck,Chicago+Il`,
    locationId: 'chicago',
    linkName: 'Chicago',
    heroBg: 'bg-chicago-hero',
    cardBg: 'bg-chicago-card',
    img: imageChicago,
    path: '/location/chicago',
    altText: 'aerial view of the Chicago city center',
    ariaText: 'Chicago location page',
  },
  // Las Vegas, NV
  7: {
    id: 7,
    mapSrc: `https://www.google.com/maps/embed/v1/place?key=${process.env.VUE_APP_GOOGLE_API_KEY}
    &q=Fontainbleau,Las+Vegas+Nevada`,
    locationId: 'las-vegas',
    linkName: 'Las Vegas',
    heroBg: 'bg-las-vegas-hero',
    cardBg: 'bg-las-vegas-card',
    img: imageLasVegas,
    path: '/location/las-vegas',
    altText: 'aerial view of the Las Vegas city center',
    ariaText: 'Las Vegas location page',
  },
};

export default locations;
