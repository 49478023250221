import { useToast } from 'vue-toastification';

const convertArrayIntoCSV = (data) => {
  const header = `${Object.keys(data[0]).join(',')}\n`;

  const rows = data.map((obj) => Object.values(obj).map((val) => {
    if (typeof val === 'string' && val.includes(',')) {
      return `"${val}"`;
    }
    return val;
  }).join(',')).join('\n');

  const csv = header + rows;
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', 'attendee_contact_data.csv');
  link.style.visibility = 'hidden';
  document.body.appendChild(link);

  link.click();

  useToast().success('Data download has started!');

  document.body.removeChild(link);
};

export default convertArrayIntoCSV;
