<template>
  <div class="event-waiver">
  <event-waiver ref="eventWaiverRef" @sentForm="generatePDFAndSave"></event-waiver>
  </div>
  <template v-if="showPDFContainer">
    <vue3-html2pdf
    ref="html2Pdf"
    pdf-format="a4"
    pdf-orientation="portrait"
    pdf-content-width="98%"
    filename="Test_Drive_Registration"
    :show-layout="false"
    :float-layout="true"
    :enable-download="false"
    :pdf-quality="1"
    :manual-pagination="true"
    @beforeDownload="beforeDownload"
    >
      <template #pdf-content>
        <event-waiver :initialState="pdfContainerData" @sentForm="generatePDFAndSave">
        </event-waiver>
      </template>
    </vue3-html2pdf>
  </template>
</template>

<script setup>
import { ref } from 'vue';
import EventWaiver from '@/components/eventWaiver/EventWaiver.vue';
import { useMeta } from 'vue-meta';
import Vue3Html2pdf from 'vue3-html2pdf';

useMeta({
  meta: [
    { name: 'robots', content: 'noindex,nofollow' },
    { 'http-equiv': 'Content-Type', content: 'text/html;charset=utf-8' },
  ],
});

const showPDFContainer = ref(false);
const pdfContainerData = ref();
const html2Pdf = ref();
const eventWaiverRef = ref();

const generatePDFAndSave = (formData) => {
  showPDFContainer.value = true;
  pdfContainerData.value = formData;
  setTimeout(() => {
    html2Pdf.value.generatePdf();
  });
};

async function beforeDownload({ html2pdf: pdf, options, pdfContent }) {
  await pdf()
    .set(options)
    .from(pdfContent)
    .outputPdf()
    .then(async (data) => {
      const pdfBase64 = btoa(data);
      showPDFContainer.value = false;
      pdfContainerData.value = null;
      eventWaiverRef.value.pdfHasDownloaded(pdfBase64);
    });
}
</script>

<style scoped lang="scss">
.event-waiver {
  @apply px-8;
}
</style>
