<template>
  <div class="home">
    <hero-section />
    <overview-section class="relative mt-4 md:mt-[2rem]" />
    <location-section />
  </div>
</template>

<script setup>
import HeroSection from '@/components/global/HeroSection.vue';
import OverviewSection from '@/components/overview/OverviewSection.vue';
import LocationSection from '@/components/locations/LocationSection.vue';
import { useRoute } from 'vue-router';
import { useMeta } from 'vue-meta';

const route = useRoute();

useMeta({
  meta: [
    { name: 'description', content: route.meta.description },
    { 'http-equiv': 'Content-Type', content: 'text/html;charset=utf-8' },
  ],
});
</script>

<style scoped lang="scss">
.home {
  @apply px-8;
}
</style>
