<script setup>
import { ref } from 'vue';
import { useToast } from 'vue-toastification';
import apiManager from '@/api';

import TheButton from '../../global/TheButton.vue';
import TheFieldset from '../../global/TheFieldset.vue';

const toast = useToast();
const fileBlob = ref(null);
const isLoading = ref(false);

const props = defineProps({
  token: String,
});

defineEmits(['openInstructionsModal']);

const postPrintLabelsFromFile = async () => {
  try {
    await apiManager.print.postPrintLabelFile(fileBlob.value, props.token);
    toast.success(
      'The file has been successfully processed and badges will begin printing shortly.',
    );
  } catch (error) {
    const errorMessage = error.response.data.message || 'An error ocurred while uploading your file. Please try again later.';
    toast.error(errorMessage);
  } finally {
    isLoading.value = false;
  }
};

const handleFileInput = (event) => {
  const file = event.target.files[0];
  if (file) {
    fileBlob.value = file;
  }
};

const handleClickPrintBadges = () => {
  isLoading.value = true;
  if (fileBlob.value) {
    postPrintLabelsFromFile();
  } else {
    isLoading.value = false;
    toast.error(
      'Please provide either a .CSV or .XLSX file containing the badges you want to print.',
    );
  }
};
</script>
<template>
  <TheFieldset legend="Upload a list of badges to print the labels">
    <div class="grid grid-cols-1 md:grid-cols-3 gap-4 items-center">
      <div>
        <span>1. </span>
        <TheButton
          class="px-4 mx-auto"
          label="Review Instructions"
          @click="$emit('openInstructionsModal', true)"
        />
      </div>
      <label for="file-update" class="flex align-center max-w-[100%]">
        2.&nbsp;
        <input
          id="file-update"
          required
          type="file"
          @change="handleFileInput"
          accept=".xlsx,.csv"
        />
      </label>
      <div>
        <span>3. </span>
        <TheButton
          class="px-4 mx-auto"
          label="Print Badges"
          :loading="isLoading"
          @click="handleClickPrintBadges"
        />
      </div>
    </div>
  </TheFieldset>
</template>
