<template>
  <template v-if="multiple">
    <FloatLabel class="">
      <MultiSelect
        id="ms-cities"
        :options="options"
        :filter="filter"
        :class="sizeClass[size]"
        v-model="inputValue"
        fluid
        variant="filled"
        :maxSelectedLabels="3"
      />
      <label for="ms-cities">{{ label }}</label>
    </FloatLabel>
  </template>
  <template v-else>
    <template v-if="fixedLabel">
      <div class="fixed-label__container" :class="dirty && errorMessage ? 'error' : ''">
        <label class="font-bold" for="ms-cities">{{ label }}</label>
        <Select
        id="ms-cities"
        v-model="inputValue"
        :options="options"
        :placeholder="inlineOption"
        :class="sizeClass[size]"
        :filter="filter"
        optionLabel="name"
        optionValue="value"
        variant="filled"
        fluid
        />
        <small v-if="dirty && errorMessage">
        {{ errorMessage }}
      </small>
      </div>
    </template>
    <template v-else>
      <FloatLabel class="">
      <Select
        id="ms-cities"
        v-model="inputValue"
        :options="options"
        :class="sizeClass[size]"
        :filter="filter"
        fluid
      />
      <label for="ms-cities">{{ label }}</label>
    </FloatLabel>
    </template>

  </template>
</template>

<script setup>
import { computed } from 'vue';
import FloatLabel from 'primevue/floatlabel';
import MultiSelect from 'primevue/multiselect';
import Select from 'primevue/select';

const props = defineProps({
  label: String,
  multiple: { type: Boolean, required: false, default: false },
  filter: { type: Boolean, required: false },
  fixedLabel: { type: Boolean, required: false, default: false },
  options: Array,
  modelValue: Array,
  size: { type: String, default: 'lg' },
  inlineOption: { type: String, default: '' },
  dirty: { type: Boolean, default: false },
  errorMessage: { type: String, default: '' },
});

const sizeClass = { sm: 'small-select', md: 'medium-select', lg: 'large-select' };
const emit = defineEmits('update:modelValue');

const inputValue = computed({
  get: () => props.modelValue,
  set(value) {
    emit('update:modelValue', value);
  },
});

</script>

<style scoped lang="scss">
.p-multiselect,
.p-select {
  border-radius: var(--p-inputgroup-addon-border-radius) !important;
  &:not(.p-disabled) {
    &.p-focus {
      border-color: #4291D1;
    }
  }
  &.large-select {
    height: 52px;
  }
  &.medium-select {
    height: 40px;
  }
}

.p-floatlabel:has(.p-inputwrapper-filled) label,
.p-floatlabel:has(.p-inputwrapper-focus) label  {
  top: 0.125rem;
  padding: 0 0.25rem;
  background: white;
}

.fixed-label__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-column: span 2/span 2;
  label {
    font-size: 16px;
    margin-bottom: 0.25rem;
  }
  .p-select {
    border: 2px solid #CDCDCD;
    border-radius: 0.5rem !important;
    color: #393939;
    display: flex;
    align-items: center;
    height: 56px;
  }

  &.error {
    .p-select {
      border: 2px solid red;
    }
    small {
      color: red;
      font-size: 0.875rem;
      margin-top: 0.25rem;
    }
  }
}
</style>
