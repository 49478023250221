<script setup>
import { computed } from 'vue';
import store from '@/store';

defineProps({
  dates: Array,
  locationName: String,
  additionalContent: String,
  address: String,
  mapSrc: String,
});

const DaysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const Months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const eventType = computed(() => store.getters['user/eventType']);
const getDayName = (index) => DaysOfWeek[index];
const getMonthName = (month) => Months[month];

const formatDate = (date) => {
  const dateT = new Date(`${date}T00:00:00`);
  const dayName = getDayName(dateT.getDay());
  const monthName = getMonthName(dateT.getMonth());
  const day = dateT.getDate();
  const year = dateT.getFullYear();

  return `${dayName}, ${monthName} ${day}, ${year}`;
};
</script>
<template>
  <div
    class="
      flex flex-col
      lg:flex-row
      justify-center
      w-full
      max-w-[23.75rem]
      lg:max-w-full lg:h-[21rem]
      mx-auto
    "
  >
    <div class="lg:flex lg:flex-col lg:justify-center w-full lg:mx-[5rem]">
      <div
        class="
          font-MyriadProLight
          tracking-[1.47px]
          text-[1.375rem]
          lg:text-[2.275rem]
          2xl:text-[2.875rem]
          after:border-b-[6px]
          after:border-dynamic
          after:block
          after:w-[9rem]
          mb-[1.5rem]
        "
      >
        <p v-for="date in dates" :key="date">{{ formatDate(date) }}</p>

        <p
          class="text-2xl font-MyriadProRegular mb-8"
          v-if="eventType === 'zdxdrive' || eventType === 'prologuedrive'">
          Please note the following meeting schedule for Friday, April 5 before
          selecting a time below to avoid conflicts <br />
          Business Meeting 9:00 AM
          – 9:30 AM <br /> {{eventType === 'zdxdrive'
          ? 'Acura Brand Meeting 11:20 AM – 12:40 PM'
          : 'Honda Brand Meeting 9:35 AM – 10:40 AM'}}
        </p>
      </div>
      <div
        class="text-[1.0625rem] lg:text-[1.125rem] pb-2"
      >
        <p class="uppercase mb-[0.5rem] font-MyriadProBold">
        {{
        eventType === 'zdxdrive' || eventType === 'prologuedrive'
        ? 'Dealer Meeting Registration Desk'
        : locationName
        }}
        </p>
        <p
          class="font-MyriadProRegular"
          v-if="eventType === 'zdxdrive' || eventType === 'prologuedrive'">
          {{ locationName }}
          </p>
        <p class="font-MyriadProRegular">{{ address }}</p>
      </div>
      <div v-html="additionalContent" />
      <p class="italic text-[.875rem] lg:text-[1.125rem] mt-4 font-MyriadProItalic">
        *AHM reserves the right to change locations, venues, and dates.
      </p>
    </div>
    <iframe
      :title="`Google map of ${locationName}`"
      class="
        max-w-[100%]
        w-[24.5rem]
        lg:w-full
        h-[13rem]
        lg:h-[21rem]
        rounded-[30px]
      "
      style="border: 0"
      loading="lazy"
      allowfullscreen
      referrerpolicy="no-referrer-when-downgrade"
      :src="mapSrc"
    >
    </iframe>
  </div>
</template>
