<template>
  <div class="tab-carousel">
    <div class="tabs">
      <button
        class="
          absolute
          left-0

          px-2
          py-1
          font-MyriadProRegular
          uppercase
          text-white
          rounded-[6px]
          disabled:bg-acura-gray-dark
          disabled:border-acura-gray-dark
          disabled:cursor-not-allowed
          self-start
          lg:w-auto
          bg-[#2D7AB9]
          border-[#2D7AB9]
          md:col-start-2
          xl:col-start-5
          border-2
        "
        v-if="enableDownload && activeTab === 1"
        @click="handleDownload">
        Download Data
      </button>
      <button
        v-for="(tab, index) in tabs"
        :key="index"
        @click="handleTab(index)"
        :class="{ active: index === activeTab }"
      >
        {{ tab.name }}
      </button>
    </div>
    <section class="w-[100%] overflow-hidden">
      <transition name="carousel">
        <div
          class="tab-content-wrapper"
          :style="{
            width: `calc(100% * ${tabs.length})`,
            transform: `translateX(-${activeTab * 50}%)`
          }">
          <div class="!w-full" v-for="tab in tabs" :key="tab">
            <slot :name="tab.slot"></slot>
          </div>
        </div>
      </transition>
    </section>
  </div>
</template>

<script setup>
import { defineEmits, defineProps } from 'vue';

defineProps({
  activeTab: { type: Number, required: true },
  tabs: { type: Array, required: true },
  enableDownload: { type: Boolean, required: false },
});
const emit = defineEmits('changeTab', 'onDownload');

const handleTab = (index) => {
  emit('changeTab', index);
};

const handleDownload = () => {
  emit('onDownload');
};
</script>

<style>

.tabs-container {
  overflow: hidden;
}

.tabs {
  @apply flex justify-end md:justify-center gap-6 pb-4 relative overflow-x-auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.tabs::-webkit-scrollbar {
  display: none;
}

.tab-content-wrapper {
  display: flex;
  transition: transform 0.5s ease;
}

.tab-carousel {
  @apply w-full;
}

button {
  @apply mx-0 my-2.5 px-[5px] py-2.5 cursor-pointer border-none bg-transparent;
  transition: all 0.3s;
}
button.active {
  @apply border-b-2 border-solid border-[#2d7ab9];
}
.tab-content {
  @apply flex justify-center;
}
</style>
