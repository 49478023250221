<script setup>
const emit = defineEmits(['click', 'close-toast']);

async function click() {
  emit('click');
}
</script>
<template>
  <div class="container">
    <h2 class="mb-5 text-center">
      Your session will expire in five minutes.
      <br>
      Do you want more time to register?
    </h2>
    <div class="flex justify-center gap-4">
      <button
      @click.stop="click(), $emit('close-toast')"
        class="
        uppercase text-white border
        border-box border-white py-2
        px-8
      ">
        Yes
      </button>
      <button
      @click="$emit('close-toast')"
      class="
        uppercase text-white border
        border-box border-white py-2
        px-8
      ">
        No
      </button>
    </div>
  </div>
</template>
