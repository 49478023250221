<script setup>

import { ref } from 'vue';
import ReportingOptions from './ReportingOptions.vue';
import ReportingTable from './ReportingTable.vue';

defineProps({
  tableData: {
    type: Object,
  },
  filterData: {
    type: Object,
  },
});

const emits = defineEmits(['getMetrics']);

// const emits = defineEmits(['getMetrics']);
const getMetrics = (x) => emits('getMetrics', x);

const showTable = ref(true);

const onClearAndHideTable = () => {
  showTable.value = false;
};

const onGetMetrics = (inputs) => {
  showTable.value = true;
  getMetrics(inputs);
};

</script>
<template>
    <div class="p-5 lg:p-14 min-h-[450px]">
      <div class="col-span- xl:col-span-3">
        <h1 class="
          mb-10
          text-2xl
          sm:text-3xl
          lg:text-4xl
          font-MyriadProLight
          uppercase
        ">
        <span class="border-b-[6px] border-dynamic">Eve</span>nt Reporting
        </h1>
        <reporting-options
          class="mb-[2rem]"
          @clearAndHideTable="onClearAndHideTable"
          @submit="onGetMetrics"
        />
        <reporting-table v-if="showTable && tableData.data.length" v-bind="tableData" />
      </div>
    </div>
</template>
